import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';

@Injectable({ providedIn: 'root' })

export class FamilyAuthGuardService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authorizationService.hasRights('family', 'canRead')) {
      // console.log('FamilyAuthGuardService: ', route.routeConfig.path);
      localStorage.setItem('returnUrl', route.routeConfig.path);
      this.router.navigate(['login']);
      return false;
    } else { return true; }
  }
}
