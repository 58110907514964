import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";
import { take } from 'rxjs/operators';
import { Rate } from './../../domain/rate';

@Injectable({
  providedIn: 'root'
})
export class FxRatesService {
  private ws = 'https://currencies.apps.grandtrunk.net/getrate/';
  private wsrange = 'https://currencies.apps.grandtrunk.net/getrange/';
  private dateFormat = 'yyyy-MM-dd';
  private datePipe = new DatePipe('fr-FR');
  public historicalRates: ReplaySubject<Rate[][]> = new ReplaySubject(1);
  private rates: Rate[][];

  constructor(private http: HttpClient) { }

  /*
   * Uses a WebService to get an exchange rate between two currencies at a specific date in time
   * Web services used:<br>
   * "https://currencies.apps.grandtrunk.net/getlatest/eur/chf"<br>
   * "https://currencies.apps.grandtrunk.net/getrate/<date>/<fromcode>[/<tocode>]" (Default:EUR)<br>
   * "https://currencies.apps.grandtrunk.net/getrange/1999-01-01/2000-01-01/eur/usd"<br>
   *
   * @param currency1 Source currency String, e.g. "EUR"
   * @param currency2 Target currency String, e.g. "CHF"
   * @param date LocalDate) when this FX rate was valid
   * @return the exchange rate as double
   */

  getAllFXHistoricalRates(baseCcy: string, listOfCurrencies: string[], fromDate: number, toDate: number) {
    let counter = listOfCurrencies.length;
    this.rates = [];

    for (const currency of listOfCurrencies) {

      this.getFXHistoricalRates(baseCcy, currency, fromDate, toDate).pipe(take(1))
        .subscribe(rates => {
          counter--;
          const ccyRates = this.transformToJson(rates);
          this.rates.push(ccyRates);
          if (counter === 0) {
            this.historicalRates.next(this.rates);
          }
        })
    }
  }

  private transformToJson(rates: string): { date: Date, rate: number }[] {
    const ratesArray: { date: Date, rate: number }[] = [];
    rates = rates.trim();
    const lines = rates.split('\n');
    for (const line of lines) {
      const items = line.split(' ');
      const dateArray = items[0].split('-');
      const date = new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2]);
      const rate = +items[1];
      ratesArray.push({ date, rate })
    }
    return ratesArray;
  }

  getFXRate(currency1: string, currency2: string, ofDate: number) {
    const url = this.ws + this.datePipe.transform(ofDate, this.dateFormat) + '/' + currency1 + '/' + currency2;
    return this.http.get(url);
  }

  getFXHistoricalRates(baseCcy: string, ccy: string, fromDate: number, toDate: number) {
    const url = this.wsrange + this.datePipe.transform(fromDate, this.dateFormat) + '/' + this.datePipe.transform(toDate, this.dateFormat) + '/' + ccy + '/'
      + baseCcy;
    // console.log(' URL: ', url);
    return this.http.get(url, { responseType: 'text' });
  }

}
