import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeOverviewComponent } from './components/home-overview/home-overview.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HomeTempGaugeComponent } from './components/home-temp-gauge/home-temp-gauge.component';
import { ThermostatAuthGuardService } from "./services/thermostat-auth-guard.service";

@NgModule({
  declarations: [HomeOverviewComponent, HomeTempGaugeComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'home', component: HomeOverviewComponent, canActivate: [ThermostatAuthGuardService] },
    ]),
  ]
})
export class HomeModule { }
