<h1 mat-dialog-title>{{header | translate}}</h1>

<mat-dialog-content>
  <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Select Column -->
    <ng-container matColumnDef="selected">
      <mat-header-cell *matHeaderCellDef> {{'common.dialog.show' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox (change)="selectionChanged(element)" [(ngModel)]="element.selected" [checked]="element.selected">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Columnname Column -->
    <ng-container matColumnDef="columnname">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'role.list.header.module' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.currentLang}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()"  cdkFocusInitial>{{'common.button.cancel' | translate}}</button>
  <button mat-raised-button (click)="proceed()" >{{'common.button.save' | translate}}</button>
</div>
