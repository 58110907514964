import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { FamilyMember } from 'src/app/domain/familymember';
import { FamilyMemberContext } from 'src/app/domain/familymembercontext';
import { FileNameDialogComponent } from 'src/app/shared/components/file-name-dialog/file-name-dialog.component';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class FamilyStoreService {

  private subscriptions = new Subscription();
  private familyRaw: FamilyMember[];
  private tableName = 'family';
  private fileNameDialogRef: any;

  private familyExpanded = new ReplaySubject<FamilyMember[]>(1);
  familyExpanded$: Observable<FamilyMember[]> = this.familyExpanded.asObservable();
  private emptyMember: FamilyMember = { key: '', firstName: '', lastName: '' };

  private currentMemberContextSubject = new BehaviorSubject<FamilyMemberContext>({
    self: null,
    father: null,
    mother: null,
    partner: null,
    children: []
  });

  currentMemberContext$: Observable<FamilyMemberContext> = this.currentMemberContextSubject.asObservable();
  familyTree: number;

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
  ) {
    this.subscriptions.add(
      this.dataService.readAllSortBy<FamilyMember>(this.tableName, 'part')
        .subscribe((family: FamilyMember[]) => {
          this.familyRaw = family;
          this.familyExpanded.next(this.expandFamily(family));
          // console.log('familyExpanded updated');
          // currentMember has possibly changed; update currentMember:
          if (this.currentMemberContext.self) {
            const newSelf = this.getMemberById(this.currentMemberContext.self.key);
            this.setCurrentRecord(newSelf);
          }
        })
    );
  }

  private expandFamily(family: FamilyMember[]): FamilyMember[] {
    let list: FamilyMember[] = [];
    for (const member of family) {
      let fm: FamilyMember;
      fm = {...member};
      delete fm.modifiedBy;
      delete fm.createdBy;
      if (fm.fatherId) fm.fathersName = this.getFirstNameById(fm.fatherId)
      if (fm.motherId) fm.mothersName = this.getFirstNameById(fm.motherId)
      if (fm.partnerId) fm.partnersName = this.getFirstNameById(fm.partnerId)
      if (fm.childrenIds) fm.childrenNames = this.getChildrenNamesById(fm.childrenIds)
      // console.log('fm: ', fm);
      list.push(fm);
    }
    list = this.calcGen(list)
    return list;
  }

  getChildrenNamesById(childrenIds: string[]): string {
    // console.log('Children before: ', member);
    let childrenNames: string = '';
    for (const childId of childrenIds) {
      const firstName = this.getFirstNameById(childId)
        childrenNames = childrenNames.concat(firstName + ',');
    }
    childrenNames = childrenNames.substring(0, childrenNames.length - 1);
    return childrenNames;
  }

  private calcGen(family: FamilyMember[]): FamilyMember[] {
    let generation = 1;
    this.familyTree = 1;
    const seed = family.findIndex(person => person.generation === generation);
    if (seed > -1) {
      const parent = family[seed];
      parent.familyTree = this.familyTree;
      // console.log('Seed: ', this.filteredFamily.filteredData[seed]);
      if (parent.childrenIds) this.nextGeneration(family, parent, generation);
    }
    return family;
  }

  private nextGeneration(family: FamilyMember[], parent: FamilyMember, parentGeneration: number) {
    // console.log('Parent: ',parent.firstName,parent.lastName,parent.partnerId);

    if (parent.partnerId) {
      const partner = this.getById(family, parent.partnerId);
      partner.generation = parentGeneration;
      partner.familyTree = ++this.familyTree;
    }

    const generation = parentGeneration + 1;

    for (const childId of parent.childrenIds) {
      const child = this.getById(family, childId);
      child.generation = generation;
      child.familyTree = ++this.familyTree;
      // console.log('Child: ',child.firstName,child.lastName,child.generation,child.familyTree);
      if (child.childrenIds) this.nextGeneration(family, child, generation);
    }
  }

  private getById(family: FamilyMember[], id: string): FamilyMember {
    for (const member of family) {
      if (id === member.key) return member;
    }
    return this.emptyMember;
  }

  public setCurrentRecord(newCurrentRecord: FamilyMember) {
    // console.log('Set current record to: ', currentMember);

    const newFamilyContext: FamilyMemberContext = {};
    let self = this.familyRaw.find(member => member.key === newCurrentRecord.key);
    if (!self) self = newCurrentRecord;
    // console.trace('Self: ', self);

    newFamilyContext.self = self;
    if (self.fatherId) {
      newFamilyContext.father = this.getMemberById(self.fatherId);
      // newFamilyContext.father.fatherId = self.fatherId;
    }
    if (self.motherId) {
      newFamilyContext.mother = this.getMemberById(self.motherId);
      // newFamilyContext.mother.motherId = self.motherId;
    }
    if (self.partnerId) {
      newFamilyContext.partner = this.getMemberById(self.partnerId);
      // newFamilyContext.partner.partnerId = self.partnerId;
    }

    const children: FamilyMember[] = [];
    // console.log('Self: ', self);
    if (self.childrenIds) { for (const child of self.childrenIds) { children.push(this.getMemberById(child)) } }
    newFamilyContext.children = children;

    // console.log('Set current record to: ', newFamilyContext);
    this.currentMemberContextSubject.next(newFamilyContext);
  }

  public getNameById(id) {
    for (const member of this.familyRaw) {
      if (id === member.key) return (member.firstName + ' ' + member.lastName);
    }
    return '';
  }
  public getFirstNameById(id) {
    for (const member of this.familyRaw) {
      if (id === member.key) return member.firstName;
    }
    return '';
  }

  public getMemberById(id): FamilyMember {
    for (const member of this.familyRaw) {
      if (id === member.key) return member;
    }
    return this.emptyMember;
  }

  importFamilyFile(familyAllColumns: string[]) {

    this.fileNameDialogRef = this.dialog.open(FileNameDialogComponent, {
      width: '750px',
      data: { domain: 'family', title: 'family.import.title', columns: familyAllColumns }
    });

    this.fileNameDialogRef.afterClosed().subscribe(table => {
      if (!table) { return; }

      if (!table.append) {
        this.dataService.deleteAll(this.tableName).then(() => {
          this.addNewFamilyMember(table.records);
        });
      } else { this.addNewFamilyMember(table.records); }
    });
  }

  private addNewFamilyMember(records: FamilyMember[]) {
    records.forEach(parent => {
      this.addChildren(parent, records);
      this.dataService.set(this.tableName, parent);
    });

  }

  private addChildren(parent: FamilyMember, records: FamilyMember[]) {
    const children = [];
    for (const child of records) {
      if ((parent.key === child.fatherId) || (parent.key === child.motherId)) {
        children.push(child.key);
      }
    }
    parent.childrenIds = children;
  }


  public get currentMemberContext(): FamilyMemberContext {
    return this.currentMemberContextSubject.getValue();
  }

  public set currentMemberContext(currentMember: FamilyMemberContext) {
    this.currentMemberContextSubject.next(currentMember);
    // console.info('Current Member: ', currentMember);
  }
}