export const MODULES = [
    'admin',
    'config',
    'users',
    'export',
    'import',
    'permissions',
    'weather',
    'fxrates',
    'projects',
    'family',
    'home',
    'portfolio',
];
