<mat-card class="card">
    <mat-card-header>
        <div mat-card-avatar>
            <img src="{{icon}}">
        </div>
        <mat-card-title>
            {{1000*weather.dt | localDate:'dd. MMM'}}
            <span>
                <button *ngIf="city !== 'currentPosition'" mat-icon-button (click)="deleteCity(forecast.city.name)">
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
        </mat-card-title>
        <mat-card-subtitle>{{weather.weather[0].description}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="layout">
        <span class="left">{{'weather.card.temp' | translate}}</span>
        <span class="right">{{weather.main.temp | number:'1.0-0'}} °C</span>
        <span class="left">{{'weather.card.tempmin' | translate}}</span>
        <span class="right">{{temp_min | number:'1.0-0'}} °C</span>
        <div class="left">{{'weather.card.tempmax' | translate}}</div>
        <div class="right"> {{temp_max | number:'1.0-0'}} °C</div>
        <div class="left">{{'weather.card.humidity' | translate}}</div>
        <div class="right">{{weather.main.humidity | number:'1.0-0'}} %</div>
        <div class="left">{{'weather.card.wind' | translate}} </div>
        <div class="right">{{3.6*weather.wind.speed | number:'1.0-0'}} km/h</div>
        <div class="left">{{'weather.card.deg' | translate}} </div>
        <div class="right">{{deg2dir(weather.wind.deg)}}</div>
        <div class="left">{{'weather.card.clouds' | translate}} </div>
        <div class="right">{{weather.clouds.all | number:'1.0-0'}} %</div>
        <div class="left">{{'weather.card.sunrise' | translate}}</div>
        <div class="right">{{1000 * weather.sys.sunrise | date:'H:mm' }}</div>
        <div class="left">{{'weather.card.sunset' | translate}} </div>
        <div class="right"> {{1000 * weather.sys.sunset | date:'H:mm' }}</div>
    </mat-card-content>

</mat-card>