<div class="container">
    <div >
        <div >
            <div >
                <h1>Oops!</h1>
                <h2>404 Not Found</h2>
                <div>
                    Sorry, an error has occured, Requested page not found!
                </div>
                <div >
                    <a mat-raised-button color="primary"  [routerLink]="['/login']" >Go to login page</a>
                </div>
            </div>
        </div>
    </div>
</div>
