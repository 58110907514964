import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { Observable, take, map } from "rxjs";
import { AppUser } from "src/app/domain/appuser";
import { ContactBase } from "src/app/domain/contact-base";
import { MODULES } from "src/app/domain/modules";
import { MenuStoreService } from "src/app/menu/services/menu-store.service";
import { DataService } from "src/app/shared/services/data.service";
import { AdminStoreService } from "../../services/admin-store.service";


export interface Type {
    checked: boolean;
    table: string;
    countRecords: number;
    countModified: number;
    done: boolean;
}

@Component({
    selector: 'migrate',
    templateUrl: './migrate.component.html',
    styleUrls: ['./migrate.component.scss'],
})
export class MigrateComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    appUser$: Observable<AppUser> = this.adminStore.appUser$;

    checkAll = false;
    typeControl;
    datePipe = new DatePipe('fr');

    selectedTypes: string;
    types: Type[] = [];
    exceptions = ['import', 'export', 'admin', 'subscriptions'];
    dataSource = new MatTableDataSource();
    displayedColumns = ['table', 'select', 'countRecords', 'countModified', 'done'];
    dryRun: boolean;

    constructor(
        private dataService: DataService,
        private menuStore: MenuStoreService,
        private adminStore: AdminStoreService
    ) { }

    ngOnInit(): void {
        for (const module of MODULES) {
            if (!this.exceptions.includes(module)) {
                this.types.push({ checked: false, table: module, countRecords: 0, countModified: 0, done: false });
            }
        }

        this.dataSource.data = this.types;
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.menuStore.componentInfo = {
            name: 'migrate',
            form: true,
            options: [],
            showFilter: false,
        };
    }

    setAll(selected: boolean): void {
        for (const type of this.types) {
            type.checked = selected;
            type.countRecords = 0;
            type.countModified = 0;
            type.done = false;
        }
    }

    migrate(dryRun: boolean): void {
        this.dryRun = dryRun;
        for (const type of this.dataSource.data as Type[]) {
            if (type.checked) {
                const dataStream$ = this.dataService.readAll(type.table);

                dataStream$
                    .pipe(
                        take(1),
                        map((data: ContactBase[]) => {
                            type.countRecords = 0;
                            type.countModified = 0;
                            for (const record of data) {
                                type.countRecords++;
                                this.migrateRecord(record, type);
                            }
                            type.done = true;
                            return data;
                        })
                    )
                    .subscribe((data) => {
                        if (this.dryRun) {
                            console.log(type.table, data.length, data);
                        } else {
                            for (const record of data) {
                                this.dataService.set(type.table, record, record.key);
                            }
                        }
                    });
            }
        }
    }

    private migrateRecord(record: ContactBase, type: Type): void {

        Object.keys(record).forEach((key) => {
            if (this.isObject(record[key])) this.migrateRecord(record[key], type);

            switch (key) {
                case 'father':
                    type.countModified++;
                    record['fatherId'] = record['father'];
                    delete record['father'];
                    break;
                case 'mother':
                    type.countModified++;
                    record['motherId'] = record['mother'];
                    delete record['mother'];
                    break;
                case 'partner':
                    type.countModified++;
                    record['partnerId'] = record['partner'];
                    delete record['partner'];
                    break;

                case 'modifiedby':
                    type.countModified++;
                    if (!record.modifiedBy) record.modifiedBy = record['modifiedby'];
                    delete record['modifiedby'];
                    break;

                case 'modifiedon':
                    type.countModified++;
                    if (!record.modifiedOn) record.modifiedOn = record['modifiedon'];
                    delete record['modifiedon'];
                    break;

                case 'createdby':
                    type.countModified++;
                    if (!record.createdBy) record.createdBy = record['createdby'];
                    delete record['createdby'];
                    break;

                    case 'createdon':
                    type.countModified++;
                    if (!record.createdOn) record.createdOn = record['createdon'];
                    delete record['createdon'];
                    break;

                default:
                    break;
            }
        });
    }

    private isObject(val): boolean {
        if (val === null) {
            return false;
        }
        return typeof val === 'function' || typeof val === 'object';
    }
}
