<form #form [formGroup]="personForm" class="container layout">
    <div class="mat-h1 header"> {{data.header | translate}}</div>
    <div class="formGrid form">
        <mat-form-field appearance="outline">
            <mat-label>{{'common.contact.form.firstname.label' | translate}}</mat-label>
            <input matInput formControlName="firstName" name="firstName"
                placeholder="{{'common.contact.form.firstname.placeholder' | translate}}">
            <mat-error *ngIf="personForm.get('firstName').hasError('required')">
                {{'common.contact.form.firstname.error.required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'common.contact.form.lastname.label' | translate}}</mat-label>
            <input matInput formControlName="lastName" name="lastName"
                placeholder="{{'common.contact.form.lastname.placeholder' | translate}}">
            <mat-error *ngIf="personForm.get('lastName').hasError('required')">
                {{'common.contact.form.lastname.error.required' | translate}} </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-select formControlName="sex" name="sex" matTooltip="{{'family.form.sex.tooltip' | translate}}"
                placeholder="{{'family.form.sex.placeholder' | translate}}">
                <mat-option *ngFor="let sex of (config$ | async).sex" [value]="sex"> {{sex}} </mat-option>
            </mat-select>
            <mat-error *ngIf="personForm.get('sex').hasError('required')">
                {{'common.contact.form.sex.error.required' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'common.contact.form.dateofbirth.label' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="dateOfBirth" name="dateOfBirth"
                placeholder="{{'common.contact.form.dateofbirth.placeholder' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

    </div>

    <div class="footer">
        <button mat-raised-button (click)="cancel()" cdkFocusInitial>
            <mat-icon>undo</mat-icon>
        </button>
        <span class="spacer"></span>
        <button [disabled]="form.invalid" mat-raised-button (click)="proceed()">
            <mat-icon>cloud_done</mat-icon>
        </button>
    </div>
</form>