<div class="mat-elevation-z8">
    <mat-table matSort [dataSource]="filteredFamily$" matSortActive="lastName" matSortDirection="desc">

        <!-- FirstName Column -->
        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="center">
                {{'contact.list.firstname' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.lastname' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- createdby  Column -->
        <ng-container matColumnDef="createdby">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'common.list.createdby' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.createdBy}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- createdon Column -->
        <ng-container matColumnDef="createdon">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.list.createdon' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.createdOn | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Modified By Column -->
        <ng-container matColumnDef="modifiedby">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'common.list.modifiedby' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.modifiedBy}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>
        // 'modifiedby', 'modifiedon', 'createdby', 'createdon']
        <!-- modifiedon Column -->
        <ng-container matColumnDef="modifiedon">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.list.modifiedon' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.modifiedOn | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.key' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
        <!-- [ngClass]="{'highlight': row.LastSubscription === lastSubscription }"  -->
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="$event.stopPropagation(); viewDetails(row)">
        </mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns" class="bold"></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons></mat-paginator>
</div>