<div *ngIf="currentMember"  class="container">
  <div class="header mat-elevation-z8">
    <span class="spacer"></span>
    <span >{{currentMember.self.firstName}} {{currentMember.self.lastName}} </span>
    <span class="spacer"></span>

    <div class="menu">
      <button mat-icon-button [matMenuTriggerFor]="menu" class="cornerIcon">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="!currentMember.partner" mat-menu-item (click)="addPartner()">
          <mat-icon>wc</mat-icon>
          <span>{{'family.form.menu.add.partner' | translate}}</span>
        </button>
        <button mat-menu-item (click)="addChild()">
          <mat-icon>child_friendly</mat-icon>
          <span>{{'family.form.menu.add.child' | translate}}</span>
        </button>
        <button *ngIf="!currentMember.mother" mat-menu-item (click)="addMother()">
          <mat-icon>pregnant_woman</mat-icon>
          <span>{{'family.form.menu.add.mother' | translate}}</span>
        </button>
        <button *ngIf="!currentMember.father" mat-menu-item (click)="addFather()">
          <mat-icon>emoji_people</mat-icon>
          <span>{{'family.form.menu.add.father' | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <form [formGroup]="familyMemberForm">
    <div class="formGrid">

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.firstname.label' | translate}}</mat-label>
        <input matInput formControlName="firstName" name="firstName"
          placeholder="{{'common.contact.form.firstname.placeholder' | translate}}">
        <mat-error *ngIf="familyMemberForm.get('firstName').hasError('required')">
          {{'common.contact.form.firstname.error.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.lastname.label' | translate}}</mat-label>
        <input matInput formControlName="lastName" name="lastName"
          placeholder="{{'common.contact.form.lastname.placeholder' | translate}}">
        <mat-error *ngIf="familyMemberForm.get('lastName').hasError('required')">
          {{'common.contact.form.lastname.error.required' | translate}} </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-select formControlName="sex" name="sex" matTooltip="{{'family.form.sex.tooltip' | translate}}"
          placeholder="{{'family.form.sex.placeholder' | translate}}">
          <mat-option *ngFor="let sex of (config$ | async).sex" [value]="sex"> {{sex}} </mat-option>
        </mat-select>
        <mat-error *ngIf="familyMemberForm.get('sex').hasError('required')">
          {{'common.contact.form.sex.error.required' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-select formControlName="function" name="function" matTooltip="{{'family.form.function.tooltip' | translate}}"
          placeholder="{{'family.form.function.placeholder' | translate}}">
          <mat-option *ngFor="let function of (config$ | async).functions" [value]="function"> {{function}} </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.generation.label' | translate}}</mat-label>
        <input matInput type="number" formControlName="generation" name="generation"
          placeholder="{{'family.form.generation.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.dateofbirth.label' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="dateOfBirth" name="dateOfBirth"
          placeholder="{{'common.contact.form.dateofbirth.placeholder' | translate}}">
          <mat-hint>{{ getDateFormat() }}</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" #picker1></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>{{'common.contact.form.dateofdeath.label' | translate}}</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="dateOfDeath" name="dateOfDeath"
          placeholder="{{'common.contact.form.dateofdeath.placeholder' | translate}}">
          <mat-hint>{{ getDateFormat() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" #picker2></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.partner.firstname.label' | translate}}</mat-label>
        <input matInput formControlName="partnerFirstName" name="partnerFirstName"
          placeholder="{{'family.form.partner.firstname.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.partner.lastname.label' | translate}}</mat-label>
        <input matInput formControlName="partnerLastName" name="partnerLastName"
          placeholder="{{'common.contact.form.lastname.placeholder' | translate}}">
        <button [disabled]="!currentMember.partner?.lastName"
          matTooltip="{{'family.form.partner.lastname.tooltip' | translate}}" matTooltipPosition="below" matSuffix
          mat-icon-button (click)="zoom(currentMember.partner)">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.father.label' | translate}}</mat-label>
        <input matInput formControlName="fatherFirstName" name="fatherFirstName"
          placeholder="{{'common.contact.form.father.placeholder' | translate}}">
        <button [disabled]="!currentMember.father?.firstName" matTooltip="{{'family.form.father.tooltip' | translate}}"
          matTooltipPosition="below" matSuffix mat-icon-button (click)="zoom(currentMember.father)">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.mother.label' | translate}}</mat-label>
        <input matInput formControlName="motherFirstName" name="motherFirstName"
          placeholder="{{'family.form.mother.placeholder' | translate}}">
        <button [disabled]="!currentMember.mother?.firstName" matTooltip="{{'family.form.mother.tooltip' | translate}}"
          matTooltipPosition="below" matSuffix mat-icon-button (click)="zoom(currentMember.mother)">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.email.label' | translate}}</mat-label>
        <input matInput type="email" formControlName="email" name="email"
          placeholder="{{'common.contact.form.email.placeholder' | translate}}">
        <mat-error *ngIf="familyMemberForm.get('email').hasError('email')">
          {{'common.contact.form.email.error.format' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.telephonefix.label' | translate}}</mat-label>
        <input matInput formControlName="telephoneFix" name="telephoneFix"
          placeholder="{{'common.contact.form.telephonefix.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.telephonemobile.label' | translate}}</mat-label>
        <input matInput formControlName="telephoneMobile" name="telephoneMobile"
          placeholder="{{'common.contact.form.telephonemobile.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.addrline1.label' | translate}}</mat-label>
        <input matInput formControlName="addrLine1" name="addrLine1"
          placeholder="{{'common.contact.form.addrline1.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.addrline2.label' | translate}}</mat-label>
        <input matInput formControlName="addrLine2" name="addrLine2"
          placeholder="{{'common.contact.form.addrline2.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.zip.label' | translate}}</mat-label>
        <input matInput formControlName="cp" name="cp"
          placeholder="{{'common.contact.form.zip.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.city.label' | translate}}</mat-label>
        <input matInput formControlName="city" name="city" oninput="this.value = this.value.toUpperCase()"
          placeholder="{{'common.contact.form.city.placeholder' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'common.contact.form.country.label' | translate}}</mat-label>
        <input matInput formControlName="country" name="country" oninput="this.value = this.value.toUpperCase()"
          placeholder="{{'common.contact.form.country.placeholder' | translate}}">
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{'family.form.parts.label' | translate}}</mat-label>
        <input matInput type="number" formControlName="part" name="part"
          placeholder="{{'family.form.parts.placeholder' | translate}}">
      </mat-form-field>
      <mat-error *ngIf="familyMemberForm.get('part').hasError('required')">
        {{'common.contact.form.part.error.required' | translate}}
      </mat-error>
    </div>
  </form>

</div>