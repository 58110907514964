import { BaseObject } from './../../../domain/base-object';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'userstamps-footer',
  templateUrl: './userstamps-footer.component.html',
  styleUrls: ['./userstamps-footer.component.scss']
})
export class UserstampsFooterComponent implements OnInit {
  @Input() entity!: BaseObject;

  modifiedBy: string = '';
  modifiedOn!: number;
  createdBy: string = '';
  createdOn!: number;

  constructor() { }

  ngOnInit(): void {
    console.log('UserstampsFooterComponent entity: ',this.entity);
    if (this.entity) {
      if (this.entity.createdBy) this.createdBy = this.entity.createdBy;
      if (this.entity.modifiedBy) this.modifiedBy = this.entity.modifiedBy;
      if (this.entity.createdOn) this.createdOn = this.entity.createdOn;
      if (this.entity.modifiedOn) this.modifiedOn = this.entity.modifiedOn;
    }
  }

}
