import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localDate',
  pure: false
})

export class LocalDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(value: any, format: string): string {
    const currentLang = this.translate.currentLang;

    if (!value) { return ''; }
    if (!format) { format = 'shortDate'; }

    return formatDate(value, format, currentLang);
  }
}
