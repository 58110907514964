import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'config-dialog-modules',
  templateUrl: './config-dialog-modules.component.html',
  styleUrls: ['./config-dialog-modules.component.scss']
})

export class ConfigDialogModulesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  header: string;
  allModules: string[];
  activatedModules: string[];
  rows: {}[] = [];
  dataSource = new MatTableDataSource();

  displayedColumns = ['selected', 'columnname'];

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogModulesComponent>,
    @Inject(MAT_DIALOG_DATA) data: { header: string, allModules: string[], activatedModules: string[] },
    private translate: TranslateService) {

    this.header = data.header;
    this.allModules = data.allModules;
    this.activatedModules = data.activatedModules;
  }


  ngOnInit(): void {
    this.activatedModules.forEach(module => {
      const translatedName = this.translate.instant('role.list.module.' + module.toLocaleLowerCase());
      this.rows.push({ selected: true, dbName: module, currentLang: translatedName });
    });

    this.allModules.forEach(module => {
      if (!this.activatedModules.includes(module)) {
        const translatedName = this.translate.instant('role.list.module.' + module.toLocaleLowerCase());
        this.rows.push({ selected: false, dbName: module, currentLang: translatedName });
      }
    });

    this.dataSource.data = this.rows;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionChanged(currentRow) {
    const indexOfRowToMove = this.rows.indexOf(currentRow);
    const rowToMove = this.rows[indexOfRowToMove];
    this.rows.splice(indexOfRowToMove, 1);
    const firstNonSelectedIndex = this.rows.findIndex(row => row['selected'] === false);
    this.rows.splice(firstNonSelectedIndex, 0, rowToMove);
    this.dataSource.data = this.rows;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  proceed(): void {
    const columnHeaders: string[] = [];
    this.rows.forEach(row => {
      if (row['selected']) { columnHeaders.push(row['dbName']); }
    });
    this.dialogRef.close(columnHeaders);
  }

}
