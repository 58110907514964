import { Injectable } from '@angular/core';

@Injectable()
export class Guid {
    static newGuid() {
        // tslint:disable-next-line: only-arrow-functions
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(core) {
            // tslint:disable-next-line: one-variable-per-declaration no-bitwise
            const r = Math.random() * 16 | 0, v = (core === 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16).toUpperCase();
        });
    }
}
