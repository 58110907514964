import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherComponent } from './components/weather/weather.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { WeatherCardComponent } from './components/weather-card/weather-card.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { AddLocationDialogComponent } from './components/addlocationdialog/addlocationdialog.component';

@NgModule({
  declarations: [
    WeatherComponent,
    WeatherCardComponent,
    ForecastComponent,
    AddLocationDialogComponent,
  ],
  imports: [
    GoogleMapsModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: 'weather', component: WeatherComponent },
    ]),
  ]
})
export class WeatherModule { }
