import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { FamilyMemberContext } from 'src/app/domain/familymembercontext';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { FamilyStoreService } from '../../services/family-store.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})

export class MemberFormComponent implements OnInit, OnDestroy, AfterViewInit {
  currentMember$: Observable<FamilyMemberContext> = this.familyStore.currentMemberContext$;
  tableName = 'family';
  locale: string;

  constructor(
    private familyStore: FamilyStoreService,
    private menuStore: MenuStoreService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {  this.locale = this.translate.currentLang; }

  ngAfterViewInit() {

    this.menuStore.componentInfo = {
      name: this.tableName,
      canUpdate: this.authorizationService.hasRights(this.tableName, 'canUpdate'),
      canCreate: this.authorizationService.hasRights(this.tableName, 'canCreate'),
      canDelete: this.authorizationService.hasRights(this.tableName, 'canDelete'),
      form: true,
      options: [],
      showFilter: false
    };
  }

  ngOnDestroy(): void {
  }
}
