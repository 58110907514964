import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { PdfList } from "../print/pdf-list";
import { ColumnDialogComponent } from './components/column-dialog/column-dialog.component';
import { ContinueDialogComponent } from './components/continue-dialog/continue-dialog.component';
import { FileNameDialogComponent } from './components/file-name-dialog/file-name-dialog.component';
import { ImagePopupDialogComponent } from './components/image-popup-dialog/image-popup-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { UserstampsFooterComponent } from './components/userstamps-footer/userstamps-footer.component';
import { CurrencyInputLocaleDirective } from './directives/ds-currency-input-locale.directive';
import { DsDndDirective } from "./directives/ds-dnd.directive";
import { IfHasRightDirective } from './directives/if-has-right.directive';
import { MatInputNumberLocaleDirective } from './directives/mat-input-number-locale.directive';
import { MatComponentModule } from './mat-components.module';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { AuthenticationService } from './services/authentication.service';
import { UploadFileService } from './services/uploadfile.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatComponentModule,
        TranslateModule,
    ],
    declarations: [
        IfHasRightDirective,
        ImagePopupDialogComponent,
        ColumnDialogComponent,
        FileNameDialogComponent,
        MessageDialogComponent,
        LocalDatePipe,
        MatInputNumberLocaleDirective,
        CurrencyInputLocaleDirective,
        UserstampsFooterComponent,
        ContinueDialogComponent,
        DsDndDirective,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        MatComponentModule,
        IfHasRightDirective,
        LocalDatePipe,
        MatInputNumberLocaleDirective,
        CurrencyInputLocaleDirective,
        UserstampsFooterComponent,
        DsDndDirective,
    ],
    providers: [
        AuthenticationService,
        UploadFileService,
        PdfList,
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    ]
})
export class SharedModule { }
