import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { AppUser } from './../../../domain/appuser';
import { AuthorizationService } from './../../services/authorization.service';

export interface Row {
  key: string;
  name: string;
  email: string;
}
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableName = 'users';
  initialRole = 'SUPERUSER';
  appUser: AppUser;
  orderBy = 'name';
  displayColumns = [];
  roles = [];
  users = [];
  usersRoles = [];
  hasWriteRights: boolean;
  dataSource;

  constructor(
    private dataService: DataService,
    private authorizationService: AuthorizationService,
    private configStore: ConfigStoreService
  ) { }

  ngOnInit() {
    this.displayColumns.push('name');
    this.displayColumns.push('email');
    this.roles = this.configStore.config.roles;
    if (!this.roles) {  // applications initial setup not done yet
      this.roles = [];
      this.roles.push(this.initialRole);
    }

    this.roles.forEach(role => this.displayColumns.push(role));

    this.dataService.readAllSortBy(this.tableName, this.orderBy).subscribe((appusers: AppUser[]) => {
      this.usersRoles = [];
      this.users = appusers;
      appusers.forEach(user => {
        const row: Row = {
          key: user.key,
          name: user.name,
          email: user.email
        };
        this.roles.forEach((role: string) => row[role] = false);
        if (user.role) { row[user.role] = true; }
        this.usersRoles.push(row);
      });

      this.dataSource = new MatTableDataSource(this.usersRoles);
      this.dataSource.paginator = this.paginator;

      this.hasWriteRights = this.authorizationService.hasRights(this.tableName, 'canUpdate');
      this.hasWriteRights = true;

      const hasCreateRights = this.authorizationService.hasRights(this.tableName, 'canCreate');
    });
  }

  ngOnDestroy(): void {
  }

  userChanged(row, role) {
    const currentUser = this.users.find(user => user.key === row.key);
    if (row[role]) { currentUser.role = role; } else { delete currentUser.role; }
    this.cleanUser(currentUser);
    this.dataService.set(this.tableName, currentUser);
  }

  cleanUser(user: AppUser) {
    Object.keys(user).forEach(item => {
      if (item.startsWith('has')) { delete user[item]; }
    });
  }

}
