<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
  {{data.message | translate}}
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="keep()" cdkFocusInitial>
    <mat-icon>undo</mat-icon>
    <span>{{'common.button.no' | translate}}</span>
  </button>
  <span class="spacer"></span>
  <button mat-raised-button (click)="delete()">
    <mat-icon>delete</mat-icon>
    {{'common.button.yes' | translate}}
  </button>
</div>
