import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from '../../guid';
import { FileUtil } from './../../file.util';
import { levenshtein } from './../../levenshtein';

export interface Row {
  selected: boolean;
  dbName?: string;
  appcol: string;
  xlsxcol: string;
  dist: number;
  currentLang?: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'file-name-dialog',
  templateUrl: './file-name-dialog.component.html',
  styleUrls: ['./file-name-dialog.component.scss']
})

export class FileNameDialogComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  title: string;
  rows: Row[] = [];
  allColumns: string[];
  appHeadersTranslated: { dbName: string, currentLang: string }[] = [];
  xlsxHeadersRow: string[];
  xlsxRecords;
  dataSource;
  isFileSelected = false;
  fileName: string;
  domain: string;
  displayedColumns = ['use', 'xlsxcol', 'appcol', 'select', 'dist'];
  excelDateBase = 25569;

  constructor(
    public dialogRef: MatDialogRef<FileNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { domain: string, title: string, columns: string[] },
    private translate: TranslateService,
    private fileUtil: FileUtil) {
    this.domain = data.domain;
    this.title = data.title;
    this.allColumns = data.columns;
  }

  ngOnInit(): void {
    this.fillAppHeader();
    this.rows = [];
    this.dataSource = new MatTableDataSource(this.rows);
    this.dataSource.paginator = this.paginator;
  }

  fileSelected(files: File[]) {

    this.fileUtil.import(files[0]).subscribe(data => {
      this.rows = [];
      this.xlsxRecords = data.rows;
      this.xlsxHeadersRow = data.header;
      this.xlsxHeadersRow.forEach(header => {
        const row = {
          selected: false,
          appcol: '',
          xlsxcol: header,
          dist: null,
        };
        // this.appHeadersTranslated.splice(appHeaderIndex, 1);
        this.rows.push(row);
      });

      this.matchWithLevenshtein();
      this.dataSource = new MatTableDataSource(this.rows);
      this.dataSource.paginator = this.paginator;
      this.isFileSelected = true;
      this.fileName = files[0].name;
    });
  }

  private matchWithLevenshtein() {
    this.xlsxHeadersRow.forEach((xlsxHeader: string) => {
      if (typeof xlsxHeader === 'string') {
        let minDist = 1000;
        let equivHeader = '';
        let equivHeaderKey = '';

        this.appHeadersTranslated.forEach(column => {
          const dist = levenshtein(column.currentLang.toLocaleLowerCase(), xlsxHeader.toLocaleLowerCase());
          if (dist < minDist) { minDist = dist; equivHeader = column.currentLang; equivHeaderKey = column.dbName; }
        });

        const index = this.rows.findIndex((row: Row) => row.xlsxcol === xlsxHeader);
        this.rows[index].dbName = equivHeaderKey;
        this.rows[index].appcol = equivHeader;
        this.rows[index].dist = minDist;
        if (minDist <= 2) { this.rows[index].selected = true; }
        // if (minDist === 0) {
        //   const removeIndex = this.appHeadersTranslated.findIndex(header => header['dbName'] === equivHeaderKey);
        //   this.appHeadersTranslated.splice(removeIndex, 1);
        // }
      }
    });
  }

  private fillAppHeader() {
    this.allColumns.forEach((dbName: string) => {
      const currentLang = this.translate.instant(this.domain + '.list.' + dbName.toLocaleLowerCase());
      const newColumn = { dbName, currentLang };
      this.appHeadersTranslated.push(newColumn);
    });
  }

  selectionChanged(currentRow) {

  }

  headerSelected(currentRow: Row) {
    const rowIndex = this.rows.findIndex(item => item.xlsxcol === currentRow.xlsxcol);
    this.rows[rowIndex].selected = true;
    const appHeaderIndex = this.appHeadersTranslated.findIndex(header => header.currentLang === currentRow.appcol);
    this.rows[rowIndex].appcol = this.appHeadersTranslated[appHeaderIndex].currentLang;
    this.rows[rowIndex].dbName = this.appHeadersTranslated[appHeaderIndex].dbName;
    // this.appHeadersTranslated.splice(appHeaderIndex, 1);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(append: boolean): void {
    const records: {}[] = [];
    this.xlsxRecords.forEach(record => {
      const line: {} = { key: Guid.newGuid() };

      this.rows.forEach(row => {
        if (row.selected && record[row.xlsxcol]) {
          if (row.dbName.toLocaleLowerCase().includes('date')) {
            line[row.dbName] = this.convertExcelDateToUnixDate(record[row.xlsxcol]);
          } else {
            line[row.dbName] = record[row.xlsxcol];
          }
        }
      });
      // console.log('Line: ', line);
      records.push(line);
    });
    this.dialogRef.close({ records, append });
  }

  private convertExcelDateToUnixDate(excelDate): number {
    // console.log((excelDate - this.excelDateBase) * 86400000);
    return (excelDate - this.excelDateBase) * 86400000;
  }
}
