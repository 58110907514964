import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  FormGroup,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { IsinService } from '../services/isin.service';
// ┌───────────── a 2-character ISO country code (A-Z)
// │ ┌─────────── a 9-character security code (A-Z, 0-9)
// │ │        ┌── a checksum digit (0-9)
// AU0000XVGZA3
// https://en.wikipedia.org/wiki/Luhn_algorithm

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[validateIsin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidateIsinDirective, multi: true },
  ],
})
export class ValidateIsinDirective implements Validator {
  @Input('validateIsin') isin: string;
  constructor(private isinService: IsinService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    let isin = control.value;
    if (!isin) return null;

    isin = isin.trim().toUpperCase();

    if (!isin.match('^[A-Z]{2}[A-Z0-9]{9}\\d$'))
      return { format: { message: 'not in format xx123456789n' } };

    const checkDigit = parseInt(isin.charAt(isin.length - 1), 10);
    const digitsWithoutCheckDigit = isin.substr(0, isin.length - 1);
    if (
      this.isinService.calcISINCheck(digitsWithoutCheckDigit) !== checkDigit
    ) {
      return { checksum: { message: 'lat digit does not match checksum' } };
    }
    return null;
  }
}
