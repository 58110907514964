<div fxLayout="column">
    <mat-tab-group animationDuration="0ms" fxFlex="0 1 50%" class="box" (selectedTabChange)="tabChange($event)">
        <mat-tab  label="{{'portfolio.tab.positions' | translate}}">
            <position-list></position-list>
        </mat-tab>
        <mat-tab label="{{'portfolio.tab.transactions' | translate}}">
            <transaction-list></transaction-list>
        </mat-tab>
        <mat-tab label="{{'portfolio.tab.securities' | translate}}">
            <security-list></security-list>
        </mat-tab>
    </mat-tab-group>
    <div class="box" fxFlex="0 1 40%">
        <performance-chart></performance-chart>
    </div>
</div>