import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageTokenService {

  constructor(
    private http: HttpClient
  ) { }

  getRefreshedAccessToken(): Observable<{}> {
    // const url = this.apiUrl + this.weather + '&lang=' + lang + '&id=' + locationId + '&APPID=' + this.apiKey;

    const url = 'https://www.googleapis.com/oauth2/v4/token?client_id=592752444195-7a1h199hn0bfng59vbrom662mpilv8vf.apps.googleusercontent.com&client_secret=N7b3YGtzKJou2AHhsyjuO9tN&refresh_token=1//03biLe4dMXpC7CgYIARAAGAMSNwF-L9IrL2omFekOIkWEmbrtd1gd7vz9aPGpvkfX6WQ0pEPnGMdi1_wlUgala_PymapS6tobGyU&grant_type=refresh_token';

    // console.log(' URL: ', url);
    return this.http.post(url, '');
  }

  getTemperature(accessId: string, projectId: string, deviceId: string): Observable<{}> {
    const url = `https://smartdevicemanagement.googleapis.com/v1/enterprises/${projectId}/devices/${deviceId}`;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessId, 'Content-Type': 'application/json' });
    const options = { headers };
    // console.log(' URL: ', url);
    return this.http.get(url, options);
  }

  getCameraStream(accessId: string, projectId: string, deviceId: string): Observable<{}> {
    // console.log('Project: ',projectId);
    // console.log('Device: ',deviceId);
    const url = `https://smartdevicemanagement.googleapis.com/v1/enterprises/${projectId}/devices/${deviceId}`;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + accessId, 'Content-Type': 'application/json' });
    const options = { headers };
    const dataRaw = { command: 'sdm.devices.commands.CameraLiveStream.GenerateRtspStream', params: {} };
    console.log(
      `curl -X POST \\
'https://smartdevicemanagement.googleapis.com/v1/enterprises/${projectId}/devices/${deviceId}:executeCommand:' \\
-H 'Content-Type: application/json' \\
-H 'Authorization: Bearer ${accessId}' \\
--data-raw '{
"command" : "sdm.devices.commands.CameraLiveStream.GenerateRtspStream",
"params" : {}
}'`
    );
    return this.http.post(url, dataRaw, options);
  }
}
