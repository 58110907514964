import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'image-popup-dialog',
  templateUrl: './image-popup-dialog.component.html',
  styleUrls: ['./image-popup-dialog.component.scss']
})
export class ImagePopupDialogComponent {
  imageUrl;
  // https://github.com/hallysonh/ngx-imageviewer
  constructor(
    public dialogRef: MatDialogRef<ImagePopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    // this.getBase64ImageFromUrl(data).then(result => { this.imageUrl = result; });
    this.imageUrl = data;
  }

  close() {
    this.dialogRef.close();
  }

  // private async  getBase64ImageFromUrl(imageUrl: string) {
  //   const res = await fetch(imageUrl);
  //   let blob = await res.blob();
  //   const type = blob.type;
  //   if (type === 'image/jpeg') { // work around for jpeg files
  //     blob = blob.slice(0, blob.size, '.jpeg');
  //   } else if (type === 'image/jpg') {
  //     blob = blob.slice(0, blob.size, '.jpg');
  //   } else {
  //     blob = blob.slice(0, blob.size, type);
  //   }

  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       resolve(reader.result);
  //     };
  //     reader.onerror = (e) => {
  //       console.log('failed with ' + e);
  //       reject(e);
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // }

}
