import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

// "https://api.openweathermap.org/data/2.5/weather?units=metric&lang=fr&APPID=707b5adde87eafd82e348117f67cd0b2&id=";
// "https://api.openweathermap.org/data/2.5/forecast?units=metric&lang=fr&APPID=707b5adde87eafd82e348117f67cd0b2&id=";

export class WeatherService {
  apiKey: string = environment.openweathermap.apiKey;
  apiUrl: string = environment.openweathermap.apiUrl;

  weather = 'weather?units=metric'; // https://openweathermap.org/weather-data
  forecast = 'forecast?units=metric'; // https://openweathermap.org/forecast5

  constructor(
    private translate: TranslateService,
    private http: HttpClient
  ) {  }

  getCurrentWeatherById(locationId: string): Observable<any> {
    const lang = this.translate.currentLang;
    const url = this.apiUrl + this.weather + '&lang=' + lang + '&id=' + locationId + '&APPID=' + this.apiKey;
    // console.log(' URL: ', url);
    return this.http.get(url);
  }

  getForecastById(locationId: string) {
    const lang = this.translate.currentLang;
    const url = this.apiUrl + this.forecast + '&lang=' + lang + '&id=' + locationId + '&APPID=' + this.apiKey;
    // console.log(' URL: ', url);
    return this.http.get(url);
  }

  getCurrentWeatherByCoord(lat: number,long: number): Observable<any> {
    const lang = this.translate.currentLang;
    const url = this.apiUrl + this.weather + '&lang=' + lang + '&lat=' + lat+ '&lon=' + long + '&APPID=' + this.apiKey;
    // console.log(' URL: ', url);
    return this.http.get(url);
  }

  getForecastByCoord(lat: number,long: number) {
    const lang = this.translate.currentLang;
    const url = this.apiUrl + this.forecast + '&lang=' + lang + '&lat=' + lat+ '&lon=' + long + '&APPID=' + this.apiKey;
    // console.log(' URL: ', url);
    return this.http.get(url);
  }
  
  getCoordByZIP(country:String,zipCode:String): Observable<any>{
    const apiUrl= 'https://api.openweathermap.org/geo/1.0/zip?zip='
    const url = apiUrl+ zipCode + ',' + country+ '&APPID=' + this.apiKey;
    // console.log(' URL: ', url);
    return this.http.get(url);
    
  }
    //   // https://api.openweathermap.org/geo/1.0/zip?zip=E14,GB&appid=707b5adde87eafd82e348117f67cd0b2
    //   // {"zip":"07220","name":"Saint-Thomé","lat":44.5015,"lon":4.624,"country":"FR"}
}
