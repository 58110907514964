import { MatPaginatorIntl } from '@angular/material/paginator';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeDECH from '@angular/common/locales/de-ch';
import localeFR from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AdminModule } from './admin/admin.module';
import { AdminStoreService } from './admin/services/admin-store.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuditModule } from './audit/audit.module';
import { FamilyModule } from './family/family.module';
import { FxRatesModule } from './fxrates/fxrates.module';
import { HomeModule } from './home/home.module';
import { MenuModule } from './menu/menu.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { FileUtil } from './shared/file.util';
import { CustomMatPaginatorIntl } from './shared/international-paginator';
import { ConfigStoreService } from './shared/services/config-store.service';
import { SharedModule } from './shared/shared.module';
import { WeatherModule } from './weather/weather.module';

registerLocaleData(localeFR);
registerLocaleData(localeDE);
registerLocaleData(localeDECH);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createCustomMatPaginatorIntl(
    translateService: TranslateService,
    translateParser: TranslateParser
) {
    return new CustomMatPaginatorIntl(translateService, translateParser);
}

export function configInit(
    configStore: ConfigStoreService
) {
    return (): Promise<any> => configStore.init();
}

export function userInit(
    adminStore: AdminStoreService
) {
    return (): Promise<any> => adminStore.init();
}

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        SharedModule,
        AdminModule,
        MenuModule,
        WeatherModule,
        FxRatesModule,
        FamilyModule,
        PortfolioModule,
        AuditModule,
        HomeModule,
        RouterModule.forRoot([
            { path: '', redirectTo: '/weather', pathMatch: 'full' },
            { path: '**', redirectTo: '' }
        ], { enableTracing: false })
    ],
    providers: [
        FileUtil,
        { provide: MatPaginatorIntl, useFactory: createCustomMatPaginatorIntl, deps: [TranslateService, TranslateParser] },
        { provide: LOCALE_ID, useValue: 'de-ch' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-ch' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: APP_INITIALIZER, useFactory: configInit, multi: true, deps: [ConfigStoreService] },
        { provide: APP_INITIALIZER, useFactory: userInit, multi: true, deps: [AdminStoreService] }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
