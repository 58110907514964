import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { Security } from 'src/app/domain/security';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { FileNameDialogComponent } from 'src/app/shared/components/file-name-dialog/file-name-dialog.component';
import { Guid } from 'src/app/shared/guid';
import { DataService } from 'src/app/shared/services/data.service';
import { IsinService } from '../../services/isin.service';
import { PortfolioStoreService } from '../../services/portfolio-store.service';
import { DividendDialogComponent } from '../dividend-dialog/dividend-dialog.component';
import { SecurityDialogComponent } from '../security-dialog/security-dialog.component';
import { TransactionDialogComponent } from '../transaction-dialog/transaction-dialog.component';
import { PositionSecurity } from './../../../domain/position-security';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit, OnDestroy, AfterViewInit {
  option$: Observable<string> = this.menuStore.option$;

  selectedPositions$: Observable<PositionSecurity[]> = this.portfolioStore.selectedPositions$;
  allPositions$: Observable<PositionSecurity[]> = this.portfolioStore.allPositions$;
  selectedSecurities$: Observable<Security[]> = this.portfolioStore.selectedSecurities$;

  tableName = 'portfolio';
  addtransaction = 'portfolio.list.addtransaction';
  addsecurity = 'portfolio.list.addsecurity';
  adddividends = 'portfolio.list.adddividends';
  addinterests = 'portfolio.list.addinterests';
  importsixlist = 'portfolio.list.importsix';
  transactionDialogRef: any;
  transaction: TransactionSecurity;
  subscriptions = new Subscription();
  hasUpdateRights: boolean;
  hasCreateRights: boolean;
  hasDeleteRights: boolean;
  dialog: any;
  fileNameDialogRef: any;
  securities: Security[] = [];
  security: Security;
  securityDialogRef: any;

  constructor(
    private dataService: DataService,
    private authorizationService: AuthorizationService,
    private matDialog: MatDialog,
    private isinService: IsinService,
    private menuStore: MenuStoreService,
    private portfolioStore: PortfolioStoreService
  ) { }

  ngOnInit(): void {
    this.hasUpdateRights = this.authorizationService.hasRights(
      this.tableName,
      'canUpdate'
    );
    this.hasCreateRights = this.authorizationService.hasRights(
      this.tableName,
      'canCreate'
    );
    this.hasDeleteRights = this.authorizationService.hasRights(
      this.tableName,
      'canDelete'
    );

    this.subscriptions.add(
      this.option$.subscribe((option) => {
        if (option === this.addtransaction) { this.newTransaction(); }
        if (option === this.addsecurity) { this.newSecurity(); }
        if (option === this.adddividends) { this.addDividends(); }
        if (option === this.addinterests) { this.addInterests(); }
        if (option === this.importsixlist) { this.importSymbolList(); }
      })
    );


    this.subscriptions.add(this.selectedPositions$
      .subscribe((positions: PositionSecurity[]) => {
        console.log('Selected positions updated', positions);
        this.portfolioStore.chart = { positions, chartType: 'line', option: 'percent' } ;
      }));;

    this.subscriptions.add(this.allPositions$
      .subscribe((positions: PositionSecurity[]) => {
        console.log('All positions updated', positions);
        this.portfolioStore.chart = { positions, chartType: 'area', option: undefined } ;
      }));

    this.subscriptions.add(this.selectedSecurities$
      .subscribe((sec: Security[]) => {
        console.log('Selected securities updated', sec);
        this.portfolioStore.chart = { positions: sec, chartType: 'line', option: 'percent' } ;
      }));
  }

  ngAfterViewInit() {
    const options: { label: string, icon: string }[] = [];
    if (this.hasCreateRights) {
      options.push({ label: this.addtransaction, icon: 'add_box' });
      options.push({ label: this.addsecurity, icon: 'add_box' });
      options.push({ label: this.adddividends, icon: 'save_alt' });
      options.push({ label: this.addinterests, icon: 'save_alt' });
      options.push({ label: this.importsixlist, icon: 'import_export' });
    }

    this.menuStore.componentInfo = {
      canUpdate: this.authorizationService.hasRights(this.tableName, 'canUpdate'),
      canCreate: this.authorizationService.hasRights(this.tableName, 'canCreate'),
      canDelete: this.authorizationService.hasRights(this.tableName, 'canDelete'),
      name: this.tableName,
      form: false,
      options,
      showFilter: true
    };
 }

ngOnDestroy(): void {
  this.subscriptions.unsubscribe();
}

tabChange(event) {
  this.portfolioStore.chart = { positions: [], chartType: '', option: ''  } ;
  // console.log('Tab change: ',event);
}

  private newTransaction() {
  this.transaction = { key: Guid.newGuid() };
  this.transactionDialogRef = this.matDialog.open(
    TransactionDialogComponent,
    {
      width: '750px',
      data: this.transaction,
    }
  );

  this.transactionDialogRef.afterClosed().subscribe((transaction) => {
    // console.log('Transaction: ', transaction);

    if (!transaction) {
      return;
    }
    this.dataService.set(this.tableName + '/transactions', this.transaction);
  });
}

  private newSecurity() {
  const security: Security = { key: 'tbd' };
  this.securityDialogRef = this.matDialog.open(SecurityDialogComponent,
    {
      width: '750px',
      data: security,
    }
  );

  this.securityDialogRef.afterClosed().subscribe((sec) => {
    // console.log('Security: ', security);

    if (!sec) {
      return;
    }
    sec.key = sec.symbol;
    this.dataService.set(this.tableName + '/securities', sec);
  });
}

addDividends() {
  this.transaction = { key: Guid.newGuid() };
  this.transactionDialogRef = this.matDialog.open(DividendDialogComponent,
    {
      width: '750px',
      data: this.transaction,
    }
  );

  this.transactionDialogRef.afterClosed().subscribe((transaction) => {
    if (!transaction) {
      return;
    }
    this.dataService.set(this.tableName + '/transactions', this.transaction);
  });
}

addInterests() {
  throw new Error('Method not implemented.');
}

  private importSymbolList() {
  const columns = ['company', 'symbol', 'valor', 'currency', 'country', 'exchange'];

  this.fileNameDialogRef = this.matDialog.open(FileNameDialogComponent, {
    width: '750px',
    data: { domain: 'portfolio', title: 'portfolio.import.title', columns },
  });

  this.fileNameDialogRef.afterClosed().subscribe((table) => {
    if (!table) {
      return;
    }

    if (!table.append) {
      this.dataService.deleteAll(this.tableName + '/securities').then(() => {
        this.addSymbols(table.records);
      });
    } else {
      this.addSymbols(table.records);
    }
  });
}

  private addSymbols(records) {
  records.forEach((record) => {
    record.key = record.symbol;
    if (record.country === 'CH')
      record.isin = this.isinService.valorToIsin(
        record.country,
        record.valor
      );
    this.dataService.set(this.tableName + '/securities', record);
  });
}
}
