import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateService, TranslateParser } from '@ngx-translate/core';

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    private rangeLabelIntl: string;

    constructor(
        private translateService: TranslateService,
        private translateParser: TranslateParser
    ) {
        super();
        this.getTranslations();
        this.translateService.onLangChange.subscribe(() => this.getTranslations());
    }

    getTranslations() {
        this.translateService
            .get(['paginator.items_per_range', 'paginator.next_page', 'paginator.previous_page', 'paginator.range'])
            .subscribe(translation => {
                this.itemsPerPageLabel = translation['paginator.items_per_range'];
                this.nextPageLabel = translation['paginator.next_page'];
                this.lastPageLabel = translation['paginator.last_page'];
                this.previousPageLabel = translation['paginator.previous_page'];
                this.firstPageLabel = translation['paginator.first_page'];
                this.rangeLabelIntl = translation['paginator.range'];
                this.changes.next();
            });
    }

    getRangeLabel = (page, pageSize, length) => {
        length = Math.max(length, 0);
        let startIndex = page * pageSize;
        const endIndex =
            startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        startIndex++;
        return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex, endIndex, length });
    }
}
