<form #form="ngForm" id="form" class="body">
  <h2 mat.dialog-title>{{ "transaction.form.title" | translate }}</h2>
  <mat-icon *ngIf="enableDeleteButton" class="cornerIcon" matTooltip="{{'transaction.form.delete.tooltip' | translate}}"
    (click)="deleteTransaction()">
    delete
  </mat-icon>
  <mat-dialog-content class="mat-typography">
    <section class="section">
      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-select required [(ngModel)]="transaction.buyOrSell" name="buyorsell" id="buyorsell"
          matTooltip="{{ 'transaction.form.buyorsell.tooltip' | translate }}" #buyorsell="ngModel" placeholder="{{
            'transaction.form.buyorsell.placeholder' | translate
          }}">
          <mat-option *ngFor="let type of transactionType" [value]="type.value">
            {{ type.label | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="buyorsell.invalid">{{"common.form.error.required" | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-label>{{"transaction.form.transactiondate.label" | translate}}</mat-label>
        <input matInput required disabled [matDatepicker]="picker1" [(ngModel)]="transactionDate"
          #transactiondate="ngModel" name="transactiondate"
          placeholder="{{'transaction.form.transactiondate.placeholder' | translate}}" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="false" #picker1></mat-datepicker>
        <mat-error *ngIf="transactiondate.invalid">{{"common.form.error.required" | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-select required [(ngModel)]="transaction.symbol" name="symbol" id="symbol"
          matTooltip="{{ 'transaction.form.symbol.tooltip' | translate }}" #symbol="ngModel"
          placeholder="{{ 'transaction.form.symbol.placeholder' | translate }}"
          (selectionChange)="updateLinkedFields(transaction.symbol)">
          <mat-option *ngFor="let security of securities" [value]="security.symbol">
            {{ security.symbol }} {{ security.company }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="symbol.invalid">{{"transaction.form.symbol.error.required" | translate}} </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-label>{{"transaction.form.exchange.label" | translate}}</mat-label>
        <input matInput disabled="true" [(ngModel)]="transaction.exchange" #exchange="ngModel" name="exchange"
          placeholder="{{'transaction.form.exchange.placeholder' | translate}}" />
      </mat-form-field>
    </section>

    <section class="section">
      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-label>{{ "transaction.form.isin.label" | translate }}</mat-label>
        <input matInput [(ngModel)]="transaction.isin" #isin="ngModel" id="isin" name="isin" validateIsin
          placeholder="{{ 'transaction.form.isin.placeholder' | translate }}" required />
        <mat-error *ngIf="!!isin.errors?.required">
          {{ "transaction.form.isin.error.required" | translate }}
        </mat-error>
        <mat-error *ngIf="!!isin.errors?.checksum">
          {{ "transaction.form.isin.error.checksum" | translate }}
        </mat-error>
        <mat-error *ngIf="!!isin.errors?.format">
          {{ "transaction.form.isin.error.format" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field style="width: 73%; padding-right: 2%;">
        <mat-label>{{
          "transaction.form.company.label" | translate
        }}</mat-label>
        <input matInput [(ngModel)]="transaction.company" #transactioncompany="ngModel" name="transactioncompany"
          placeholder="{{
            'transaction.form.company.placeholder' | translate
          }}" />
      </mat-form-field>

    </section>

    <section class="section">
      <mat-form-field style="width: 20%; padding-right: 2%;">
        <mat-label>{{ "transaction.form.units.label" | translate }}</mat-label>
        <input class="number" required matInput matInputNumberLocale [(ngModel)]="transaction.units" #units="ngModel"
          name="units" placeholder="{{ 'transaction.form.units.placeholder' | translate }}" />
        <mat-error *ngIf="units.invalid">{{"common.form.error.required" | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field style="text-align: center; width: 1%; padding: 0 2% 0 0;">
        <mat-label>x</mat-label> <input disabled="true" matInput />
      </mat-form-field>

      <mat-form-field style="width: 20%; padding-right: 2%;">
        <mat-label>{{"transaction.form.unitprice.label" | translate}}</mat-label>
        <input class="number" matInputNumberLocale required matInput [(ngModel)]="transaction.unitPrice"
          #unitPrice="ngModel" name="unitPrice"
          placeholder="{{'transaction.form.unitprice.placeholder' | translate}}" />
        <mat-error *ngIf="unitPrice.invalid">{{"common.form.error.required" | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field style="text-align: center; width: 1%; padding: 0 2% 0 0;">
        <mat-label>=</mat-label> <input disabled="true" matInput />
      </mat-form-field>

      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-label>{{ "transaction.form.price.label" | translate }}</mat-label>
        <input class="number" matInput matInputNumberLocale disabled="true" [(ngModel)]="transaction.price"
          #price="ngModel" name="price" placeholder="{{ 'transaction.form.price.placeholder' | translate }}" />
      </mat-form-field>

      <mat-form-field style="width: 23%; padding-right: 2%;">
        <mat-select required [(ngModel)]="transaction.currency" name="currency" id="currency" #currency="ngModel"
          placeholder="{{
            'transaction.form.currency.placeholder' | translate
          }}">
          <mat-option *ngFor="let currency of (config$ |async).currencies" [value]="currency">
            {{ currency }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="currency.invalid">{{
          "transaction.form.currency.error.required" | translate
        }}</mat-error>
      </mat-form-field>
    </section>

    <section>
      <mat-form-field style="width: 98%; padding-right: 2%;">
        <mat-label>{{"transaction.form.comment.label" | translate}}</mat-label>
        <textarea matInput [(ngModel)]="transaction.comment" #comment="ngModel"  name="comment"
          placeholder="{{'transaction.form.comment.placeholder' | translate}}"></textarea>
      </mat-form-field>
    </section>

    <section>
      <mat-form-field class="right">
        <mat-label>{{
          "transaction.form.priceinbaseccy.label" | translate
        }}</mat-label>
        <input matInput matInputNumberLocale disabled="true" [(ngModel)]="transaction.priceInBaseCcy"
          #priceInBaseCcy="ngModel" name="priceInBaseCcy" placeholder="{{
            'transaction.form.priceinbaseccy.placeholder' | translate
          }}" />
      </mat-form-field>
    </section>

    <section>
      <mat-form-field class="right">
        <mat-label>{{ "transaction.form.taxes.label" | translate }}</mat-label>
        <input matInputNumberLocale matInput required [(ngModel)]="transaction.taxes" #taxes="ngModel" name="taxes"
          placeholder="{{ 'transaction.form.taxes.placeholder' | translate }}" />
      </mat-form-field>
    </section>

    <section>
      <mat-form-field class="right">
        <mat-label>{{
          "transaction.form.commission.label" | translate
        }}</mat-label>
        <input matInputNumberLocale matInput required [(ngModel)]="transaction.commission" #commission="ngModel"
          name="commission" placeholder="{{
            'transaction.form.commission.placeholder' | translate
          }}" />
      </mat-form-field>
    </section>

    <section>
      <mat-form-field class="right">
        <mat-label>{{
          "transaction.form.totalcost.label" | translate
        }}</mat-label>
        <input matInput matInputNumberLocale disabled="true" [(ngModel)]="transaction.totalCost" #totalCost="ngModel"
          name="totalCost" placeholder="{{
            'transaction.form.totalcost.placeholder' | translate
          }}" />
      </mat-form-field>
    </section>

    <footer *ngIf="transaction.modifiedOn">
      <p class="mat-small">
        {{ "common.form.modifiedby" | translate }} :
        {{ transaction.modifiedby }}
        {{ "common.form.modifiedon" | translate }} :
        {{ transaction.modifiedon | date: "medium" }}
      </p>
    </footer>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button mat-raised-button (click)="$event.stopPropagation(); cancel()" cdkFocusInitial>
      <mat-icon>undo</mat-icon>
    </button>
    <span class="spacer"></span>
    <button mat-raised-button [disabled]="form.invalid" (click)="$event.stopPropagation(); proceed()">
      <mat-icon>cloud_done</mat-icon>
    </button>
  </div>
</form>