import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PortfolioStoreService } from '../../services/portfolio-store.service';
import { PositionSecurity } from './../../../domain/position-security';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['../../portfolio.scss']
})
export class PositionListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableName = 'portfolio';
  subscriptions = new Subscription();
  displayedColumns = [
    'company', 'symbol', 'units', 'totalprice', 'taxes', 'commission', 'totalcost',
    'currentprice', 'lastRefreshed', 'totalValue', 'dividends', 'interests', 'gainLoss', 'gainLossPercent', 'trend'
  ];
  dataSource = new MatTableDataSource();
  hasUpdateRights: boolean;
  hasCreateRights: boolean;
  hasDeleteRights: boolean;
  total: number;
  transactionDialogRef: any;
  transaction: TransactionSecurity;
  baseCcy: string;
  totalCosts: number;
  totalTaxes: number;
  totalCommission: number;
  totalValue: number;
  totalPrice: number;
  totalDelta: number;
  totalGainLoss: number;
  totalDividends: number;
  totalInterests: number;
  positions: PositionSecurity[];
  selectedPositions: PositionSecurity[] = [];
  selectedRows: string[] = [];

  constructor(
    private dataService: DataService,
    private authorizationService: AuthorizationService,
    private configStore: ConfigStoreService,
    private portfolioStore: PortfolioStoreService
  ) { }

  ngOnInit(): void {
    this.hasUpdateRights = this.authorizationService.hasRights(this.tableName, 'canUpdate');
    this.hasCreateRights = this.authorizationService.hasRights(this.tableName, 'canCreate');
    this.hasDeleteRights = this.authorizationService.hasRights(this.tableName, 'canDelete');

    this.baseCcy = this.configStore.config.baseCcy;

    this.subscriptions.add(
      this.dataService.readAll<PositionSecurity>(this.tableName + '/positions').subscribe((positions: PositionSecurity[]) => {
        this.dataSource.data = positions;
        this.positions = positions;
        this.setTotals(positions);
        this.triggerFullChart();
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.selectedPositions = [];
  }

  setTotals(positions: PositionSecurity[]) {
    this.totalCosts = 0;
    this.totalTaxes = 0;
    this.totalCommission = 0;
    this.totalValue = 0;
    this.totalPrice = 0;
    this.totalDelta = 0;
    this.totalGainLoss = 0;
    this.totalDividends = 0;
    this.totalInterests = 0;
    for (const position of positions) {
      this.totalCosts += position.totalCost;
      this.totalTaxes += position.taxes;
      this.totalCommission += position.commission;
      this.totalValue += position.totalValue;
      this.totalPrice += position.price;
      this.totalGainLoss += position.gainLoss;
      this.totalDividends += position.dividends;
      this.totalInterests += position.interests;
      // console.log('Transaction date: ', transaction.transactionDate);
    }
  }

  selectRow(row) {
    // console.log('Row: ', row,' selectedRow: ', this.selectedRows);
    const isThereAlready = this.selectedPositions.indexOf(row);

    if (isThereAlready !== -1) { // remove security from list
      // pos = this.selectedPositions.filter(position => position.symbol !== row.symbol);
      this.selectedPositions.splice(isThereAlready, 1);
      this.selectedRows.splice(isThereAlready, 1);
    } else { // add to list
      // pos = this.selectedPositions.concat(row);
      this.selectedPositions.push(row);
      this.selectedRows.push(row.symbol);
    }

    // console.log(this.selectedPositions);

    if (this.selectedPositions.length === 0) {
      this.portfolioStore.selectedPositions =  this.selectedPositions;
      this.triggerFullChart();
    } else {
      this.portfolioStore.selectedPositions =  this.selectedPositions;
    }
  }

  private triggerFullChart() {
    this.portfolioStore.allPositions =  this.positions;
  }
}
