import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyListComponent } from './components/family-list/family-list.component';
import { RouterModule } from '@angular/router';
import { FamilyAuthGuardService } from './services/family-auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { FamilyTreeComponent } from './components/family-tree/family-tree.component';
import { MemberFormComponent } from './components/member-form/member-form.component';
import { MformComponent } from './components/member-form/mform/mform.component';
import { ImageAndFormComponent } from './components/image-and-form/image-and-form.component';
import { ChildrenListComponent } from './components/member-form/children-list/children-list.component';
import { PersonDialogComponent } from './components/person-dialog/person-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    FamilyListComponent,
    FamilyTreeComponent,
    MemberFormComponent,
    MformComponent,
    ImageAndFormComponent,
    ChildrenListComponent,
    PersonDialogComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'family-list', component: FamilyListComponent, canActivate: [FamilyAuthGuardService] },
      { path: 'family', component: MemberFormComponent, canActivate: [FamilyAuthGuardService] },
      { path: 'family-tree/:id', component: FamilyTreeComponent, canActivate: [FamilyAuthGuardService] },
    ]),
  ]
})
export class FamilyModule { }
