import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IsinService {
  constructor() {}

  //
  //  Calculates a check digit for an isin
  //  @param {String} code an ISIN code with country code, but without check digit
  //  @return {Integer} The check digit for this code
  //

  calcISINCheck(code) {
    let conv = '';
    let digits = '';
    let sd = 0;
    // convert letters
    for (let i = 0; i < code.length; i++) {
      const charCode = code.charCodeAt(i);
      conv += charCode > 57 ? (charCode - 55).toString() : code[i];
    }
    // group by odd and even, multiply digits from group containing rightmost character by 2
    for (let i = 0; i < conv.length; i++) {
      digits += (
        parseInt(conv[i], 10) *
        (i % 2 === (conv.length % 2 !== 0 ? 0 : 1) ? 2 : 1)
      ).toString();
    }
    // sum all digits
    for (const digit of digits) {
      sd += parseInt(digit, 10);
    }
    // subtract mod 10 of the sum from 10, return mod 10 of result
    return (10 - (sd % 10)) % 10;
  }

  valorToIsin(country: string, valor: string | number): string {
    let code: string;
    if (typeof valor === 'number') {
      code = String(valor);
    } else {
      code = valor;
    }
    let filler = '';
    for (let i = 0; i < 9 - code.length; i++) {
      filler += '0';
    }
    const checksum = this.calcISINCheck(country + filler + code);
    return country + filler + code + checksum;
  }
}
