import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminStoreService } from 'src/app/admin/services/admin-store.service';
import { AppUser } from 'src/app/domain/appuser';
import { MenuStoreService } from '../../services/menu-store.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['../menu.scss']
})
export class SidenavComponent implements OnInit {
  appUser$: Observable<AppUser> = this.adminStore.appUser$;
  filterString$: Observable<string> = this.menuStore.filterString$;

  constructor(
    private router: Router,
    private menuStore: MenuStoreService,
    private adminStore: AdminStoreService
    ) { }

  ngOnInit(): void {
  }

  routeTo(path: String) {
    // console.log(path);
    if (path !== 'family-list') this.menuStore.filterString = '';
    this.router.navigate([path]);
  }
}
