    <div class="header mat-elevation-z4">
        <span class="spacer"></span>
        <span> {{'family.form.children.header' | translate}} </span>
        <span class="spacer"></span>
    </div>

    <mat-table matSort matSortActive="dateOfBirth" matSortDirection="asc" [dataSource]="dataSource">

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button matTooltip="{{'family.list.delete.tooltip' | translate}}" matTooltipPosition="below" matPrefix
                    mat-icon-button (click)="$event.stopPropagation(); deleteChild(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>
        <!-- FirstName Column -->
        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.firstname' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.lastname' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{'family.list.totalparts' | translate}}</mat-footer-cell>
        </ng-container>

        <!-- DateOfBirth Column -->
        <ng-container matColumnDef="dateOfBirth">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'family.list.dateofbirth' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateOfBirth | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- partNominal Column -->
        <ng-container matColumnDef="part">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.part' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.part}} </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{totParts | number}} </mat-footer-cell>
        </ng-container>

        <!-- DateOfDeath Column -->
        <ng-container matColumnDef="dateOfDeath">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'family.list.dateofdeath' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateOfDeath | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectChild(row)"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 15, 20, 50]"></mat-paginator>
