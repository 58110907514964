import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppUser } from 'src/app/domain/appuser';
import { AdminStoreService, RoleModulesPermissions } from './admin-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements OnDestroy {
  appUser$: Observable<AppUser> = this.adminStore.appUser$;
  roleModulesPermissions$: Observable<RoleModulesPermissions[]> = this.adminStore.roleModulesPermissions$;

  appUser: AppUser;
  appUserRole: string;
  roleModulesPermissions: RoleModulesPermissions[];
  subscriptions = new Subscription;

  constructor(
    private adminStore: AdminStoreService
  ) { this.init(); }

  private init(): void {
    this.subscriptions.add(this.appUser$.subscribe(appuser => {
      if (appuser) {
        this.appUser = appuser;
        this.appUserRole = this.appUser.role;
      }
    }));

    this.subscriptions.add(this.roleModulesPermissions$.subscribe((permissions: RoleModulesPermissions[]) => {
      this.roleModulesPermissions = permissions;
      // console.log(permissions);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasRights(module: string, permission: string) {
    let hasRight = false;

    if (!this.appUser) { return false; }
    // console.log(module, ': ', this.roleModulesPermissions);

    if ((this.roleModulesPermissions.length <= 3) &&
      ((module === 'permissions') || (module === 'admin'))) {
      // console.log('==> ', module, ': ', this.roleModulesPermissions);
      return true; // application not gone through initial setup yet
    }

    const modulePermissions = this.roleModulesPermissions.find((obj: RoleModulesPermissions) => obj['key'] === module);
    let modulePermissionsRoles;

    if (modulePermissions) {
      if (modulePermissions[permission]) {
        modulePermissionsRoles = modulePermissions[permission].roles;
      }
    }

    if (modulePermissionsRoles) {
      hasRight = modulePermissionsRoles[this.appUserRole];
    }

    // if (hasRight) {
    //   console.log('User ', this.appUser.name, ' with role ', this.appUser.role, ' has ', permission, ' rights in ', module);
    // } else {
    //   console.log('User ', this.appUser.name, ' with role ', this.appUser.role, ' has NO ', permission, ' rights in ', module);
    // }

    return hasRight;
  }

}
