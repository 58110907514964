import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription, takeUntil, Subject } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { FamilyMember } from 'src/app/domain/familymember';
import { FamilyMemberContext } from 'src/app/domain/familymembercontext';
import { FamilyStoreService } from 'src/app/family/services/family-store.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { DataService } from 'src/app/shared/services/data.service';

export interface Row {
  firstname: string,
  birthday: number,
  deathday: number,
  part: number,
  partner: string
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'children-list',
  templateUrl: './children-list.component.html',
  styleUrls: ['./children-list.component.scss']
})

export class ChildrenListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentMember$: Observable<FamilyMemberContext> = this.familyStore.currentMemberContext$;


  dataSource = new MatTableDataSource();
  children: FamilyMember[];
  member: FamilyMember;

  subscriptions = new Subscription();

  displayedColumns = ['delete', 'firstName', 'lastName', 'part', 'dateOfBirth', 'dateOfDeath'];
  tableName = 'family';
  hasDeleteRights: boolean = false;
  destroyed = new Subject<void>();
  currentScreenSize: string = 'md';
  totParts: number = 0;
  currentMember: FamilyMemberContext;

  constructor(
    private authorizationService: AuthorizationService,
    private dialog: MatDialog,
    private familyStore: FamilyStoreService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.hasDeleteRights = this.authorizationService.hasRights(this.tableName, 'canDelete');

    this.subscriptions.add(this.currentMember$.subscribe(currentMember => {
      // console.log('ChildrenListComponent current member: ', currentMember);
      if (currentMember) {
        this.currentMember = currentMember;
        this.dataSource.data = currentMember.children ? currentMember.children : null;
        this.getTotalParts();
      }
    }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyed.next();
    this.destroyed.complete();
  }

  getTotalParts(): void {
    this.totParts = 0;
    for (const child of this.currentMember.children) {
      if (!isNaN(child.part)) this.totParts += child.part
    }
  }

  selectChild(row) {
    // console.log('View details row: ', row);
    this.familyStore.setCurrentRecord(row);
  }

  deleteChild(row) {
    // console.log('Delete row: ', row);
    if (row.children) { // child has children - can't be deleted
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { title: 'common.dialog.message.title', message: 'common.dialog.message.haschildren' };
      dialogConfig.width = '35vw';
      dialogConfig.enterAnimationDuration = 300;
      dialogConfig.exitAnimationDuration = 500;
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const messageDialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
      messageDialogRef.afterClosed().subscribe(remove => { return; });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { title: 'common.dialog.confirm.delete.title', message: 'common.dialog.confirm.delete.message' };
      dialogConfig.width = '35vw';
      dialogConfig.enterAnimationDuration = 300;
      dialogConfig.exitAnimationDuration = 500;
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

      confirmDialogRef.afterClosed().subscribe(remove => {
        if (!remove) return;

        const indexSelf = this.currentMember.self.childrenIds.indexOf(row.key);
        const indexPartner = this.currentMember.partner ? this.currentMember.partner.childrenIds.indexOf(row.key) : -1;
        if (indexSelf > -1) {
          this.currentMember.self.childrenIds.splice(indexSelf, 1);
          this.dataService.update(this.tableName, this.currentMember.self);
        }
        if (indexPartner > -1) {
          this.currentMember.partner.childrenIds.splice(indexPartner, 1);
          this.dataService.update(this.tableName, this.currentMember.partner);
        }
        this.dataService.delete(this.tableName, row);
      });
    }
  }
}
