import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {
  private returnUrl='';
  private locale='';
  date: string = environment.date;
  name: string = environment.name;
  version: string = environment.version;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.locale = this.translate.currentLang;
    // this.auth.signOut();
    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log('Return URL: ', this.returnUrl);
  }

  ngAfterViewInit() {
    // firebase.auth().languageCode = this.locale;
  }

  successfullLogin(event) {
    // console.log('successfullLogin event: ', event.authResult.credential);
  }

  unSuccessfullLogin(event) {
    console.log('un-successfullLogin event: ', event);
    // console.log(event);
  }

  uiShown() {
    // console.log('UI shown');
  }

}