import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PositionSecurity } from 'src/app/domain/position-security';
import { TimeSeries } from 'src/app/domain/timeseries';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PortfolioStoreService } from '../../services/portfolio-store.service';
import { StockService } from '../../services/stock.service';
import { SecurityDialogComponent } from '../security-dialog/security-dialog.component';
import { Security } from './../../../domain/security';

interface TickerSymbol {
  symbol: string;
  exchange: string;
}

// export interface TimeSeries {name: string, data: number[]};

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'security-list',
  templateUrl: './security-list.component.html',
  styleUrls: ['../../portfolio.scss']
})
export class SecurityListComponent implements OnInit, OnDestroy, AfterViewInit {
  filterString$: Observable<string> = this.menuStore.filterString$;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableName = 'portfolio';
  chartDataAvailable = false;
  updateRequired = false;
  series: TimeSeries[];
  positions: PositionSecurity[];
  subscriptions = new Subscription();
  allSymbols: TickerSymbol[] = [];
  transactionSymbols: TickerSymbol[] = [];
  watchListSymbols: TickerSymbol[] = [];
  callWaitTime = 12000; // 60*1000/5 maximum 5 calls per minute
  transactions: TransactionSecurity[];
  stale: boolean;
  displayedColumns = ['watchList', 'company', 'symbol', 'securityType', 'isin', 'valor', 'currency', 'exchange'];
  dataSource = new MatTableDataSource();
  selectedSecurities: Security[] = [];
  securities: Security[];
  timeSeries: TimeSeries[];
  securityDialogRef: any;

  constructor(
    private stockService: StockService,
    private dataService: DataService,
    private matDialog: MatDialog,
    private menuStore: MenuStoreService,
    private portfolioStore: PortfolioStoreService
  ) { }

  ngOnInit(): void {

    this.subscriptions.add(this.dataService.readAll<TimeSeries>(this.tableName + '/timeseries/')
      .subscribe((data: TimeSeries[]) => this.timeSeries = data));

    this.subscriptions.add(combineLatest([
      this.dataService.readAll<TransactionSecurity>(this.tableName + '/transactions'),
      this.dataService.readAll<Security>(this.tableName + '/securities')
    ]).subscribe(([transactions, securities]) => {
      if (transactions) {
        this.transactions = transactions;
        for (const transaction of transactions) {
          const index = this.transactionSymbols.findIndex(symbol => (transaction.symbol === symbol.symbol));
          if (index === -1) this.transactionSymbols.push({ symbol: transaction.symbol, exchange: transaction.exchange });
        }
      }

      if (securities) {
        for (const security of securities) {
          this.dataSource.data = securities;
          if (security.watchList) {
            const index = this.watchListSymbols.findIndex(symbol => (security.symbol === symbol.symbol));
            if (index === -1) {
              this.watchListSymbols.push({ symbol: security.symbol, exchange: security.exchange });
              this.selectedSecurities.push(security);
            }
          }
        }
      }

      this.allSymbols = this.transactionSymbols.concat(this.watchListSymbols);
      if (this.timeSeries) this.verifyTimeSeries(this.allSymbols);
      this.portfolioStore.selectedSecurities = this.selectedSecurities;

    }));

    this.subscriptions.add(this.filterString$.subscribe(filterString => this.filter(filterString)));

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private filter(query: string) {
    if (query) {
      this.dataSource.filter = query.trim().toLowerCase();
    } else {
      this.dataSource.filter = '';
    }
    if (this.dataSource.paginator) { this.dataSource.paginator.firstPage(); }
  }

  selectionChanged(row) {
    row.watchList = !row.watchList;
    this.dataService.update(this.tableName + '/securities', row, row.symbol);
  }

  selectedRow(row: Security) {
    this.securityDialogRef = this.matDialog.open(SecurityDialogComponent, { width: '750px', data: row, });

    this.securityDialogRef.afterClosed().subscribe((security) => {
      if (!security) { return; }

      this.dataService.set(this.tableName + '/securities', security);
    });
  }

  private verifyTimeSeries(symbols: { symbol: string, exchange: string }[]) {
    const outdated: { symbol: string, exchange: string }[] = [];
    this.series = [];
    let urlRefreshDone = false;
    // console.log('verifyTimeSeries: ', symbols);

    for (const symbol of symbols) {
      this.updateRequired = true;

      const ts: TimeSeries = this.timeSeries.find(series => series.key === symbol.symbol);

      if (ts) {
        this.updateRequired = false;
        const lastUpdated = ts.lastRefreshed;
        const lastRequested = new Date(ts.lastRequested);
        const today = new Date(Date.now());
        const weekday = today.getDay();
        const sinceLastUpdate = Math.round((today.valueOf() - lastUpdated) / (24 * 3600000) - 1.0);

        // console.log(symbol.symbol, 'Days: ', sinceLastUpdate, ' LastUpdated: ',
        // new Date(lastUpdated), ' LastRequested: ', new Date(lastRequested));
        // if lastRefresh is not today (or Friday if we have a weekend) get new data from URL

        if ((weekday === 6) && (sinceLastUpdate > 1)) this.updateRequired = true;
        if ((weekday === 0) && (sinceLastUpdate > 2)) this.updateRequired = true;
        if ((weekday > 0) && (weekday < 6) && (sinceLastUpdate > 0)) this.updateRequired = true;

        if ( // we already updated to date but received no new data (public holidays etc.)
          (lastRequested.getDate() === today.getDate()) &&
          (lastRequested.getMonth() === today.getMonth()) &&
          (lastRequested.getFullYear() === today.getFullYear())
        ) this.updateRequired = false;

        if (!this.updateRequired) {
          const index = this.series.findIndex(series => series.key === symbol.symbol);
          if (index === -1) this.series.push(ts);
        }
      }

      if (this.updateRequired && !urlRefreshDone) outdated.push(symbol);
    }

    if (outdated.length > 0) {
      if (!urlRefreshDone) {
        console.log('Outdated: ', outdated);
        this.updateTimeSeries(outdated, 0);
        urlRefreshDone = true;
      }
    }
  }

  private calculatePositions(storeInDB: boolean) {
    this.positions = [];

    for (const symbol of this.transactionSymbols) {
      const position: PositionSecurity = { key: symbol.symbol, symbol: symbol.symbol };
      const indexOfSymbol = this.series.findIndex(serie => serie.key === symbol.symbol);
      const timeseries = this.series[indexOfSymbol];
      position.priceInBaseCcy = 0;
      position.units = 0;
      position.commission = 0;
      position.taxes = 0;
      position.price = 0;
      position.dividends = 0;
      position.interests = 0;
      position.totalCost = 0;
      position.totalValue = 0;
      position.gainLoss = 0;

      for (const transaction of this.transactions) {
        if (transaction.symbol === symbol.symbol) {
          // console.log('Timeseries: ', this.series);
          // console.log('Found timeseries: ', timeseries);
          // console.log('transaction: ', transaction);
          position.lastPrice = timeseries.lastPrice;
          position.lastRefreshed = timeseries.lastRefreshed;
          position.company = transaction.company;

          if (transaction.priceInBaseCcy) position.priceInBaseCcy += transaction.priceInBaseCcy;
          if (transaction.units && !transaction.buyOrSell) position.units += transaction.units;
          if (transaction.units && transaction.buyOrSell) position.units += transaction.buyOrSell * transaction.units;
          if (transaction.commission) position.commission += transaction.commission;
          if (transaction.taxes) position.taxes += transaction.taxes;
          if (transaction.price) position.price += transaction.price;
          if (transaction.dividends) position.dividends += transaction.dividends;
          if (transaction.interests) position.interests += transaction.interests;
          if (transaction.totalCost) position.totalCost += transaction.totalCost;
          if (position.units) position.totalValue += position.units * position.lastPrice;
        }
      }

      position.gainLoss += position.units * position.lastPrice - position.totalCost + position.dividends + position.interests;
      console.log('Store in DB: ', storeInDB, 'Position: ', position);
      this.positions.push(position);
    }

    if (storeInDB) this.storePositions();
  }

  private storePositions() {
    const posDB = {};
    for (const position of this.positions) {
      posDB[position.key] = position;
    }
    console.log(posDB);
    this.dataService.set(this.tableName, posDB, 'positions');
  }

  private updateTimeSeries(symbols: { symbol: string, exchange: string }[], index: number) {

    let currentSymbol: string;
    if (!symbols[index].exchange) {
      currentSymbol = symbols[index].symbol
    } else {
      currentSymbol = symbols[index].symbol + '.' + symbols[index].exchange;
    }

    this.stockService.getTimeSeriesCompact(currentSymbol).pipe(take(1))
      .subscribe(async series => {
        if (series) {
          const timestamp = Date.now();
          console.log(new Date(timestamp), ' Updating: ', symbols[index].symbol, index + 1, ' of ', symbols.length);

          const data: TimeSeries = {
            key: symbols[index].symbol,
            symbol: symbols[index].symbol,
            exchange: symbols[index].exchange,
            lastRefreshed: series.lastRefreshed,
            lastRequested: Date.now(),
            lastPrice: series.lastPrice,
            series: series.timeSeries
          };

          this.dataService.update(this.tableName + '/timeseries/', data);
          await this.delay(this.callWaitTime);
          if (symbols[++index]) { this.updateTimeSeries(symbols, index); } else {
            this.calculatePositions(true);
          }
        }
      });
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
