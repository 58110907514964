import { DataService } from 'src/app/shared/services/data.service';
import { AddLocationDialogComponent } from './../addlocationdialog/addlocationdialog.component';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigDialogArrayComponent } from "src/app/admin/components/config/config-dialog-array/config-dialog-array.component";
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { Forecast } from 'src/app/domain/forecast';
import { Weather } from 'src/app/domain/weather';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { Config, ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { WeatherService } from '../../weather.service';
import { OpenWeatherCoordinate } from "src/app/domain/owcoord";

export interface Position { lat: number, lon: number };
export interface WeatherForeCast { weather: Weather, forecast: Forecast };
// https://openweathermap.org/weather-data
// Viviers: 44.481013746376746, 4.690227349019343
// Zürich: 47.39088574004697, 8.51049337895679

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit, AfterViewInit, OnDestroy {
  config$: Observable<Config> = this.configStore.config$;
  option$: Observable<string> = this.menuStore.option$;

  weatherForecasts: WeatherForeCast[] = [];
  positions: Position[] = [];
  locationIds: string[] = [];
  cities: string[] = [];
  forecastAvailable = false;
  weatherAvailable = false;
  tableName = 'weather';
  addLocation = 'weather.option.addlocation';
  configDialogArrayRef: MatDialogRef<ConfigDialogArrayComponent>;

  subscriptions = new Subscription();

  constructor(
    private weatherService: WeatherService,
    private menuStore: MenuStoreService,
    private dataService: DataService,
    private configStore: ConfigStoreService,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const locations = this.authorizationService.appUser.locations;
    // console.info('Locations: ', locations);

    navigator.geolocation.getCurrentPosition(position => {
      this.positions.push({ lat: position.coords.latitude, lon: position.coords.longitude });
      this.cities.push('currentPosition');
      // console.info('Current location: ', this.positions[0].lat, this.positions[0].lon);
      if (locations) {
        Object.keys(locations).forEach(location => {
          this.cities.push(location);
          this.positions.push({ lat: locations[location].lat, lon: locations[location].lon });

          // console.info(locations[location].name, ': ', locations[location].lat, locations[location].lon);
        });
      }
      for (const position of this.positions) this.weatherForecasts.push({ weather: null, forecast: null });

      // console.info('weatherForecasts: ', this.weatherForecasts, this.positions.length);
      this.getAllCurrentWeatherFromUrl(this.positions, 0);
      this.getAllForecastsFromUrl(this.positions, 0);
    });


    this.subscriptions.add(
      this.option$.subscribe(option => {
        if (option === this.addLocation) { this.addLocationDialog(); }
      })
    );
  }

  ngAfterViewInit(): void {
    const options: { label: string, icon: string }[] = [];
    if (this.authorizationService.hasRights('config', 'canUpdate')) {
      options.push({ label: this.addLocation, icon: 'add_location_alt' });
    }

    this.menuStore.componentInfo = {
      name: this.tableName,
      form: false,
      canUpdate: this.authorizationService.hasRights(this.tableName, 'canUpdate'),
      canCreate: this.authorizationService.hasRights(this.tableName, 'canCreate'),
      canDelete: this.authorizationService.hasRights(this.tableName, 'canDelete'),
      options,
      showFilter: false
    };

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addLocationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: 'weather.location.addlocation.title', message: 'weather.location.addlocation.title' };
    dialogConfig.width = '500px';
    dialogConfig.enterAnimationDuration = 300;
    dialogConfig.exitAnimationDuration = 500;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const addLocationDialog = this.dialog.open(AddLocationDialogComponent, dialogConfig);

    addLocationDialog.afterClosed().subscribe((coord: OpenWeatherCoordinate) => {
      if (!coord) return;

      coord.key = coord.name;
      const uid = this.authorizationService.appUser.key;
      this.dataService.set('users/' + uid + '/locations', coord);
      window.location.reload();
    });
  }

  private getAllCurrentWeatherFromUrl(positions: Position[], index: number) {
    // console.info('Get weather for: ', positions[index], ' index: ', index);
    this.weatherService.getCurrentWeatherByCoord(positions[index].lat, positions[index].lon)
      .pipe(take(1))
      .subscribe((weather: Weather) => {
        this.weatherForecasts[index].weather = weather;
        // console.info('Weather: ', this.weatherForecasts[index].weather, ' index: ', index);
        if (positions[++index]) { this.getAllCurrentWeatherFromUrl(positions, index); } else {
          this.weatherAvailable = true;
          for (const weatherForecasts of this.weatherForecasts) {
            // console.info('Weather: ', weatherForecasts.weather);
          }
          return;
        }
      });
  }

  private getAllForecastsFromUrl(positions: Position[], index: number) {
    // console.info('Get forecast for: ', list[index]);
    this.weatherService.getForecastByCoord(positions[index].lat, positions[index].lon)
      .pipe(take(1))
      .subscribe((forecast: Forecast) => {
        this.weatherForecasts[index].forecast = forecast;
        if (positions[++index]) { this.getAllForecastsFromUrl(positions, index); } else {
          this.forecastAvailable = true;
          for (const weatherForecasts of this.weatherForecasts) {
            // console.log('Forecast: ', weatherForecasts.forecast);
          }
          return;
        }
      });
  }

  private getWeatherForLocation(position, index: number) {

    this.weatherService.getForecastByCoord(position[index].lat, position[index].lon)
      .pipe(take(1))
      .subscribe((forecast: Forecast) => {
        this.weatherForecasts[index].forecast = forecast;
        this.forecastAvailable = true;
      });

    this.weatherService.getCurrentWeatherByCoord(position[index].lat, position[index].lon)
      .pipe(take(1))
      .subscribe((weather: Weather) => {
        this.weatherForecasts[index].weather = weather;
        this.weatherAvailable = true;
      });

  }
}
