import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[matInputNumberLocale]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: MatInputNumberLocaleDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputNumberLocaleDirective),
      multi: true,
    },
  ],
})
export class MatInputNumberLocaleDirective {
  private _value: string | null;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private translate: TranslateService
  ) {}

  get value(): string | null {
    console.log('get value: ', this._value);
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null) {
    console.log('formatValue: ', value);
    if (value) {
      // this.elementRef.nativeElement.value = this.numberWithCommas(value);
      this.elementRef.nativeElement.value = this.formatNumberValue(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    console.log('unFormatValue: ', this.value);
    const value = this.elementRef.nativeElement.value;
    this._value = value.replace(/[^\d.-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(/[^\d.-]/g, '');
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('blur')
  _onBlur() {
    console.log('_onBlur: ', this._value);
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    console.log('onFocus');
    this.unFormatValue();
  }

  _onChange(value: any): void {}

  writeValue(value: any) {
    // console.log('writeValue: ', value);
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}

  private numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private formatNumberValue(value: string): string {
    let currentLang = this.translate.currentLang;
    if (currentLang.toUpperCase() === 'DE') currentLang = 'de-CH';
    return parseFloat(value).toLocaleString(currentLang, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}
