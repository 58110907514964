import { ACTIONS } from './../../domain/actions';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppUser } from 'src/app/domain/appuser';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from "src/app/shared/services/authentication.service";
export interface RoleModulesPermissions {
  permissions?: {
    action: ACTIONS,
    modifiedBy: string;
    modifiedOn: number;
    roles: {}[]
  }[];
  key: string;
  modifiedBy?: string;
  modifiedOn?: number;
}

@Injectable({ providedIn: 'root' })
export class AdminStoreService {
  private appUserSubject = new BehaviorSubject<AppUser>(null);
  private roleModulesPermissionsSubject = new BehaviorSubject<RoleModulesPermissions[]>([]);

  public appUser$: Observable<AppUser> = this.appUserSubject.asObservable();;
  public roleModulesPermissions$: Observable<RoleModulesPermissions[]> = this.roleModulesPermissionsSubject.asObservable();

  constructor(
    private auth: AuthenticationService,
    private userService: UserService
  ) { }

  init(): Promise<any> {
    // console.log('init()');

    let userId: any;

    return new Promise((resolve, reject) => {
      this.auth.user$
        .pipe(
          switchMap(user => {
            userId = user;
            // console.log('User: ', userId);
            return (!userId) ? of(undefined) : this.auth.appUser$;
          })
        )
        .pipe(
          switchMap((appUser: AppUser) => {
            // console.log('Appuser: ', appUser);
            if (!appUser && userId) { appUser = this.userService.save(userId); }
            this.appUserSubject.next(appUser)
            return this.userService.permissions();
          })
        )
        .subscribe((entries: RoleModulesPermissions[]) => {
          // console.log('Role-Modules-Permissions: ', entries);
          this.roleModulesPermissionsSubject.next(entries);
          return resolve('Success');
        });
    });
  }

  public get roleModulesPermissions(): RoleModulesPermissions[] {
    return this.roleModulesPermissionsSubject.getValue();
  }

  public get appUser(): AppUser {
    return this.appUserSubject.getValue();
  }

  public set appUser(appUser: AppUser) {
    this.appUserSubject.next(appUser);
  }
}

