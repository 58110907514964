<h1 mat-dialog-title>{{data.title | translate}}</h1>

<form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content class="contentGrid">
        <mat-form-field appearance="outline" class="field1">
            <mat-select formControlName="country" name="country"
                matTooltip="{{'weather.location.addlocation.country' | translate}}"
                placeholder="{{'weather.location.addlocation.country' | translate}}">
                <mat-option *ngFor="let country of countries" [value]="country"> {{country}} </mat-option>
            </mat-select>
            <mat-error *ngIf="locationForm.get('country').hasError('required')">
                {{'weather.location.addlocation.error.required' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="field2" *ngIf="locationForm.get('country').value !=''" appearance="outline">
            <mat-label>{{'weather.location.addlocation.zip' | translate}}</mat-label>
            <input matInput formControlName="zipCode" name="zipCode"
                placeholder="{{'weather.location.addlocation.zip' | translate}}">
            <mat-error *ngIf="locationForm.get('zipCode').hasError('required')">
                {{'weather.location.addlocation.error.required' | translate}} </mat-error>
            <mat-error *ngIf="locationForm.get('zipCode').hasError('pattern')">
                {{'weather.location.addlocation.error.zipformat' | translate}} </mat-error>
            <mat-hint align="start">{{matHint}}</mat-hint>
        </mat-form-field>

        <div class="map box" *ngIf="!locationForm.valid || error"></div>
        <google-map class="map" *ngIf="locationForm.valid && !error" height="300px" width="100%" [zoom]="zoom"
            [center]="center" [options]="options">
        </google-map>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button (click)="cancel()" cdkFocusInitial>
            <mat-icon>undo</mat-icon>
            <span>
                {{'weather.location.addlocation.cancel' | translate}}
            </span>
        </button>

        <span class="spacer"></span>

        <button [disabled]="!locationForm.valid || error"  mat-raised-button (click)="keep()">
            <mat-icon>save</mat-icon>
            <span>
                {{'weather.location.addlocation.add' | translate}}
            </span>
        </button>
    </div>
</form>