import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { DataService } from './data.service';
// https://fireship.io/lessons/angular-firebase-storage-uploads-multi/

@Injectable()
export class UploadFileService {

  uploadPercent: Observable<number>;

  constructor(
    private storage: AngularFireStorage,
    private dataService: DataService,
  ) { }

  uploadAttachment(table: string, file: File, data: any, imageKey: string) {
    const existingUrl = data[imageKey];
    const endPos = imageKey.indexOf('Url');

    const path = '/' + table + '/' + data['key'] + '/' + imageKey.substring(0, endPos) + '/' + file.name;
    // console.log(path);
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file);
    this.uploadPercent = task.percentageChanges();

    if (existingUrl) { this.deleteImage(existingUrl); }

    // get notified when the download URL is available
    task.snapshotChanges().pipe(finalize(() => {
      fileRef.getDownloadURL().subscribe(url => {
        // console.log(url);
        data[imageKey] = url;
        this.dataService.update(table, data).catch(error => {
          alert('Error occured while updating \n' + data + ': ' + error);
        });
      });
    })).subscribe();
  }

  updateReceipt(file: File, member: any, year: number, deleteAttachment: boolean, imageKey: string) {
    // console.log(year, member, file, deleteAttachment);
    const existingUrl = member.subscriptions[year][imageKey];
    if (existingUrl) { this.deleteImage(existingUrl); }

    if (deleteAttachment) {
      delete member.subscriptions[year][imageKey];
      this.dataService.update('family', member);
      return;
    }

    const path = '/subscriptions/';
    const fileRef = this.storage.ref(path + member['key'] + '/' + year);
    const task = this.storage.upload(path + member['key'] + '/' + year, file);


    // get notified when the download URL is available
    task.snapshotChanges().pipe(finalize(() => {
      fileRef.getDownloadURL().subscribe(url => {
        // console.log(url);
        member.subscriptions[year][imageKey] = url;
        this.dataService.update('members', member).then(() => {
        }).catch(error => {
          alert('Error occured while updating \n' + member + ': ' + error);
        });
      });
    })).subscribe();
  }

  private deleteImage(url: string) {
    this.storage.storage.refFromURL(url).delete().then(() => {
    }).catch(error => {
      alert('Error occured while deleting file ' + url + ': ' + error);
    });
  }

  deleteAll(type: string, imageKey: string) {
    this.dataService.readAllSortBy(type, 'key').pipe(first()).subscribe(data => {
      data.forEach(entry => {
        this.deleteImage(entry[imageKey]);
        this.dataService.delete(type, entry);
      });
    });
  }

  deleteImageAndDBReference(table: string, data: any, imageKey: string) {
    const url = data[imageKey];

    delete data[imageKey];
    this.dataService.set(table,  data).then(() => {
    }).catch(error => {
      alert('Error occured while deleting image field in record ' + data + ': ' + error);
    });
    this.deleteImage(url);
  }

}
