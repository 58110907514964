import { Component } from '@angular/core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./menu/components/menu.scss']
})

export class AppComponent {
    constructor() { }
}
