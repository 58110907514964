<div class="wrapper">
  <h4 class="header" color="basic">{{ 'config.dialog.header' | translate }}</h4>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="key">
      <mat-header-cell *matHeaderCellDef> {{ 'config.table.key' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> {{ 'config.table.description' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef> {{ 'config.table.count' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="source">
      <mat-header-cell *matHeaderCellDef> {{ 'config.table.source' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.source | translate}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>