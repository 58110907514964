import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Component, Inject, ViewChild } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'config-dialog-string',
  templateUrl: './config-dialog-string.component.html',
  styleUrls: ['./config-dialog-string.component.scss']
})
export class ConfigDialogStringComponent  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  header: string;
  key: string;
  item: string;
  previousEntry: string;

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogStringComponent>,
    @Inject(MAT_DIALOG_DATA) data: { header: string, key: string, item: string }) {
    this.header = data.header;
    this.key = data.key;
    this.item = data.item;
    this.previousEntry = data.item;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  proceed(): void {
    this.dialogRef.close(this.item);
  }

}
