import { Guid } from './../../../shared/guid';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { Forecast } from 'src/app/domain/forecast';
import { Subscription } from 'rxjs';
import { Weather } from "src/app/domain/weather";
declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss']
})

export class ForecastComponent implements OnInit, OnDestroy {
  @Input() forecast: Forecast;
  @Input() index: number;
  containers = ['container0', 'container1', 'container2']

  //     color: #7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1";
  colors = ['#fff700', '#5DADEC', '#FF7A00', '#A7F432', '#FFAE42', '#FF3855', '#E936A7'];
  // yellow, blue, dark orange, green, bright orange, red, purple all bright colors

  public options: any = {
    chart: {
      type: 'spline',
      height: 320,
      backgroundColor: 'rgba(66,66,66,1.0)',
      style: { fontFamily: 'Roboto', color: '#fff' },
      // backgroundColor: '#212121',
    },
    title: { style: { color: '#fff', fontSize: '20px', } },
    credits: { enabled: false },
    xAxis: {
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000,  // days
      // tickPosition: 'outside',
      // tickColor: 'darkblue',
      tickWidth: 3,
      tickLength: 20,
      gridLineWidth: 1,
      gridLineColor: 'grey',
      labels: {
        formatter() { return Highcharts.dateFormat('%A', this.value); },
        x: 80,
        step: 1,
        style: { color: '#fff', fontSize: '14px', }
      }
    },
    yAxis: [{ // temperature
      title: { text: null },
      labels: { format: '{value} °C', style: { color: '#fff', fontSize: '14px' } },
      top: '0%',
      height: '50%',
    }, { // wind
      title: { text: null },
      labels: { format: '{value} km/h', style: { color: '#fff', fontSize: '14px' } },
      opposite: true,
      top: '50%',
      height: '50%',
    }],
    legend: { enabled: true, itemStyle: { color: '#fff', fontSize: '14px', fontWeight: 'bold' } },
    series: [],
    tooltip: {
      xDateFormat: '%A %d. %B %H:%M',
      pointFormat: '  {series.name}: <b>{point.y}</b><br/>',
      valueDecimals: 0,
      style: { fontSize: '14px' },
      split: true
    }
  };
  chart: Highcharts.Chart;

  constructor(
    private translate: TranslateService,
    private router: Router) { }

  subscriptions = new Subscription();
  title: string;
  yLabel: string;

  ngOnInit() {
    this.subscriptions.add(this.translate.onLangChange.subscribe((event) => {
      // console.log('New language is: ', event);
      this.highchartLocalize();
      setTimeout(() => this.chart.redraw());
    }
    ));

    // console.log('Forecasts: ', this.forecasts);
    this.options.series = [];
    this.options.xAxis.plotBands = [];
    let colorIndex = 0;

    this.addDayLightPlotBands(this.forecast);

    const temperatureSeries = [];

    for (const timepoint of this.forecast.list) {
      const icon = 'url(./assets/images/' + timepoint.weather[0].icon + '.png)';
      temperatureSeries.push({ x: 1000 * timepoint.dt, y: timepoint.main.temp, marker: { symbol: icon } });
    }

    this.options.series.push({
      data: temperatureSeries,
      name: this.translate.instant('weather.forecast.chart.temperature'),
      color: this.colors[colorIndex],
      yAxis: 0,
      tooltip: { valueSuffix: ' °C' }
    });

    colorIndex++;
    const windSeries = [];

    for (const timepoint of this.forecast.list) { // icon only for the last data point
      const direction = this.deg2dir(timepoint.wind.deg);
      const icon = 'url(./assets/images/' + 'Compass' + '_' + direction + '.png)';
      windSeries.push({ x: 1000 * timepoint.dt, y: 3.6 * timepoint.wind.speed, marker: { symbol: icon } });
    }

    this.options.series.push({
      data: windSeries,
      name: this.translate.instant('weather.forecast.chart.windspeed'),
      color: this.colors[colorIndex],
      yAxis: 1,
      tooltip: { valueSuffix: ' km/h' }
    });

    // console.info('Index: ', this.index, this.forecast.city.name, this.containers[this.index]);
  }

  ngAfterViewInit(): void {
    this.chart = Highcharts.chart(this.containers[this.index], this.options);
    this.chart.setTitle({ text: this.forecast.city.name + ', ' + this.forecast.city.country });
    this.highchartLocalize();
    setTimeout(() => this.chart.reflow());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private highchartLocalize() {
    // this.chart.setTitle({ text: this.translate.instant('weather.forecast.chart.title') });

    this.chart.series[0].update({ name: this.translate.instant('weather.forecast.chart.temperature'), type: 'spline' }, false);
    this.chart.series[1].update({ name: this.translate.instant('weather.forecast.chart.windspeed'), type: 'spline' }, false);

    const weekdays = [];
    const months = [];
    const shortmonths = [];

    weekdays.push(this.translate.instant('common.chart.lang.weekdays.sun'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.mon'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.tue'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.wed'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.thu'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.fri'));
    weekdays.push(this.translate.instant('common.chart.lang.weekdays.sat'));
    months.push(this.translate.instant('common.chart.lang.months.january'));
    months.push(this.translate.instant('common.chart.lang.months.february'));
    months.push(this.translate.instant('common.chart.lang.months.march'));
    months.push(this.translate.instant('common.chart.lang.months.april'));
    months.push(this.translate.instant('common.chart.lang.months.may'));
    months.push(this.translate.instant('common.chart.lang.months.june'));
    months.push(this.translate.instant('common.chart.lang.months.july'));
    months.push(this.translate.instant('common.chart.lang.months.august'));
    months.push(this.translate.instant('common.chart.lang.months.september'));
    months.push(this.translate.instant('common.chart.lang.months.october'));
    months.push(this.translate.instant('common.chart.lang.months.november'));
    months.push(this.translate.instant('common.chart.lang.months.december'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.january'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.february'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.march'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.april'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.may'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.june'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.july'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.august'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.september'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.october'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.november'));
    shortmonths.push(this.translate.instant('common.chart.lang.shortmonths.december'));
    // console.log(weekdays);
    const printChart = this.translate.instant('common.chart.lang.printChart');
    const downloadPNG = this.translate.instant('common.chart.lang.downloadPNG');
    const downloadJPEG = this.translate.instant('common.chart.lang.downloadJPEG');
    const downloadPDF = this.translate.instant('common.chart.lang.downloadPDF');
    const downloadSVG = this.translate.instant('common.chart.lang.downloadSVG');
    const viewFullscreen = this.translate.instant('common.chart.lang.viewFullscreen');
    const thousandsSep = this.translate.instant('common.chart.lang.thousandsseparator');
    const decimalPoint = this.translate.instant('common.chart.lang.decimalpoint');
    const loading = this.translate.instant('common.chart.lang.loading');
    const rangeSelectorFrom = this.translate.instant('common.chart.lang.rangeSelectorFrom');
    const rangeSelectorTo = this.translate.instant('common.chart.lang.rangeSelectorTo');
    const rangeSelectorZoom = this.translate.instant('common.chart.lang.rangeSelectorZoom');
    const resetZoom = this.translate.instant('common.chart.lang.resetZoom');
    const resetZoomTitle = this.translate.instant('common.chart.lang.resetZoomTitle');

    Highcharts.setOptions({
      lang: {
        months: months,
        shortMonths: shortmonths,
        loading: loading,
        rangeSelectorFrom: rangeSelectorFrom,
        rangeSelectorTo: rangeSelectorTo,
        rangeSelectorZoom: rangeSelectorZoom,
        printChart,
        weekdays,
        downloadPNG,
        downloadJPEG,
        downloadPDF,
        downloadSVG,
        viewFullscreen,
        resetZoom: resetZoom,
        resetZoomTitle: resetZoomTitle,
        thousandsSep: thousandsSep,
        decimalPoint: decimalPoint
      }
    });
  }

  private addDayLightPlotBands(forecast: Forecast) {
    const oneDay = 24 * 3600 * 1000;

    const firstDate = new Date(1000 * forecast.list[0].dt);
    let foreCastDate = firstDate;
    const lastDate = new Date(1000 * forecast.list[forecast.list.length - 1].dt);
    const days = Math.round((lastDate.getTime() - firstDate.getTime()) / oneDay);
    // console.log('Days: ', days);
    const sunrise = new Date(1000 * forecast.city.sunrise);
    const sunset = new Date(1000 * forecast.city.sunset);

    for (let index = 0; index <= days; index++) {
      const dateSunrise = new Date(foreCastDate.getFullYear(), foreCastDate.getMonth(), foreCastDate.getDate(),
        sunrise.getHours(), sunrise.getMinutes());
      const dateSunset = new Date(foreCastDate.getFullYear(), foreCastDate.getMonth(), foreCastDate.getDate(),
        sunset.getHours(), sunset.getMinutes());

      // mark the daylight:
      this.options.xAxis.plotBands.push({
        color: '#FCFFC5',
        from: dateSunrise.getTime(),
        to: dateSunset.getTime()
      });
      foreCastDate = new Date(foreCastDate.getTime() + oneDay);
    }

  }

  deg2dir(deg) {
    // A direction of 0 degrees is due North on a compass,
    // and 180 degrees is due South.
    // A direction of 270 degrees would indicate a wind blowing in from the west.
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
    return directions[Math.trunc((deg + 22.5) / 360 * 8)];
  }
}
