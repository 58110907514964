import { EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[dsDnd]'
})
export class DsDndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver=true;
    // console.log('DnD Directive: Drag over',' FileOver: ', this.fileOver);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver=false;
    // console.log('DnD Directive: Drag leave',' FileOver: ', this.fileOver);
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver=false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      // console.log(`You dropped ${files.length}`);
      this.fileDropped.emit(files);
    }
  }

}
