import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'continue-dialog',
  templateUrl: './continue-dialog.component.html',
  styleUrls: ['./continue-dialog.component.scss']
})
export class ContinueDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ContinueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  proceed(): void {
    this.dialogRef.close(true);
  }
}

