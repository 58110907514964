<div class="mat-elevation-z8 container">
    <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="company">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.company' | translate }} </mat-header-cell>
            <mat-cell  *matCellDef="let element"> {{element.company}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="symbol">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.symbol' | translate }} </mat-header-cell>
            <mat-cell  *matCellDef="let element"> {{element.symbol}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="units">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.units' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.units}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="totalprice">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.totalprice' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.priceInBaseCcy | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalPrice | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="taxes">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.taxes' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.taxes | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalTaxes | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="commission">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.commission' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.commission | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalCommission | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalcost">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.cost' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.totalCost | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalCosts | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="currentprice">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.currentprice' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastPrice | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="lastRefreshed">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.lastrefreshed' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastRefreshed | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalValue">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.value' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.totalValue | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalValue | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="dividends">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.dividends' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.totalDividends | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalDividends | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="interests">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.interests' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.totalInterests | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalInterests | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="gainLoss">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.gainloss' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.gainLoss | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalGainLoss | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="gainLossPercent">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.gainlosspercent' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.gainLoss/element.totalValue | percent:"1.2-2"}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{totalGainLoss/totalValue | percent:"1.2-2"}}  </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="trend">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.trend' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon class="rotate-45" *ngIf="element.gainLoss > 0">arrow_forward</mat-icon>
                <mat-icon class="rotate45" *ngIf="element.gainLoss < 0">arrow_forward</mat-icon>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <mat-icon class="rotate-45" *ngIf="totalGainLoss > 0">arrow_forward</mat-icon>
                <mat-icon class="rotate45" *ngIf="totalGainLoss < 0">arrow_forward</mat-icon>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'highlight': selectedRows.includes(row.symbol)}" (click)="selectRow(row)"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns" ></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>