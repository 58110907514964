import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Security } from 'src/app/domain/security';
import { SecurityType } from 'src/app/domain/securitytypes';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfigStoreService, Config } from 'src/app/shared/services/config-store.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'security-dialog',
  templateUrl: './security-dialog.component.html',
  styleUrls: ['./security-dialog.component.scss']
})
export class SecurityDialogComponent{

  config$: Observable<Config> = this.configStore.config$;

  header: string;
  security: Security;
  baseCcy: string;
  yesNoDialogRef: any;
  enableDeleteButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<SecurityDialogComponent>,
    private dialog: MatDialog,
    private configStore: ConfigStoreService,
    @Inject(MAT_DIALOG_DATA) public data: TransactionSecurity
  ) {
    this.security = data;
    if (this.security.symbol) {
      this.enableDeleteButton = true;
    } else {
      this.enableDeleteButton = false;
    }
    this.baseCcy = this.configStore.config.baseCcy; // default: base currency
    if (!this.security.currency) this.security.currency = this.baseCcy;
    // console.log(this.security);
  }

  deleteSecurity() {
    this.yesNoDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '20vw',
      data: { title: 'common.dialog.confirm.delete.title', message: 'common.dialog.confirm.delete.message' }
    });

    this.yesNoDialogRef.afterClosed().subscribe(remove => {
      if (remove) this.dialogRef.close('delete');
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  proceed(): void {
    this.security.securityType = SecurityType.STOCK;
    // console.log('Date: ', new Date(this.transaction.transactionDate));
    this.dialogRef.close(this.security);
  }
}

