<mat-toolbar>
  <mat-toolbar-row class="flex-row gap-0">
    <button mat-icon-button [ngClass]="{'highlight': currentlang === 'fr'}" class="lang-icon"
      (click)="language('fr')">🇫🇷</button>
    <button mat-icon-button [ngClass]="{'highlight': ((currentlang === 'de-ch') || (currentlang === 'de'))}"
      class="lang-icon" (click)="language('de-ch')">🇩🇪</button>
    <button mat-icon-button [ngClass]="{'highlight': currentlang === 'en'}" class="lang-icon"
      (click)="language('en')">🇬🇧</button>

    <ng-container *ngIf="(componentInfo$ | async).options.length > 0">
      <button mat-button [matMenuTriggerFor]="optionsMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #optionsMenu="matMenu">
        <button *ngFor="let optionsMenuItem of (componentInfo$ | async).options" mat-menu-item color="basic"
          (click)="execute(optionsMenuItem.label)">
          <mat-icon>{{optionsMenuItem.icon}}</mat-icon>
          <span>{{ optionsMenuItem.label | translate }}</span>
        </button>
      </mat-menu>
    </ng-container>

    <span class="spacer"></span>

    <div [ngClass]="(componentInfo$ | async).showFilter?'filter':'hide'">
      <form class="form">
        <mat-form-field class="full-width">
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label>{{'topmenu.filter.search' | translate}}</mat-label>
          <input matInput [(ngModel)]="searchInput" type="text"  name="searchInput" id="searchInput"
            (ngModelChange)="this.searchInputUpdate.next($event)" autocomplete="off"
            placeholder="{{'topmenu.filter.hint' | translate}}">
          <!-- <mat-hint align="start">{{'topmenu.filter.hint' | translate}}</mat-hint> -->
          <button matTooltip="{{'topmenu.filter.clear' | translate}}" matTooltipPosition="left" matSuffix
            mat-icon-button (click)="clearSearchInput()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>

    <ng-container *ngIf="((componentInfo$ | async).canUpdate) && (componentInfo$ | async).form">
      <button [disabled]="(btnStat$ | async).disMod" mat-button color="basic" (click)="editEntry(MODIFY)">
        <mat-icon matTooltip="{{'topbar.form.button.edit.tooltip' | translate }}">edit</mat-icon>
      </button>

      <button [disabled]="(btnStat$ | async).disSave" mat-button color="basic" (click)="editEntry(SAVE)">
        <mat-icon matTooltip="{{'topbar.form.button.save.tooltip' | translate }}">save</mat-icon>
      </button>

      <button [disabled]="(btnStat$ | async).disCan" mat-button color="basic" (click)="editEntry(UNDO)">
        <mat-icon matTooltip="{{'topbar.form.button.addsubscription.cancel' | translate }}">undo</mat-icon>
      </button>

      <ng-container *ngIf="(componentInfo$ | async).canDelete">
        <button [disabled]=" (btnStat$ | async).disDel" mat-button color="basic" (click)="editEntry(DELETE)">
          <mat-icon matTooltip="{{'topbar.form.button.delete.tooltip' | translate }}">delete</mat-icon>
        </button>
      </ng-container>

    </ng-container> <!-- *ngIf="form and user having rights" -->

    <span class="spacer"></span>

    <ng-container *ngIf="!(appUser$ | async)">
      <button mat-button color="basic" (click)="routeTo('login')">
        <mat-icon class="icon">account_box</mat-icon>
        <span> {{'topmenu.login' | translate}} </span>
      </button>
    </ng-container>

    <ng-container *ngIf="(appUser$ | async)">
      <button mat-button [matMenuTriggerFor]="menu">
        <span> {{ (appUser$ | async)?.name }} </span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="about()">
          <mat-icon>copyright</mat-icon>
          <span>{{'topmenu.config.about' | translate}}</span>
        </button>
        <button *ifHasRight="'canRead' in 'admin'" mat-menu-item [matMenuTriggerFor]="configMenu">
          <mat-icon>build</mat-icon>
          <span>{{'topmenu.config.menu' | translate}}</span>
        </button>
      </mat-menu>

      <mat-menu #configMenu="matMenu">
        <button *ifHasRight="'canRead' in 'permissions'" mat-menu-item (click)="routeTo('/role-admin')">
          <mat-icon>security</mat-icon>
          <span>{{'topmenu.config.roles' | translate}}</span>
        </button>

        <button *ifHasRight="'canRead' in 'users'" mat-menu-item (click)="routeTo('/user-roles')">
          <mat-icon>share</mat-icon>
          <span>{{'topmenu.config.users' | translate}}</span>
        </button>

        <button *ifHasRight="'canRead' in 'config'" mat-menu-item (click)="routeTo('/settings')">
          <mat-icon>settings</mat-icon>
          <span>{{'topmenu.config.settings' | translate}}</span>
        </button>

        <button *ifHasRight="'canUpdate' in 'admin'" mat-menu-item (click)="routeTo('/migrate')">
          <mat-icon>upgrade</mat-icon>
          <span>{{ 'mainmenu.config.migrate' | translate }}</span>
        </button>

      </mat-menu>

      <button (click)="logout()" mat-button matTooltip="{{'topmenu.logout' | translate}}" [matTooltipShowDelay]="1000"
        matTooltipPosition="below">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </ng-container> <!-- *ngIf="(appUser$ | async)" -->

  </mat-toolbar-row>
</mat-toolbar>