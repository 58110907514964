import { TranslateService } from '@ngx-translate/core';
import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from "moment";
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { FamilyMember } from 'src/app/domain/familymember';
import { FamilyMemberContext } from 'src/app/domain/familymembercontext';
import { FamilyStoreService } from 'src/app/family/services/family-store.service';
import { EditAction, EditState, MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ContinueDialogComponent } from "src/app/shared/components/continue-dialog/continue-dialog.component";
import { ImagePopupDialogComponent } from 'src/app/shared/components/image-popup-dialog/image-popup-dialog.component';
import { MessageDialogComponent } from "src/app/shared/components/message-dialog/message-dialog.component";
import { Guid } from 'src/app/shared/guid';
import { Config, ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PersonDialogComponent } from '../../person-dialog/person-dialog.component';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mform',
  templateUrl: './mform.component.html',
  styleUrls: ['./mform.component.scss']
})

export class MformComponent implements OnInit, OnDestroy, AfterViewInit {

  config$: Observable<Config> = this.configStore.config$;

  imageDialogRef: MatDialogRef<ImagePopupDialogComponent>;
  messageDialogRef: MatDialogRef<MessageDialogComponent>;
  continueDialogRef: MatDialogRef<ContinueDialogComponent>;

  tableName = 'family';
  isNewMember: boolean;
  currentMember: FamilyMemberContext;
  key: string;

  subscriptions = new Subscription();
  dateOfDeath: Date;
  dateOfBirth: Date;
  personDialogRef: MatDialogRef<PersonDialogComponent, any>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent, any>;
  editAction$: Observable<EditAction> = this.menuStore.editAction$;
  currentMember$: Observable<FamilyMemberContext> = this.familyStore.currentMemberContext$;
  familyMemberForm: FormGroup;

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog,
    private dataService: DataService,
    private menuStore: MenuStoreService,
    private familyStore: FamilyStoreService,
    private configStore: ConfigStoreService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.currentMember$.subscribe((currentMember: FamilyMemberContext) => {
      if (currentMember.self) {
        this.initFormGroup(currentMember);
        this.familyMemberForm.disable();

        this.currentMember = currentMember;
        // console.info('Current member: ', this.currentMember);
      } else {
        this.router.navigate(['family-list']);
      }
    }));

    if (this.authorizationService.hasRights(this.tableName, 'canUpdate')) {
      this.subscriptions.add(this.editAction$.subscribe(action => {
        // console.info('Edit action: ', action);
        if (action === EditAction.DELETE) { this.deleteSelf(); }
        if (action === EditAction.UNDO) { this.undo(); }
        if (action === EditAction.SAVE) { this.saveMember(); }
        if (action === EditAction.MODIFY) { this.modify(); }
      }));
    }
  }

  ngAfterViewInit() {

    const options: { label: string, icon: string }[] = [];

    this.menuStore.componentInfo = {
      name: this.tableName,
      form: true,
      options: options,
      showFilter: false
    };

    this.menuStore.editState = EditState.READONLY;

  }

  ngOnDestroy(): void {
    this.familyStore.currentMemberContext = { self: null, father: null, mother: null, partner: null, children: [] }
    this.subscriptions.unsubscribe();
  }

  private initFormGroup(currentMember: FamilyMemberContext) {
    this.familyMemberForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      sex: new FormControl(null, Validators.required),
      function: new FormControl(null),
      dateOfBirth: new FormControl(null),
      dateOfDeath: new FormControl(null),
      email: new FormControl(null),
      telephoneFix: new FormControl(null),
      telephoneMobile: new FormControl(null),
      addrLine1: new FormControl(null),
      addrLine2: new FormControl(null),
      cp: new FormControl(null),
      city: new FormControl(null),
      country: new FormControl(null),
      part: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(4032)]),
      partnerFirstName: new FormControl(null),
      partnerLastName: new FormControl(null),
      fatherFirstName: new FormControl(null),
      motherFirstName: new FormControl(null),
      generation: new FormControl(null)
    });

    if (currentMember.self.firstName) this.familyMemberForm.get('firstName').setValue(currentMember.self.firstName);
    if (currentMember.self.lastName) this.familyMemberForm.get('lastName').setValue(currentMember.self.lastName);
    if (currentMember.self.sex) this.familyMemberForm.get('sex').setValue(currentMember.self.sex);
    if (currentMember.self.function) this.familyMemberForm.get('function').setValue(currentMember.self.function);
    if (currentMember.self.dateOfBirth) this.familyMemberForm.get('dateOfBirth').setValue(moment(currentMember.self.dateOfBirth));
    if (currentMember.self.dateOfDeath) this.familyMemberForm.get('dateOfDeath').setValue(moment(currentMember.self.dateOfDeath));
    if (currentMember.self.email) this.familyMemberForm.get('email').setValue(currentMember.self.email);
    if (currentMember.self.telephoneFix) this.familyMemberForm.get('telephoneFix').setValue(currentMember.self.telephoneFix);
    if (currentMember.self.telephoneMobile) this.familyMemberForm.get('telephoneMobile').setValue(currentMember.self.telephoneMobile);
    if (currentMember.self.addrLine1) this.familyMemberForm.get('addrLine1').setValue(currentMember.self.addrLine1);
    if (currentMember.self.addrLine2) this.familyMemberForm.get('addrLine2').setValue(currentMember.self.addrLine2);
    if (currentMember.self.cp) this.familyMemberForm.get('cp').setValue(currentMember.self.cp);
    if (currentMember.self.city) this.familyMemberForm.get('city').setValue(currentMember.self.city);
    if (currentMember.self.country) this.familyMemberForm.get('country').setValue(currentMember.self.country);
    if (currentMember.self.part) this.familyMemberForm.get('part').setValue(currentMember.self.part);
    if (currentMember.self.generation) this.familyMemberForm.get('generation').setValue(currentMember.self.generation);
    if (currentMember.partner?.firstName) this.familyMemberForm.get('partnerFirstName').setValue(currentMember.partner.firstName);
    if (currentMember.partner?.lastName) this.familyMemberForm.get('partnerLastName').setValue(currentMember.partner.lastName);
    if (currentMember.father?.firstName) this.familyMemberForm.get('fatherFirstName').setValue(currentMember.father.firstName);
    if (currentMember.mother?.firstName) this.familyMemberForm.get('motherFirstName').setValue(currentMember.mother.firstName);

    this.familyMemberForm.valueChanges.subscribe(result => {
      if (!this.familyMemberForm.pristine) {
        const newState = this.familyMemberForm.valid ? EditState.VALID : EditState.INVALID;
        if (newState !== this.menuStore.editState) this.menuStore.editState = newState;
      }
    });
  }

  private modify() {
    this.menuStore.editState = EditState.UNTOUCHED;
    this.familyMemberForm.enable();
    this.familyMemberForm.get('partnerFirstName').disable();
    this.familyMemberForm.get('partnerLastName').disable();
    this.familyMemberForm.get('fatherFirstName').disable();
    this.familyMemberForm.get('motherFirstName').disable();
  }

  private undo() {
    console.trace('Edit state: ', this.menuStore.editState);
    if (this.menuStore.editState === EditState.READONLY) this.router.navigate(['family-list']);

    this.menuStore.editState = EditState.READONLY;
    this.initFormGroup(this.currentMember);

  }

  private saveMember() {
    const self: FamilyMember = JSON.parse(JSON.stringify(this.currentMember.self));

    self.firstName = this.familyMemberForm.get('firstName').value;
    self.lastName = this.familyMemberForm.get('lastName').value;
    self.sex = this.familyMemberForm.get('sex').value;
    self.part = +this.familyMemberForm.get('part').value.valueOf();

    if (this.familyMemberForm.get('function').value) self.function = this.familyMemberForm.get('function').value;
    if (this.familyMemberForm.get('dateOfBirth').value) self.dateOfBirth = this.familyMemberForm.get('dateOfBirth').value.valueOf();
    if (this.familyMemberForm.get('dateOfDeath').value) self.dateOfDeath = this.familyMemberForm.get('dateOfDeath').value.valueOf();
    if (this.familyMemberForm.get('email').value) self.email = this.familyMemberForm.get('email').value;
    if (this.familyMemberForm.get('telephoneFix').value) self.telephoneFix = this.familyMemberForm.get('telephoneFix').value;
    if (this.familyMemberForm.get('telephoneMobile').value) self.telephoneMobile = this.familyMemberForm.get('telephoneMobile').value;
    if (this.familyMemberForm.get('addrLine1').value) self.addrLine1 = this.familyMemberForm.get('addrLine1').value;
    if (this.familyMemberForm.get('addrLine2').value) self.addrLine2 = this.familyMemberForm.get('addrLine2').value;
    if (this.familyMemberForm.get('cp').value) self.cp = this.familyMemberForm.get('cp').value;
    if (this.familyMemberForm.get('city').value) self.city = this.familyMemberForm.get('city').value;
    if (this.familyMemberForm.get('country').value) self.country = this.familyMemberForm.get('country').value;
    if (this.familyMemberForm.get('generation').value) self.generation = this.familyMemberForm.get('generation').value;

    // console.info('Part --> updated value: ', this.familyMemberForm.get('part').value);
    // console.info('Save --> updated self values: ', self);

    this.currentMember.self = JSON.parse(JSON.stringify(self));
    // console.info('Save --> updated currentMember values: ', this.currentMember);
    this.isNewMember ? this.dataService.create(this.tableName, this.currentMember.self) : this.dataService.update(this.tableName, this.currentMember.self);
    this.familyStore.currentMemberContext = this.currentMember;
    this.menuStore.editState = EditState.READONLY;
  }

  private deleteSelf() {
    if (this.currentMember.children.length > 0) {
      this.messageDialogRef = this.dialog.open(MessageDialogComponent, {
        width: '35vw',
        data: { title: 'common.dialog.message.title', message: 'common.dialog.message.haschildren' }
      });

      this.messageDialogRef.afterClosed().subscribe(() => {
        return;
      });
    } else {  // no children
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35vw',
        // enterAnimationDuration: 300,
        // exitAnimationDuration: 300,
        data: {
          title: 'common.dialog.confirm.delete.title',
          message: 'common.dialog.confirm.delete.message'
        }
      });

      this.confirmDialogRef.afterClosed().subscribe(remove => {
        if (!remove) return;

        delete this.currentMember.partner.partnerId;
        // console.log('this.currentMember.partner: ', this.currentMember.partner);
        // set and NOT update, as the deleted items would re-appear!
        this.dataService.updateUserStampModified(this.currentMember.partner);
        this.dataService.set(this.tableName, this.currentMember.partner, this.currentMember.partner.key);
        this.dataService.delete(this.tableName, this.currentMember.self);

        this.router.navigate(['family-list']);
      });
    }
  }

  zoom(entry: FamilyMember) {
    // console.log('Zoom: ', entry)
    if (entry.key) this.familyStore.setCurrentRecord(entry);
  }

  addChild() {
    if (!this.currentMember.partner) {
      this.continueDialogRef = this.dialog.open(ContinueDialogComponent, {
        width: '35vw',
        data: { title: 'common.dialog.message.title', message: 'common.dialog.message.missingpartner' }
      });

      this.continueDialogRef.afterClosed().subscribe((proceed) => {
        if (!proceed) return;
        this.createChild();
      });
    } else {
      this.createChild();
    }
  }

  private createChild() {
    const newChild: FamilyMember = { key: Guid.newGuid() };
    newChild.firstName = '';
    newChild.lastName = this.currentMember.self.lastName;
    if (this.currentMember.self.sex === 'M') {
      newChild.fatherId = this.currentMember.self.key;
      if (this.currentMember.partner) newChild.motherId = this.currentMember.partner.key;
    } else {
      if (this.currentMember.self.partnerId) newChild.fatherId = this.currentMember.self.partnerId;
      newChild.motherId = this.currentMember.self.key;
    }

    this.personDialogRef = this.dialog.open(PersonDialogComponent, {
      width: '500px',
      data: { header: 'family.form.menu.add.child', member: newChild }
    });

    this.personDialogRef.afterClosed().subscribe(newChildEnriched => {
      if (!newChildEnriched) { return; }

      if (!this.currentMember.self.childrenIds) this.currentMember.self.childrenIds = [];
      this.currentMember.self.childrenIds.push(newChildEnriched.key);
      this.dataService.updateUserStampModified(this.currentMember.self);

      if (this.currentMember.partner) {
        if (!this.currentMember.partner.childrenIds) this.currentMember.partner.childrenIds = [];
        this.currentMember.partner.childrenIds.push(newChildEnriched.key);
        this.dataService.updateUserStampModified(this.currentMember.partner);
      }

      this.dataService.updateUserStampCreated(newChildEnriched);
      const updates = {};
      updates[newChildEnriched.key] = newChildEnriched;
      updates[this.currentMember.self.key] = this.currentMember.self;
      if (this.currentMember.partner) updates[this.currentMember.partner.key] = this.currentMember.partner;
      this.dataService.multiUpdate(this.tableName, updates);

    });
  }

  addPartner() {
    const addedPartner: FamilyMember = { key: Guid.newGuid() };
    addedPartner.firstName = '';
    addedPartner.lastName = this.currentMember.self.lastName;
    addedPartner.partnerId = this.currentMember.self.key;
    addedPartner.sex = (this.currentMember.self.sex === 'M') ? 'F' : 'M';

    this.personDialogRef = this.dialog.open(PersonDialogComponent, {
      width: '500px',
      data: { header: 'family.form.menu.add.partner', member: addedPartner }
    });

    this.personDialogRef.afterClosed().subscribe((updatedAddedPartner: FamilyMember) => {
      if (!updatedAddedPartner) { return; }

      if (this.currentMember.self.childrenIds) updatedAddedPartner.childrenIds = this.currentMember.self.childrenIds;
      if (this.currentMember.self.telephoneFix) updatedAddedPartner.telephoneFix = this.currentMember.self.telephoneFix;
      if (this.currentMember.self.addrLine1) updatedAddedPartner.addrLine1 = this.currentMember.self.addrLine1;
      if (this.currentMember.self.addrLine2) updatedAddedPartner.addrLine2 = this.currentMember.self.addrLine2;
      if (this.currentMember.self.cp) updatedAddedPartner.cp = this.currentMember.self.cp;
      if (this.currentMember.self.city) updatedAddedPartner.city = this.currentMember.self.city;
      if (this.currentMember.self.country) updatedAddedPartner.country = this.currentMember.self.country;

      this.currentMember.self.partnerId = updatedAddedPartner.key;
      // this.currentMember.partner = updateAddedPartner;

      // console.log('Added partner: ', updateAddedPartner);
      this.dataService.updateUserStampCreated(updatedAddedPartner);
      this.dataService.updateUserStampModified(this.currentMember.self);
      const updates = {};
      updates[updatedAddedPartner.key] = updatedAddedPartner;
      updates[this.currentMember.self.key] = this.currentMember.self;
      this.dataService.multiUpdate(this.tableName, updates);
    });
  }

  addMother() {
    const mother: FamilyMember = { key: Guid.newGuid() };
    mother.firstName = '';
    mother.childrenIds = [this.currentMember.self.key];
    mother.lastName = this.currentMember.self.lastName;
    mother.sex = 'F';

    this.personDialogRef = this.dialog.open(PersonDialogComponent, {
      width: '500px',
      data: { header: 'family.form.menu.add.mother', member: mother }
    });

    this.personDialogRef.afterClosed().subscribe(motherEnriched => {
      if (!motherEnriched) { return; }

      this.currentMember.self.motherId = motherEnriched.key;
      this.dataService.updateUserStampCreated(motherEnriched);
      this.dataService.updateUserStampModified(this.currentMember.self);
      const updates = {};
      updates[motherEnriched.key] = motherEnriched;
      updates[this.currentMember.self.key] = this.currentMember.self;
      this.dataService.multiUpdate(this.tableName, updates);
    });
  }

  addFather() {
    const father: FamilyMember = { key: Guid.newGuid() };
    father.firstName = '';
    father.childrenIds = [this.currentMember.self.key];
    father.lastName = this.currentMember.self.lastName;
    father.sex = 'M';

    this.personDialogRef = this.dialog.open(PersonDialogComponent, {
      width: '500px',
      data: { header: 'family.form.menu.add.father', member: father }
    });

    this.personDialogRef.afterClosed().subscribe(fatherEnriched => {
      if (!fatherEnriched) { return; }

      this.currentMember.self.fatherId = fatherEnriched.key;
      this.dataService.updateUserStampCreated(fatherEnriched);
      this.dataService.updateUserStampModified(this.currentMember.self);
      const updates = {};
      updates[fatherEnriched.key] = fatherEnriched;
      updates[this.currentMember.self.key] = this.currentMember.self;
      this.dataService.multiUpdate(this.tableName, updates);
    });
  }

  getDateFormat() {
    const locale = this.translate.currentLang;
    return getLocaleDateFormat(locale, FormatWidth.Short);
  }
}
