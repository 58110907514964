<mat-card class="about">
    <mat-card-header>
        <div mat-card-avatar class="header-image"></div>
        <mat-card-title>{{data.name}}</mat-card-title>
        <mat-card-subtitle>{{data.version}} - {{data.date}}</mat-card-subtitle>
        <mat-card-subtitle>{{data.author}}</mat-card-subtitle>
        <mat-card-subtitle>{{data.database}}</mat-card-subtitle>
        <mat-card-subtitle>{{data.storage}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p> Application to manage the family tree of the Suter family </p>
        <p> Additional options:</p>
        <ul>
            <li>See local weather and 5 days forecast</li>
            <li>Chart of 2 years of exchange rate USD/GBP/EUR to CHF</li>
            <li>Family tree</li>
            <li>Audit trail</li>
        </ul>
    </mat-card-content>
    <mat-card-actions>
        <span class="spacer"></span>
        <button mat-raised-button (click)="close()" cdkFocusInitial>
            <mat-icon>close</mat-icon>
            <span>{{'common.button.close' | translate}}</span>
        </button>
    </mat-card-actions>
</mat-card>