import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { AuthorizationService } from './../../../admin/services/authorization.service';
import { ManageTokenService } from './../../services/manage-token.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['./home-overview.component.scss']
})
export class HomeOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('video') video: any;

  tableName = 'home';
  projectId = '8922ffb6-c7af-4fbe-bd3c-dd8b7e8f4217';
  thermostats = [
    {
      id: 'AVPHwEuFOwz76-CB_4rFSaDpM8rzrCKKEP8wnOje4qg_ORzx4ZOKvGNya3TUDpKm9kl3URPFIm4BykeL_DPhFINxBFZywA',
      name: '',
      temperature: 0,
      humidity: 0,
      gaugeNumber: 0
    },
    {
      id: 'AVPHwEuSa84aEjJuO-vNFm6drcD701My-5WcpSrYa4wCc5ysQYqRyhgm0XL8LwrWPKki3spOyI1oli6XwndHHKZ1M3aSrg',
      name: '',
      temperature: 0,
      humidity: 0,
      gaugeNumber: 0
    }
  ];
  cameras = [
    {
      id: 'AVPHwEv_eq7emU2yQztb72m9WaDByD3ImSJHBmBKfJtEdlwwAtJkL1NjRtnLuBZVRyiVN8vtJ0Rwbs0o_oZX8TS8os90vg',
      name: '',
      stream: ''
    },
  ];
  temp1: number;
  temp2: number;
  accessToken: string;
  expiresIn: number;
  subscriptions = new Subscription();

  constructor(
    private authorizationService: AuthorizationService,
    private tokenService: ManageTokenService,
    private menuStore: MenuStoreService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  ngAfterViewInit() {
    const options: { label: string, icon: string }[] = [];

    this.menuStore.componentInfo = {
      canUpdate: this.authorizationService.hasRights(this.tableName, 'canUpdate'),
      canCreate: this.authorizationService.hasRights(this.tableName, 'canCreate'),
      canDelete: this.authorizationService.hasRights(this.tableName, 'canDelete'),
      name: this.tableName,
      form: false,
      options,
      showFilter: false
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  // Kill subject to stop all requests for component

  private refresh() {
    this.subscriptions.add(timer(0, this.expiresIn)
      .subscribe(() => this.refreshToken()));

  }

  private refreshToken(): void {
    this.subscriptions.add(this.tokenService.getRefreshedAccessToken()
      .subscribe((response: { access_token: string, expires_in: number }) => {
        this.accessToken = response.access_token;
        this.expiresIn = response.expires_in * 1000;
        // console.log(response);
        // console.log(this.accessToken);
        this.getTemperatures();
        this.getCameraStreams();
      }));
  }
  getCameraStreams() {
       // tslint:disable-next-line: prefer-for-of
       for (let index = 0; index < this.cameras.length; index++) {
        this.subscriptions.add(this.tokenService.getCameraStream(this.accessToken, this.projectId, this.cameras[index].id)
          .subscribe((response: any) => {
            if (response) {
              // console.log('Response: ', response);
              // console.log('traits: ', response.traits['sdm.devices.traits.Temperature'].ambientTemperatureCelsius);
            }
          }));
      }
  }

  private getTemperatures() {

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.thermostats.length; index++) {
      this.subscriptions.add(this.tokenService.getTemperature(this.accessToken, this.projectId, this.thermostats[index].id)
        .subscribe((response: any) => {
          if (response) {
            // console.log('Response: ', response);
            // console.log('traits: ', response.traits['sdm.devices.traits.Temperature'].ambientTemperatureCelsius);
            this.thermostats[index].name = response.traits['sdm.devices.traits.Info'].customName;
            this.thermostats[index].temperature = response.traits['sdm.devices.traits.Temperature'].ambientTemperatureCelsius;
            this.thermostats[index].humidity = response.traits['sdm.devices.traits.Humidity'].ambientHumidityPercent;
            this.thermostats[index].gaugeNumber = index;
            // console.log('Thermostat: ', this.thermostats[index]);
          }
        }));
    }
  }
}
