import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  apiKey: string = environment.alphavantage.apiKey;
  apiUrl: string = environment.alphavantage.apiUrl;

  constructor(private http: HttpClient) { }

  // https://query1.finance.yahoo.com/v7/finance/quote?symbols=amz.de


  // https://www.alphavantage.co/documentation/
  // https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&apikey=demo&outputsize=compact&symbol=IBM
  // outputsize=compact oder full
  // TIME_SERIES_WEEKLY_ADJUSTED

  // {
  //   "Meta Data": {
  //       "1. Information": "Daily Prices (open, high, low, close) and Volumes",
  //       "2. Symbol": "IBM",
  //       "3. Last Refreshed": "2020-05-13",
  //       "4. Output Size": "Compact",
  //       "5. Time Zone": "US/Eastern"
  //   },
  //   "Time Series (Daily)": {
  //       "2020-05-13": {
  //           "1. open": "119.9500",
  //           "2. high": "119.9900",
  //           "3. low": "114.8500",
  //           "4. close": "115.7300",
  //           "5. volume": "5850517"
  //       },

  // https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=BA&apikey=demo
  // {
  //   "bestMatches": [
  //       {
  //           "1. symbol": "BA",
  //           "2. name": "The Boeing Company",
  //           "3. type": "Equity",
  //           "4. region": "United States",
  //           "5. marketOpen": "09:30",
  //           "6. marketClose": "16:00",
  //           "7. timezone": "UTC-05",
  //           "8. currency": "USD",
  //           "9. matchScore": "1.0000"
  //       },


  public getTimeSeriesCompact(symbol: string) {
    const url = this.apiUrl + '&apikey=' + this.apiKey + '&outputsize=compact&symbol=' + symbol;
    console.log(url);
    return this.http.get(url)
      .pipe(map((series: {}[]) => {
        console.log('Series: ', series);
        const metaData = series['Meta Data'];
        // console.log('Metadata: ', metaData);

        if (!metaData) { return {}; }

        const tickerSymbol: string = metaData['2. Symbol'];
        const lastRefreshed = this.convertDate(metaData['3. Last Refreshed']);
        let lastPrice = 0;
        const dataPoints = series['Time Series (Daily)'];
        // console.log(dataPoints);
        // dataPoints = dataPoints.reverse();
        const timeSeries: {} = {};
        Object.keys(dataPoints).forEach(key => {
          timeSeries[key] = +dataPoints[key]['1. open'];
          // +timeSeries[key]['2. high'],
          // +timeSeries[key]['3. low'],
          // +timeSeries[key]['4. close']
          // +timeSeries[key]['5. volume']
          // timeSeries[key] = +dataPoints[key]['4. close'];
          if (lastPrice === 0) lastPrice = timeSeries[key];
        });
        // console.log(timeSeries);
        // timeSeries = timeSeries.sort((a, b) => a[0] - b[0]);
        return { tickerSymbol, lastRefreshed, lastPrice, timeSeries }
      }))
  }

  public getTimeSeriesFull(symbol: string) {
    const url = this.apiUrl + '&apikey=' + this.apiKey + '&outputsize=full&symbol=' + symbol;
    return this.http.get(url);
  }

  private convertDate(key: string): number {
    // console.log(+key.substr(0,4),+key.substr(5,2)-1,+key.substr(8,2))
    const date = new Date(+key.substr(0, 4), +key.substr(5, 2) - 1, +key.substr(8, 2));
    return date.getTime();
  }
}