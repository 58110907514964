import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { SharedModule } from '../shared/shared.module';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { ConfigDialogArrayComponent } from './components/config/config-dialog-array/config-dialog-array.component';
import { ConfigDialogKeyValueComponent } from "./components/config/config-dialog-key-value/config-dialog-key-value.component";
import { ConfigDialogModulesComponent } from './components/config/config-dialog-modules/config-dialog-modules.component';
import { ConfigDialogStringComponent } from './components/config/config-dialog-string/config-dialog-string/config-dialog-string.component';
import { ConfigComponent } from './components/config/config.component';
import { LoginComponent } from './components/login/login.component';
import { MigrateComponent } from "./components/migrate/migrate.component";
import { NoAccessComponent } from './components/no-access/no-access.component';
import { RolePermissionsComponent } from './components/role-permissions/role-permissions.component';
import { UserRolesComponent } from './components/user-roles/user-roles.component';
import { ConfigAuthGuardService } from './services/config-auth-guard.service';
import { ExportAuthGuardService } from './services/export-auth-guard.service';
import { ImportAuthGuardService } from './services/import-auth-guard.service';
import { RoleAdminAuthGuardService } from './services/role-admin-auth-guard.service';
import { UserAdminAuthGuardService } from './services/user-admin-auth-guard.service';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  // signInSuccessUrl: '<url-to-redirect-to-on-success>',
  autoUpgradeAnonymousUsers: true,
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      fullLabel: 'Login mit Email',
      requireDisplayName: true
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Login mit Google',
    }
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};
@NgModule({
  declarations: [
    LoginComponent,
    NoAccessComponent,
    RolePermissionsComponent,
    UserRolesComponent,
    ConfigComponent,
    ConfigDialogArrayComponent,
    ConfigDialogStringComponent,
    ConfigDialogModulesComponent,
    ConfigDialogKeyValueComponent,
    AboutDialogComponent,
    MigrateComponent
  ],
  providers: [
    ImportAuthGuardService,
    ExportAuthGuardService,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    RouterModule.forChild([
      { path: 'settings', component: ConfigComponent, canActivate: [ConfigAuthGuardService] },
      { path: 'login', component: LoginComponent },
      { path: 'no-access', component: NoAccessComponent },
      { path: 'role-admin', component: RolePermissionsComponent, canActivate: [RoleAdminAuthGuardService] },
      { path: 'user-roles', component: UserRolesComponent, canActivate: [UserAdminAuthGuardService] },
      { path: 'migrate', component: MigrateComponent, canActivate: [UserAdminAuthGuardService] },
    ])
  ],
})
export class AdminModule { }
