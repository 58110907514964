import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'config-dialog-array',
  templateUrl: './config-dialog-array.component.html',
  styleUrls: ['./config-dialog-array.component.scss']
})
export class ConfigDialogArrayComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  header: string;
  key: string;
  items: string[];
  dataSource;
  addToList: string;
  modifiedEntry: string;
  previousEntry: string;
  modifiedEntryIndex: number;
  editMode: boolean;
  isModified: boolean;
  displayedColumns = ['delete', 'modify', 'item', 'drag'];

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogArrayComponent>,
    @Inject(MAT_DIALOG_DATA) data: { header: string, key: string, items: string[] }) {
    this.header = data.header;
    this.key = data.key;
    this.items = data.items;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.items);
    this.editMode = false;
    this.isModified = false;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  remove(item) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
    this.isModified = true;
    this.dataSource.data = this.items;
  }

  add() {
    this.items.push(this.addToList);
    this.addToList = null;
    this.isModified = true;
    this.dataSource.data = this.items;
  }

  edit(item) {
    this.previousEntry = item;
    this.modifiedEntry = item;
    this.modifiedEntryIndex = this.items.indexOf(item);
    this.editMode = true;
  }

  saveEditChanges() {
    this.items.splice(this.modifiedEntryIndex, 1, this.modifiedEntry);
    this.modifiedEntry = null;
    this.previousEntry = null;
    this.editMode = false;
    this.isModified = true;
    this.dataSource.data = this.items;
  }

  onListDrop(event: CdkDragDrop<string[]>): void {
    // Swap the elements around
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.dataSource.data = this.items;
    this.isModified = true;
    // console.log('After dropping: ',this.dataSource.data);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  proceed(): void {
    this.dialogRef.close(this.items);
  }

}
