<h1 mat-dialog-title>{{header | translate}}</h1>

<mat-dialog-content>
  <mat-table cdkDropList (cdkDropListDropped)="onListDrop($event)" [cdkDropListData]="dataSource"
    [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef> {{'config.dialog.array.delete' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="remove(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="modify">
      <mat-header-cell *matHeaderCellDef> {{'config.dialog.array.modify' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- Columnname Column -->
    <ng-container matColumnDef="item">
      <mat-header-cell *matHeaderCellDef> {{key | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element}} </mat-cell>
    </ng-container>

    <!-- Drag handle-->
    <ng-container matColumnDef="drag">
      <mat-header-cell *matHeaderCellDef>{{ 'common.dialog.drag' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon>drag_handle</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
    <mat-row cdkDrag [cdkDragData]="row" *matRowDef="let row; columns: displayedColumns;"> </mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]"></mat-paginator>
</mat-dialog-content>

<div mat-dialog-actions>
  <mat-form-field class="input-form">
    <mat-label>{{ 'config.dialog.array.add' | translate }}</mat-label>
    <input [(ngModel)]="addToList" name="additem" matTooltipPosition="below"
      matTooltip="{{ 'config.dialog.array.addtooltip' | translate }}" matInput type="text" (keydown.enter)="add()"
      #newentry="ngModel">
    <button *ngIf="addToList" matSuffix mat-icon-button (click)="add()">
      <mat-icon>check</mat-icon>
    </button>
  </mat-form-field>

  <span class="spacer"></span>

  <mat-form-field class="input-form">
    <mat-label>{{ 'config.dialog.array.modify' | translate }}</mat-label>
    <input [(ngModel)]="modifiedEntry" name="modifieditem" matTooltipPosition="below"
      matTooltip="{{ 'config.dialog.array.modifytooltip' | translate }}" matInput type="text"
      (keydown.enter)="saveEditChanges()" [disabled]="!editMode" #modifiedentry="ngModel">
    <button *ngIf="modifiedEntry && (modifiedEntry !== previousEntry)" matSuffix mat-icon-button
      (click)="saveEditChanges()">
      <mat-icon>check</mat-icon>
    </button>
  </mat-form-field>
  <br />

  <button mat-raised-button (click)="cancel()" cdkFocusInitial>
    <mat-icon>cancel</mat-icon> <span>{{'common.button.cancel' | translate}}</span>
  </button>

  <span class="spacer"></span>

  <button mat-raised-button [disabled]=!isModified (click)="proceed()">
    <mat-icon>save</mat-icon> <span>{{'common.button.save' | translate}}</span>
  </button>
</div>