<h1 mat-dialog-title>{{header | translate}}</h1>

<div mat-dialog-content>
  <mat-form-field class="input-form">
    <mat-label>{{ 'config.dialog.string.modify' | translate }}</mat-label>
    <input [(ngModel)]="item" name="value" matTooltipPosition="below"
      matTooltip="{{ 'config.dialog.string.modifytooltip' | translate }}" matInput type="text" (keydown.enter)="proceed()"
      #value="ngModel">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" cdkFocusInitial>
    <mat-icon>arrow_back</mat-icon>
    <span>{{'common.button.cancel' | translate}}</span>
  </button>
  <span class="spacer"></span>
  <button mat-raised-button [disabled]="(!item || (item === previousEntry))" (click)="proceed()">
    <mat-icon>cloud_done</mat-icon>
    <span>{{'common.button.save' | translate}}</span>
  </button>
</div>