import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

export interface Row {
    selected: boolean;
    dbName: string;
    currentLang: string;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'column-dialog',
    templateUrl: './column-dialog.component.html',
    styleUrls: ['./column-dialog.component.scss'],
})
export class ColumnDialogComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    tableName: string;
    header: string;
    allColumns: string[];
    currentColumns: string[];
    rows: Row[];
    dataSource = new MatTableDataSource();

    displayedColumns = ['selected', 'columnname', 'drag'];

    constructor(
        public dialogRef: MatDialogRef<ColumnDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: { domain: string; header: string; allColumns: string[]; currentColumns: string[] },
        private translate: TranslateService
    ) {
        this.tableName = data.domain;
        this.header = data.header;
        this.allColumns = data.allColumns;
        this.currentColumns = data.currentColumns;
    }

    ngOnInit(): void {
        this.rows = [];
        this.currentColumns.forEach((column) => {
            const translatedName = this.translate.instant(this.tableName + '.list.' + column.toLocaleLowerCase());
            this.rows.push({ selected: true, dbName: column, currentLang: translatedName });
        });

        this.allColumns.forEach((column) => {
            if (!this.currentColumns.includes(column)) {
                const translatedName = this.translate.instant(this.tableName + '.list.' + column.toLocaleLowerCase());
                this.rows.push({ selected: false, dbName: column, currentLang: translatedName });
            }
        });
        this.dataSource.data = this.rows;
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    selectionChanged(currentRow: Row): void {
        const indexOfRowToMove = this.rows.indexOf(currentRow);
        const rowToMove = this.rows[indexOfRowToMove];
        this.rows.splice(indexOfRowToMove, 1);
        const firstNonSelectedIndex = this.rows.findIndex((row) => row.selected === false);
        this.rows.splice(firstNonSelectedIndex, 0, rowToMove);
        this.dataSource.data = this.rows;
    }

    onListDrop(event: CdkDragDrop<string[]>): void {
        // Swap the elements around
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
        this.rows[event.currentIndex].selected = true;
        this.dataSource.data = this.rows;
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    proceed(): void {
        const columnHeaders: string[] = [];
        this.rows.forEach((row) => {
            if (row.selected) {
                columnHeaders.push(row.dbName);
            }
        });
        this.dialogRef.close(columnHeaders);
    }
}
