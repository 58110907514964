import { FileUtil } from '../shared/file.util';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { _MatTabBodyBase } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { AdminStoreService } from '../admin/services/admin-store.service';
import { ConfigStoreService } from '../shared/services/config-store.service';
declare const require: any;
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class PdfList {
    datePipe = new DatePipe('fr');
    now: string;

    constructor(
        private fileUtil: FileUtil,
        private translate: TranslateService,
        private adminStore: AdminStoreService,
        private configStore: ConfigStoreService
    ) {}

    generate(action: 'open' | 'print' | 'download', baseName: string, header: string, headerRow: string[], rows: any[]): void {
        const fileName = baseName + '_' + this.datePipe.transform(Date.now(), 'yyyyMMddHHmmSS') + '.pdf';
        const now = this.datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm');
        const body = this.createBody(headerRow, rows);

        this.fileUtil
            .getBase64ImageFromUrl('./assets/images/logo.png')
            .then((logo) => {
                const documentDefinition = this.getDocumentDefinition(logo, header, body, this.now, this.translate);
                // console.log(documentDefinition);

                switch (action) {
                    case 'open':
                        pdfMake.createPdf(documentDefinition).open();
                        break;
                    case 'print':
                        pdfMake.createPdf(documentDefinition).print();
                        break;
                    case 'download':
                        pdfMake.createPdf(documentDefinition).download(fileName);
                        break;

                    default:
                        pdfMake.createPdf(documentDefinition).open();
                        break;
                }
            })
            .catch((e) => alert(e));
    }

    private createBody(headerRow: string[], rows: any[]): {}[] {
        const body: any[] = [];
        const header: any[] = [];
        for (const columnHeader of headerRow) {
            header.push({ text: columnHeader, style: 'headerRow' });
        }
        body.push(header);
        // console.log('Header row:', header);

        for (const row of rows) {
            const line = [];
            for (let index = 0; index < row.length; index++) {
                if (!row[index]) {
                    line.push({ text: ' ', style: 'bodyRow' });
                } else {
                    if (isNaN(row[index])) {
                        line.push({ text: row[index], style: 'bodyRow' });
                    } else {
                        line.push({ text: row[index], style: 'bodyRow', alignment: 'right' });
                    }
                }
            }
            body.push(line);
            // console.log('Row: ', line);
        }
        // console.log('Body: ', body);
        return body;
    }

    getDocumentDefinition(logo: any, header: string, body: any[], now: string, translate: TranslateService) {
        const creator = this.adminStore.appUser.name;
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [40, 80, 40, 40],
            footer: function (currentPage, pageCount) {
                return [
                    {
                        columns: [
                            { text: now, style: 'footer', alignment: 'left' },
                            {
                                text:
                                    translate.instant('common.paginator.page') +
                                    ' ' +
                                    currentPage +
                                    ' ' +
                                    translate.instant('common.paginator.of') +
                                    ' ' +
                                    pageCount,
                                style: 'footer',
                                alignment: 'right',
                            },
                        ],
                    },
                ];
            },
            header: function (currentPage, pageCount, pageSize) {
                return [
                    {
                        columns: [
                            { image: logo, width: 50, margin: [40, 20, 0, 0] },
                            { text: header, style: 'header' },
                        ],
                    },
                ];
            },
            content: [
                {
                    layout: 'lightHorizontalLines',
                    table: { headerRows: 1, body: body },
                },
            ],
            info: {
                title: header,
                author: 'Dani SUTER',
                creator: creator,
                subject: 'List',
            },
            styles: {
                header: { fontSize: 14, bold: true, margin: [0, 45, 0, 10], alignment: 'center' },
                footer: { fontSize: 8, bold: true, margin: [40, 15, 40, 20] },
                headerRow: { fontSize: 9, bold: true, margin: [0, 5, 0, 5] },
                bodyRow: { fontSize: 9, margin: [0, 0, 0, 0] },
                tableHeader: { bold: true },
            },
        };
    }
}
