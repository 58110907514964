import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { OpenWeatherCoordinate } from "src/app/domain/owcoord";
import { WeatherService } from './../../weather.service';



@Component({
  selector: 'addlocationdialog',
  templateUrl: './addlocationdialog.component.html',
  styleUrls: ['./addlocationdialog.component.scss']
})
export class AddLocationDialogComponent {

  countries = ['CH', 'FR', 'IT'];
  zipCode: String;
  matHint = '';
  coord: OpenWeatherCoordinate;
  zoom = 11;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    mapTypeControlOptions: { mapTypeIds: ["roadmap"] },
    zoomControl: false,
    disableDefaultUI: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  error = false;
  locationForm: FormGroup;
  subscriptions = new Subscription();
  // "lat":44.5015,"lon":4.624,"
  constructor(
    private snackBar: MatSnackBar,
    private weatherService: WeatherService,
    public dialogRef: MatDialogRef<AddLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) {
  }

  ngOnInit() {
    this.locationForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      zipCode: new FormControl(''),
    });

    this.subscriptions.add(
      this.locationForm.valueChanges
        .subscribe(value => {
          if (this.locationForm.dirty) {
            if ((this.locationForm.get('country').value === 'FR') || (this.locationForm.get('country').value === 'IT')) {
              this.locationForm.get('zipCode').setValidators([
                Validators.required,
                Validators.pattern('\\d{5}')
              ]);
            }
            if (this.locationForm.get('country').value === 'CH') {
              this.locationForm.get('zipCode').setValidators([
                Validators.required,
                Validators.pattern('\\d{4}')
              ]);
            }

            if (this.locationForm.valid) {
              this.weatherService.getCoordByZIP(this.locationForm.get('country').value, this.locationForm.get('zipCode').value)
                .pipe(take(1))
                .subscribe({
                  next: (coord) => {
                    this.coord = coord;
                    // console.info('Response: ', coord);
                    this.center = {
                      lat: coord.lat,
                      lng: coord.lon,
                    };
                    this.matHint = coord.name;
                    this.error = false;
                  },
                  error: (err) => {
                    console.info('Error: ', err);
                    const config: MatSnackBarConfig = { duration: 5000 };
                    this.snackBar.open(
                      'Error: ' + this.locationForm.get('country').value + " " + this.locationForm.get('zipCode').value + ": " + err.statusText,
                      'Continue',
                      config);
                    this.error = true;
                  }
                  //   coord => {
                  // }
                })
            }
          }
        })
    );
    // navigator.geolocation.getCurrentPosition((position) => {
    // console.info('Coordinates: ',position.coords.latitude,position.coords.longitude);
    // this.center = {
    //   lat: 44.5015,
    //   lng: 4.624,
    // };
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(): void {
    if (this.locationForm.invalid) {
      return;
    }
    // continue work here
  }

  // zoomIn() {
  //   if (this.zoom < this.options.maxZoom) this.zoom++;
  // }

  // zoomOut() {
  //   if (this.zoom > this.options.minZoom) this.zoom--;
  // }

  clickMap(event: google.maps.MapMouseEvent) {
    console.info('Event: ', event.latLng);
  }

  keep(): void {
    this.dialogRef.close(this.coord);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
