import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { FamilyMember } from 'src/app/domain/familymember';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { Guid } from "src/app/shared/guid";
import { FamilyStoreService } from '../../services/family-store.service';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');
const Organization = require('highcharts/modules/organization');
const Exporting = require('highcharts/modules/exporting');
const Sankey = require('highcharts/modules/sankey.js');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Sankey(Highcharts);
Organization(Highcharts);
Exporting(Highcharts);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'family-tree',
  templateUrl: './family-tree.component.html',
  styleUrls: ['./family-tree.component.scss']
})
export class FamilyTreeComponent implements OnInit, AfterViewInit {
  backGroundColor = '#313131';

  public options: any = {
    lang: { thousandsSep: '\u0020' },
    title: { text: '' },
    chart: {
      type: 'organization',
      height: '48%',
      inverted: true,
      backgroundColor: this.backGroundColor,
      style: {
        fontFamily: 'Roboto, Verdana, Arial, Helvetica, sans-serif',
        fontSize: "15px"
      },
    },
    colors: ['#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee', '#2b908f'],
    credits: { enabled: false },
    tooltip: {
      useHTML: true,
      outside: true,
      hideDelay: 100,
      formatter: function () {
        if (!this.point.partner.firstName) return false;
        // console.log(this.point.partner);
        if (this.point.partner.imageUrl) {
          // const img = '<br><img src= "'+this.point.partner.imageUrl+'" height="100" width="75">';
          return this.point.partner.firstName + ' <b>' + this.point.partner.lastName + '</b><br />' +
            '<img src= "' + this.point.partner.imageUrl + '" height="150" width="120"><br />' +
            this.point.partner.comment;
        }
        return this.point.partner.firstName + ' <b>' + this.point.partner.lastName + '</b><br />' +
          this.point.partner.comment;
      }
    },
    series: [{
      type: 'organization',
      name: '',
      keys: ['from', 'to'],
      showdow: true,
      colorByPoint: false,
      nodeWidth: 80, // 90° turned ==> it's actually hight
      hangingIndent: 20,
    }],
    exporting: {
      allowHTML: true,
      sourceWidth: 800,
      sourceHeight: 600
    },
    plotOptions: {
      organization: {
        linkColor: '#777777',
        borderColor: this.backGroundColor,
      }
    }

  };

  colors = ['#f45b5b', '#7798BF', '#ff0066', '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#2b908f', '#aaeeee', '#90ee7e'];
  idTop: string;
  subscriptions = new Subscription();
  tableName = 'family';
  family: FamilyMember[];
  links = [];
  nodes = [];
  maxLevels = 4;
  datepipe = new DatePipe('fr');

  compensation = [
    [0], // 1 child
    [-0.5, 0.5], // 2 children
    [-1.0, 0, 1.0], // 3 children
    [-1.5, -0.5, 0.5, 1.5], // 4 children
    [-2.5, -1.5, 0, 1.5, 2.5], // 5 children
    [-2.5, -1.5, -0.5, 0.5, 1.5, 2.5], // 6 children
    [-3.5, -2.5, -1.5, 0, 1.5, 2.5, 3.5], // 7 children
    [-3.5, -2.5, 1.5, -0.5, 0.5, 1.5, 2.5, 3.5] // 8 children
  ];


  constructor(
    private familyStore: FamilyStoreService,
    private activeRoute: ActivatedRoute,
    private menuStore: MenuStoreService

  ) { }

  ngOnInit(): void {

    this.idTop = this.activeRoute.snapshot.paramMap.get('id');
    this.subscriptions.add(this.familyStore.familyExpanded$.subscribe((family: FamilyMember[]) => {
      this.family = family;
      const seed = this.familyStore.getMemberById(this.idTop);
      // console.log('Seed: ', seed);
      this.options.series[0].cursor = 'pointer';
      this.options.series[0].events = { click: event => this.treeEvent(event) };
      this.showTree(seed);
    }));
  }

  ngAfterViewInit(): void {
    const options: { label: string, icon: string }[] = [];

    this.menuStore.componentInfo = {
      name: 'family-tree',
      form: false,
      options: options,
      showFilter: false
    };
  }

  private showTree(seed: FamilyMember) {
    this.links = [];
    this.nodes = [];
    const depth = this.getMaxDepth(seed);
    // console.log('buildFamilyTree depth: ', depth);
    this.buildFamilyTree(depth, seed);
    this.options.series[0].data = this.links;
    this.options.series[0].nodes = this.nodes;
    // console.log('Links: ', this.options.series[0].data);
    // console.log('Nodes: ', this.options.series[0].nodes);
    // console.log('Options: ', this.options);
    Highcharts.chart('familytree', this.options);
  }

  private buildFamilyTree(maxDepth: number, seed: FamilyMember) {
    // console.log('buildFamilyTree seed: ', seed);
    let column = 0;
    let color = 0;
    //
    // Level 0
    //
    this.addToNode(seed, column, this.colors[color], 0, 'normal');

    if (!seed.childrenIds) {
      this.addLink(seed, seed);
      return;
    }

    //
    // Level 1
    //
    //               +-------+
    //               |   0   |
    //               +---+---+
    //                   |
    //     +-------------+-------------+
    //     |             |             |
    // +---+---+     +---+---+     +---+---+
    // |   1   |     |   1   |     |   1   |
    // +-------+     +-------+     +-------+
    //
    let totalGrandChildrenCount = 0;
    for (const childId of seed.childrenIds) {
      const child = this.familyStore.getMemberById(childId);
      if (maxDepth === 3) {
        totalGrandChildrenCount += (child.childrenIds) ? child.childrenIds.length : 1;
      } else {
        totalGrandChildrenCount++;
      }
    }

    const colBefore = ++color;
    column++;

    let start = -totalGrandChildrenCount / 2;
    // console.log('totalGrandChildrenCount: ', totalGrandChildrenCount);
    // console.log('Start: ', start);

    for (let childNumber = 0; childNumber < seed.childrenIds.length; childNumber++) {
      const child = this.familyStore.getMemberById(seed.childrenIds[childNumber]);
      const grandChildrenCountOfThisChild = (child.childrenIds && maxDepth === 3) ? child.childrenIds.length : 1;

      console.log(' child: ', child.firstName, ' grandChildrenCountOfThisChild: ', grandChildrenCountOfThisChild);
      this.addLink(seed, child);
      // console.log('Compensation: ', this.compensation[seed.childrenIds.length - 1][childNumber]);
      const offset = start + grandChildrenCountOfThisChild / 2 - this.compensation[seed.childrenIds.length - 1][childNumber];
      // console.log('Offset: ', offset);
      switch (maxDepth) {
        case 1:
          this.addToNode(child, column, this.colors[color], 100 * offset, 'bottom');
          break;
        case 2:
          this.addToNode(child, column, this.colors[color], 100 * offset, 'hanging');
          break;
        case 3:
          this.addToNode(child, column, this.colors[color], 100 * offset, 'normal');
          break;

        default:
          break;
      }

      start += grandChildrenCountOfThisChild;
      // console.log('Start: ', start);
      color = ++color % this.colors.length;;
    }
    //
    // Level 2
    //
    //               +-------+
    //               |   0   |
    //               +---+---+
    //                   |
    //     +-------------+-------------+
    //     |             |             |
    // +---+---+     +---+---+     +---+---+
    // |   1   |     |   1   |     |   1   |
    // +-------+     +-------+     +-------+
    //     |             |             |
    //     |             |             +-------------+
    //     |             |             |             |
    // +---+---+     +---+---+     +---+---+     +---+---+
    // |   2   |     |   2   |     |   2   |     |   2   |
    // +-------+     +-------+     +-------+     +-------+
    //
    color = colBefore;
    column++;
    const style = (maxDepth === 3) ? 'hanging' : 'bottom';

    for (const childId of seed.childrenIds) {
      const child = this.familyStore.getMemberById(childId);

      if (child.childrenIds) {
        for (const grandChildId of child.childrenIds) {
          const grandChild = this.familyStore.getMemberById(grandChildId);
          this.addLink(child, grandChild);
          this.addToNode(grandChild, column, this.colors[color], 0, style);
        }
      }
      if (!child.childrenIds && (maxDepth === 3)) { // no children
        const grandChild = { key: Guid.newGuid(), firstName: ' ', lastName: ' ' };
        this.addLink(child, grandChild);
        this.addToNode(grandChild, column, this.backGroundColor, 0, style);
      }

      color = ++color % this.colors.length;;
    }
    //
    // Level 3
    //
    color = colBefore;
    column++;
    for (const childId of seed.childrenIds) {
      const child = this.familyStore.getMemberById(childId);
      if (child.childrenIds) {
        for (const grandChildId of child.childrenIds) {
          const grandChild = this.familyStore.getMemberById(grandChildId);
          if (grandChild.childrenIds) {

            for (const grandGrandChildId of grandChild.childrenIds) {
              const grandGrandChild = this.familyStore.getMemberById(grandGrandChildId);
              this.addLink(grandChild, grandGrandChild);
              this.addToNode(grandGrandChild, column, this.colors[color], 0, 'bottom');
            }
          }
        }
      }
      color = ++color % this.colors.length;
    }
    return;
  }

  private getMaxDepth(seed: FamilyMember): number {
    let maxDepth = 0;
    if (!seed.childrenIds) return maxDepth;
    maxDepth = 1;

    for (const childId of seed.childrenIds) {
      const child = this.familyStore.getMemberById(childId);
      if (child.childrenIds) {
        maxDepth = 2;
        for (const grandChildId of child.childrenIds) {
          const grandChild = this.familyStore.getMemberById(grandChildId);
          if (grandChild.childrenIds) return 3;
        }
      }
    }
    return maxDepth;
  }

  private addLink(parent: FamilyMember, child: FamilyMember) {
    const link = [];
    link.push(parent.key);
    link.push(child.key);
    this.links.push(link);
  }

  private addToNode(person: FamilyMember, column: number, color: string, offset: number, layout: string) {
    // console.log('addToNodes seed: ', seed);
    const rgb = this.hexToRgb(color);
    let image = null;
    if (person.imageUrl) { image = person.imageUrl }
    let partner: FamilyMember = {};
    if (person.partnerId) {
      partner = this.familyStore.getMemberById(person.partnerId);
      partner.comment = this.birthAndDeath(partner);
    }

    const node: {
      id: string,
      name: string,
      title: string,
      description: string,
      color: string,
      dataLabels: {},
      column?: number,
      layout?: string,
      offset: string,
      image?: string,
      partner?: {}
    } = {
      id: person.key,
      name: person.firstName, // uses <h4>
      title: person.lastName.toLocaleUpperCase(), // uses <p>
      description: this.birthAndDeath(person), // uses <p>
      color: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',0.9)',
      dataLabels: { style: { fontSize: '12px', color: 'contrast', textOverflow: 'ellipsis' } },
      // offsetVertical: '100%',
      offset: offset.toString() + '%',
      image,
      partner: partner
    };

    if ((layout === 'normal') || (layout === 'hanging')) {
      node.layout = layout;
      node.column = column;
    }
    this.nodes.push(node);
  }

  private birthAndDeath(person: FamilyMember): string {
    const dayOfBirth = person.dateOfBirth ? this.datepipe.transform(new Date(person.dateOfBirth), '* yyyy') : ' ';
    const dayOfDeath = person.dateOfDeath ? this.datepipe.transform(new Date(person.dateOfDeath), '† yyyy') : ' ';
    let lifeSpan: string = ' '
    if (dayOfBirth.length > 1 && dayOfDeath.length > 1) lifeSpan = dayOfBirth + ', ' + dayOfDeath;
    if (dayOfBirth.length === 1 && dayOfDeath.length > 1) lifeSpan = dayOfDeath;
    if (dayOfBirth.length > 1 && dayOfDeath.length === 1) lifeSpan = dayOfBirth;
    return lifeSpan;
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  treeEvent(event) {
    const newId = event.point.id;
    const seed = this.familyStore.getMemberById(newId);
    // console.info('New id: ', newId, ' Name: ', event.point.name);
    if (this.idTop === newId) { // top level
      if (seed.fatherId) {
        const seedFather = this.familyStore.getMemberById(seed.fatherId);
        this.idTop = seedFather.key;
        this.showTree(seedFather);
      }
    } else {
      this.idTop = newId;
      this.showTree(seed);
    }
  }
}
