<form #form="ngForm" id="form" class="body">
    <h2 mat.dialog-title>{{ "security.form.title" | translate }}</h2>
    <mat-icon *ngIf="enableDeleteButton" class="cornerIcon" matTooltip="{{'security.form.delete.tooltip' | translate}}"
        (click)="deleteSecurity()">
        delete
    </mat-icon>
    <mat-dialog-content class="mat-typography">
        <section class="section">

            <mat-form-field style="width: 23%; padding-right: 2%;">
                <mat-label>{{"security.form.symbol.label" | translate}}</mat-label>
                <input matInput [(ngModel)]="security.symbol" #symbol="ngModel" id="symbol" name="symbol"
                    placeholder="{{ 'security.form.symbol.placeholder' | translate }}" required />
                <mat-error *ngIf="symbol.invalid">{{"security.form.symbol.error.required" | translate}} </mat-error>
            </mat-form-field>

            <mat-form-field style="width: 23%; padding-right: 2%;">
                <mat-label>{{"security.form.exchange.label" | translate}}</mat-label>
                <input matInput [(ngModel)]="security.exchange" #exchange="ngModel" id="exchange" name="exchange"
                    placeholder="{{ 'security.form.exchange.placeholder' | translate }}" required />
                <mat-error *ngIf="!!exchange.errors?.required">
                    {{ "common.form.error.required" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field style="width: 23%; padding-right: 2%;">
                <mat-label>{{ "security.form.isin.label" | translate }}</mat-label>
                <input matInput [(ngModel)]="security.isin" #isin="ngModel" id="isin" name="isin" validateIsin
                    placeholder="{{ 'security.form.isin.placeholder' | translate }}" required />
                <mat-error *ngIf="!!isin.errors?.required">
                    {{ "common.form.error.required" | translate }}
                </mat-error>
                <mat-error *ngIf="!!isin.errors?.checksum">
                    {{ "security.form.isin.error.checksum" | translate }}
                </mat-error>
                <mat-error *ngIf="!!isin.errors?.format">
                    {{ "security.form.isin.error.format" | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 23%; padding-right: 2%;">
                <mat-select required [(ngModel)]="security.currency" name="currency" id="currency" #currency="ngModel"
                    placeholder="{{
                'security.form.currency.placeholder' | translate
              }}">
                    <mat-option *ngFor="let currency of (config$ | async).currencies" [value]="currency">
                        {{ currency }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="currency.invalid"> {{"common.form.error.required" | translate}}</mat-error>
            </mat-form-field>
        </section>

        <section class="section">
            <mat-form-field style="width: 98%; padding-right: 2%;">
                <mat-label>{{
            "security.form.company.label" | translate
          }}</mat-label>
                <input matInput [(ngModel)]="security.company" #securitycompany="ngModel" name="securitycompany"
                    placeholder="{{'security.form.company.placeholder' | translate}}" />
            </mat-form-field>
        </section>

        <section>
            <mat-form-field style="width: 98%; padding-right: 2%;">
                <mat-label>{{"security.form.comment.label" | translate}}</mat-label>
                <textarea matInput [(ngModel)]="security.comment" #comment="ngModel" name="comment"
                    placeholder="{{'security.form.comment.placeholder' | translate}}"></textarea>
            </mat-form-field>
        </section>

        <footer *ngIf="security.modifiedOn">
            <p class="mat-small">
                {{ "common.form.modifiedby" | translate }} :
                {{ security.modifiedby }}
                {{ "common.form.modifiedon" | translate }} :
                {{ security.modifiedon | date: "medium" }}
            </p>
        </footer>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-raised-button (click)="$event.stopPropagation(); cancel()" cdkFocusInitial>
            <mat-icon>undo</mat-icon>
        </button>
        <span class="spacer"></span>
        <button mat-raised-button [disabled]="form.invalid" (click)="$event.stopPropagation(); proceed()">
            <mat-icon>cloud_done</mat-icon>
        </button>
    </div>
</form>