import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ifHasRight]'
})

export class IfHasRightDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) { }

  permission: string;

  @Input() set ifHasRight(permission: string) {
    this.permission = permission;
  }

  @Input() set ifHasRightIn(module: string) {
    // console.log('IfHasRightDirective: ', module, ' - ', this.permission)
    const hasRight = this.authorizationService.hasRights(module, this.permission);
    if (hasRight) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
