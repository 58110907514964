<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="Module">
    <mat-header-cell *matHeaderCellDef> {{'role.list.header.module' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let permission">{{permission.moduleTranslated}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Permission">
    <mat-header-cell *matHeaderCellDef> {{'role.list.header.permission' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let permission"> {{permission.actionTranslated}} </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="role" *ngFor="let role of roles">
    <mat-header-cell *matHeaderCellDef> {{role}} </mat-header-cell>
    <mat-cell *matCellDef="let permission">
      <button *ngIf="permission.header" (click)="allPermissions(permission,role)" mat-icon-button color="warn"
        [disabled]=isDisabled color="warn" matTooltip="{{'role.list.header.setall' | translate}}"
        matTooltipShowDelay="1000" matTooltipPosition="right">
        <mat-icon>{{permission[role]}}</mat-icon>
      </button>
      <!-- <mat-checkbox *ngIf="permission.header" (change)="allPermissions(permission)" [(ngModel)]="permission[role]" [checked]="permission[role]"
        [disabled]=isDisabled [color]="warn">
      </mat-checkbox> -->
      <mat-checkbox *ngIf="!permission.header" (change)="permissionChanged(permission)" [(ngModel)]="permission[role]"
        [checked]="permission[role]" [disabled]=isDisabled matTooltip="{{permission.actionTranslated}}"
        matTooltipShowDelay="1000" matTooltipPosition="right">
      </mat-checkbox>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns;" [ngClass]="{'highlight': row.header }"></mat-row>
</mat-table>
<mat-paginator [pageSize]="24" [pageSizeOptions]="[18, 24, 30]"></mat-paginator>