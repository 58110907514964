import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizationService } from "src/app/admin/services/authorization.service";
import { Forecast } from "src/app/domain/forecast";
import { Weather } from 'src/app/domain/weather';
import { DataService } from "src/app/shared/services/data.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'weather-card',
  templateUrl: './weather-card.component.html',
  styleUrls: ['./weather-card.component.scss']
})
export class WeatherCardComponent implements OnInit {
  @Input()  weather: Weather;
  @Input()  forecast: Forecast;
  @Input() city: String;

  temp_min: number = +100;
  temp_max: number = -100;
  icon: string;
  directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];

  subscriptions = new Subscription();

  constructor(
    private dataService: DataService,
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    // console.info('Weather: ',this.weather);
    // console.info('Forecast: ',this.forecast);
    this.setMinMax(this.forecast);
    this.icon = './assets/images/' + this.weather.weather[0].icon + '.png';
  }

  setMinMax(forecast: Forecast) {

    for (const timepoint of this.forecast.list) {
      const dt = 1000 * timepoint.dt;
      if (isToday(dt)) {
        const temp = timepoint.main.temp;
        if (temp > this.temp_max) this.temp_max = temp;
        if (temp < this.temp_min) this.temp_min = temp;
      } else {break}
    }
  }

  deg2dir(deg) {
    // A direction of 0 degrees is due North on a compass,
    // and 180 degrees is due South.
    // A direction of 270 degrees would indicate a wind blowing in from the west.
    return this.directions[Math.trunc((deg + 22.5) / 360 * 8)];
  }

  deleteCity(){
    const uid = this.authorizationService.appUser.key;
    const location = {key: this.city};
    console.info('Delete city: ',location, uid);
    this.dataService.delete('/users/' + uid + '/locations', location);
    window.location.reload();
  }
}

function isToday(dt: number) {
  const today = new Date();
  const date = new Date(dt)
  return date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
}
