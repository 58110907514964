import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmDialogComponent } from './../../../shared/components/confirm-dialog/confirm-dialog.component';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { ACTIONS } from "src/app/domain/actions";
import { MODULES } from "src/app/domain/modules";
import { MenuStoreService } from "src/app/menu/services/menu-store.service";
import { ConfigStoreService } from "src/app/shared/services/config-store.service";
import { DataService } from "src/app/shared/services/data.service";
import { AdminStoreService, RoleModulesPermissions } from "../../services/admin-store.service";
import { AuthorizationService } from "../../services/authorization.service";

export interface Row {
    module?: string;
    action?: string;
    moduleTranslated?: string;
    actionTranslated?: string;
    header?: boolean,
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'role-permissions',
    templateUrl: './role-permissions.component.html',
    styleUrls: ['./role-permissions.component.scss'],
})
export class RolePermissionsComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    roleModulesPermissions$: Observable<RoleModulesPermissions[]> = this.adminStore.roleModulesPermissions$;

    tableName = 'permissions';
    dataSource = new MatTableDataSource();
    initialize = 'permissions.role.init';
    printList = 'permissions.role.print';
    roles: string[];
    modules: string[];
    isDisabled;
    icon: string;
    subscriptions = new Subscription();
    displayColumns = [];
    yesNoDialogRef: any;
    setAll = 'done_all';
    resetAll = 'remove_done';
    initialRole = 'SUPERUSER';

    // https://stackoverflow.com/questions/52217179/angular-material-mat-table-row-grouping

    constructor(
        private dataService: DataService,
        // private pdfList: PdfList,
        private authorizationService: AuthorizationService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private configStore: ConfigStoreService,
        private menuStore: MenuStoreService,
        private adminStore: AdminStoreService
    ) { }

    ngOnInit(): void {
        this.isDisabled = !this.authorizationService.hasRights('permissions', ACTIONS.canUpdate);
        this.displayColumns.push('Module');
        this.displayColumns.push('Permission');

        this.roles = this.configStore.config.roles;
        if (!this.roles) { // applications initial setup not done yet
            this.roles = [this.initialRole];
            this.isDisabled = false;
        }

        this.roles.forEach((role) => this.displayColumns.push(role));

        this.subscriptions.add(this.roleModulesPermissions$
            .subscribe((roleModulesPermissions) => {
                if (roleModulesPermissions.length === 0) this.isDisabled = false;
                // check if initialized in config module
                this.modules = this.configStore.config.modulesActivated.length > 0 ? this.configStore.config.modulesActivated : MODULES;
                this.dataSource.data = this.reformatPermissionData(roleModulesPermissions);
            })
        );
    }

    ngAfterViewInit(): void {
        this.menuStore.componentInfo = {
            name: this.tableName,
            form: false,
            options: [
                { label: this.initialize, icon: 'open_in_new', optionFn: () => this.initializePermissions() },
                // { label: this.printList, icon: 'print', optionFn: () => this.printPermissionList() },
            ],
            showFilter: false,
        };
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private reformatPermissionData(roleModulesPermissions: RoleModulesPermissions[]) {
        const dbPermissions = [];

        this.modules.forEach((module) => {
            const headerRow: Row = {
                module,
                moduleTranslated: this.translate.instant('role.list.module.' + module.toLowerCase()),
                action: '',
                actionTranslated: '',
                header: true,
            };
            this.roles.forEach((role) => headerRow[role] = this.setAll);
            dbPermissions.push(headerRow);

            Object.keys(ACTIONS).forEach((action) => {
                const actionTranslated = this.translate.instant('role.list.permission.' + action.toLowerCase());
                const row = { module, moduleTranslated: '', action, actionTranslated, header: false };

                const modulePermissions = roleModulesPermissions.find((obj) => obj.key === module);
                let modulePermissionsRoles;
                if (modulePermissions) { if (modulePermissions[action]) modulePermissionsRoles = modulePermissions[action].roles; }

                this.roles.forEach((role) => {
                    if (modulePermissionsRoles && modulePermissionsRoles[role]) {
                        row[role] = modulePermissionsRoles[role];
                        headerRow[role] = this.resetAll; // if one action is enabled, change header icon to "clear all"
                    } else { row[role] = false; }
                });

                dbPermissions.push(row);
            });
        });
        return dbPermissions;
    }

    permissionChanged(row): void {
        // console.log('Role: ', role, ' Module: ', permission.module, ' Action: ', permission.action, ' Permission: ', permission[role]);
        const rolesHavingRight = {};
        this.roles.forEach((role) => {
            if (row[role]) rolesHavingRight[role] = true;
        });
        this.dataService.update(this.tableName, { key: row.module }, row.module);
        this.dataService.set(this.tableName, { roles: rolesHavingRight }, row.module + '/' + row.action);
    }

    allPermissions(row: Row, role: string) {
        // console.log(this.dataSource.data, row, role);
        const currentSetting = row[role];
        const modulePermissions: RoleModulesPermissions = { key: row.module };
        
        for (const item of this.dataSource.data as Row[]) {
            if ((item.module === row.module) && item.header) {
                item[role] = (row[role] === this.setAll) ? this.resetAll : this.setAll;
            } else if ((item.module === row.module) && !item.header) {
                item[role] = (currentSetting === this.setAll);
                const rolesHavingRight = {};
                this.roles.forEach((definedRole) => {
                    if (item[definedRole]) rolesHavingRight[definedRole] = true;
                });
                modulePermissions[item.action] = { roles: rolesHavingRight };
            }
        }
        
        // console.log('modulePermissions: ',modulePermissions);
        this.dataService.set(this.tableName, modulePermissions, row.module);
    }

    private initializePermissions() {
        // Permissions on all actions for SUPERUSER
        this.yesNoDialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '35vw',
            data: { title: 'role.dialog.confirm' },
        });

        this.yesNoDialogRef.afterClosed().subscribe((remove) => {
            // console.log('Answer: ', remove);
            if (remove) {
                const rolesHavingRight = {};
                rolesHavingRight[this.initialRole] = true;

                for (const module of this.modules) {
                    const modulePermissions: RoleModulesPermissions = { key: module };
                    Object.keys(ACTIONS).forEach((action) => modulePermissions[action] = { roles: rolesHavingRight });
                    this.dataService.set(this.tableName, modulePermissions, module);
                }
            }
        });
    }

    private printPermissionList() {
        const baseName = 'permissions';
        const headerRow = [];
        headerRow.push('Nr');
        const moduleHeader = this.translate.instant('role.list.header.module');
        const permissionHeader = this.translate.instant('role.list.header.permission');
        headerRow.push(moduleHeader);
        headerRow.push(permissionHeader);
        this.roles.forEach((role) => {
            headerRow.push(role);
        });
        const header = this.translate.instant('role.list.print.header');

        const printRows = [];
        let moduleNr = 0;

        this.dataSource.data.forEach((row: Row) => {
            const line = [];
            if (row.moduleTranslated) {
                line.push(++moduleNr);
            } else {
                line.push('');
            }
            line.push(row.moduleTranslated);
            line.push(row.actionTranslated);
            this.roles.forEach((role) => {
                if (row[role]) {
                    line.push('X');
                } else {
                    line.push('');
                }
            });
            printRows.push(line);
        });

        // this.pdfList.generate('download', baseName, header, headerRow, printRows);
    }
}
