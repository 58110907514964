import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from 'src/app/admin/services/config.service';

export interface KeyValuePair {
  key: string,
  value: any
}

export interface Config {
  title: string[],
  sex: string[],
  roles: string[],
  functions: string[],
  contactDisplayedColumns: string[],
  contactAllColumns: string[],
  contactTypes: string[],
  locations: KeyValuePair[],
  baseCcy: string,
  currencies: string[],
  defaultLanes: string[],
  modulesActivated: string[],
  familyDisplayedColumns: string[],
  familyAllColumns: string[],
  nom: string,
  site: string,
  mail: string,
};

@Injectable({ providedIn: 'root' })

export class ConfigStoreService {

  private configSubject = new BehaviorSubject<Config>({
    title: [],
    sex: [],
    roles: [],
    functions: [],
    contactDisplayedColumns: [],
    contactAllColumns: [],
    contactTypes: [],
    locations: [],
    baseCcy: '',
    currencies: [],
    defaultLanes: [],
    modulesActivated: [],
    familyDisplayedColumns: [],
    familyAllColumns: [],
    nom: '',
    site: '',
    mail: '',
  });
  config$: Observable<Config> = this.configSubject.asObservable();

  constructor(
    private configService: ConfigService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
  ) { }

  init(): Promise<any> {
    //
    // Set language
    //
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let browserLang = this.translate.getBrowserLang();
    browserLang = browserLang.match(/en|fr|de/) ? browserLang : 'en';
    if (browserLang === 'de') {
      this.translate.use('de-ch')
    } else {
      this.translate.use(browserLang); 
    }
    this.dateAdapter.setLocale(browserLang);
    // console.log('init browserLang:',browserLang);
    //
    // Set configuration
    //
    let configuration: Config = this.config;

    return new Promise((resolve, reject) => {
      this.configService.readAllConfigWithKey()
        .subscribe(config => {
          config.forEach(item => configuration[item.key] = item.data);
          this.configSubject.next(configuration);
          console.log('configuration:',config);
          return resolve('Success');
        })
    });
  }

  public get config(): Config {
    return this.configSubject.getValue();
  }

  public set config(config: Config) {
    this.configSubject.next(config);
  }
}
