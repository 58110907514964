import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    private db: AngularFireDatabase,
  ) { }

  update(type: string, value: {}) {
    // console.trace('update',type, value);
    return this.db.object('/config/' + type + '/').set(value);
  }

  readType(type: string) {
    return this.db.list(`/config/` + type).valueChanges();
  }

  readAllConfig() {
    return this.db.list(`/config/`).valueChanges();
  }

  readAllConfigWithKey() {
    // console.trace('readAllConfigWithKey')
    return this.db.list(`/config/`)
      .snapshotChanges()
      .pipe(
        // tap(config => console.trace('Response from db: ', config)),
        map(config => {
          return config.map(a => {
            const data = a.payload.val();
            const key = a.payload.key;
            return { key, data };  // or {key, data} in case data is not an Objectj
          });
        }));
  }

  delete(type: string, entry) { // TODO: replace with dataservice
    return this.db.object('/config/' + type + '/' + entry).remove();
  }

  deleteAllType(type: string) {
    return this.db.object('/config/' + type + '/').remove();
  }

  deleteAllConfig() {
    return this.db.object('/config/').remove();
  }
}
