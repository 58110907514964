<div class="mat-elevation-z8 container">
    <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="date">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.date' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.transactiondate | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="securitytype">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.securitytype' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getSecType(element.securityType)}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="transactiontype">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.transactiontype' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getTransType(element.transactionType)}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="company">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.company' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.company}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="units">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.units' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.units}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.price' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.unitPrice | currency:element.currency:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalprice">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.totalprice' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.priceInBaseCcy | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalPrice | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="taxes">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.taxes' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.taxes | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalTaxes | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="commission">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.commission' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.commission | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalCommission | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="dividends">
            <mat-header-cell *matHeaderCellDef> {{ 'portfolio.list.dividends' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dividends | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalDividends | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="totalcost">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.cost' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.totalCost | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalCosts | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="value">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.value' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{getValue(element) | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{ totalValue | currency:baseCcy:'symbol':'1.2-2':locale}} </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns" class="bold"></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>