import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Security } from 'src/app/domain/security';
import { SecurityType } from 'src/app/domain/securitytypes';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { TransactionType } from 'src/app/domain/transactiontypes';
import { FxRatesService } from 'src/app/fxrates/services/fxrates.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfigStoreService, Config } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'transaction-dialog',
  templateUrl: './transaction-dialog.component.html',
  styleUrls: ['./transaction-dialog.component.scss'],
})
export class TransactionDialogComponent
  implements OnInit, OnDestroy, AfterViewInit {
    config$: Observable<Config> = this.configStore.config$;
  @ViewChild('form', { static: false }) form: UntypedFormGroup;

  subscriptions = new Subscription();
  header: string;
  transaction: TransactionSecurity;
  baseCcy: string;
  transactionType = [
    { label: 'transaction.form.buy', value: 1 },
    { label: 'transaction.form.sell', value: -1 },
  ];
  securities: Security[];
  tableName = 'portfolio';
  transactionDate: Date;
  yesNoDialogRef: any;
  enableDeleteButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<TransactionDialogComponent>,
    private fxRatesService: FxRatesService,
    private dataService: DataService,
    private dialog: MatDialog,
    private configStore: ConfigStoreService,
    @Inject(MAT_DIALOG_DATA) public data: TransactionSecurity
  ) {
    this.transaction = data;
    if (!this.transaction.buyOrSell) this.transaction.buyOrSell = 1; // default: buy
    if (this.transaction.transactionDate) {
      this.enableDeleteButton = true;
      this.transactionDate = new Date(this.transaction.transactionDate)
    } else {
      this.enableDeleteButton = false;
      this.transactionDate = new Date(Date.now());
    }
    this.baseCcy = this.configStore.config.baseCcy; // default: base currency
    if (!this.transaction.currency) this.transaction.currency = this.baseCcy;
    // console.log(this.transaction);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.readAllSortBy(this.tableName + '/securities', 'key')
        .subscribe((securities: Security[]) => { this.securities = securities; })
    );
  }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe((result) => {
      if (!this.form.pristine) {
        // console.log(this.form);
        let unitPrice: number;
        let price: number;
        let units: number;
        let taxes = 0;
        let commission = 0;
        const type = Number(this.form.controls.buyorsell.value);

        if (this.form.controls.units.value) units = Number(this.form.controls.units.value);
        if (this.form.controls.unitPrice.value) unitPrice = Number(this.form.controls.unitPrice.value);
        if (this.form.controls.taxes.value) taxes = Number(this.form.controls.taxes.value);
        if (this.form.controls.commission.value) { commission = Number(this.form.controls.commission.value); }
        if (units && unitPrice) price = type * units * unitPrice;

        if (price) {
          this.form.controls.price.setValue(price, { emitEvent: false });
          if (this.form.controls.currency.value === this.baseCcy) {
            this.form.controls.priceInBaseCcy.setValue(price, { emitEvent: false });
            const totalCost = price + taxes + commission;
            this.form.controls.totalCost.setValue(totalCost, { emitEvent: false });
          } else {
            this.subscriptions.add(
              this.fxRatesService
                .getFXRate(this.form.controls.currency.value, this.baseCcy, this.transactionDate.getTime())
                .subscribe((rate) => {
                  // console.log('Exchange rate: ', +rate);
                  this.form.controls.priceInBaseCcy.setValue(+rate * price, { emitEvent: false });
                  const totalCost = +rate * price + taxes + commission;
                  this.form.controls.totalCost.setValue(totalCost, { emitEvent: false });
                })
            );
          }
        } else {
          this.form.controls.price.setValue(null, { emitEvent: false });
          this.form.controls.priceInBaseCcy.setValue(null, { emitEvent: false });
          this.form.controls.totalCost.setValue(null, { emitEvent: false });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateLinkedFields(symbol: string) {
    // console.log(symbol);
    const sec = this.securities.find(
      (entry) => symbol === entry.symbol
    );
    // console.log(sec.isin);
    this.transaction.isin = sec.isin;
    this.transaction.company = sec.company;
    this.transaction.exchange = sec.exchange;
  }

  deleteTransaction() {
    this.yesNoDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '20vw',
      data: { title: 'common.dialog.confirm.delete.title', message: 'common.dialog.confirm.delete.message' }
    });

    this.yesNoDialogRef.afterClosed().subscribe(remove => {
      if (remove) this.dialogRef.close('delete');
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  proceed(): void {
    this.subscriptions.unsubscribe();
    this.transaction.commission = Number(this.form.controls.commission.value); // convert to number
    this.transaction.taxes = Number(this.form.controls.taxes.value); // convert to number
    this.transaction.unitPrice = Number(this.form.controls.unitPrice.value); // convert to number
    this.transaction.units = Number(this.form.controls.units.value); // convert to number
    this.transaction.transactionDate = this.transactionDate.getTime(); // convert to millisecs
    this.transaction.transactionType = (this.transaction.buyOrSell > 0) ? TransactionType.BUY : TransactionType.SELL;
    this.transaction.securityType = SecurityType.STOCK;
    // console.log('Date: ', new Date(this.transaction.transactionDate));
    this.dialogRef.close(this.transaction);
  }
}
