import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ACTIONS } from 'src/app/domain/actions';
import { MODULES } from 'src/app/domain/modules';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { Config, ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { AuthorizationService } from '../../services/authorization.service';
import { FileUtil } from './../../../shared/file.util';
import { ConfigService } from './../../services/config.service';
import { ConfigDialogArrayComponent } from './config-dialog-array/config-dialog-array.component';
import { ConfigDialogModulesComponent } from './config-dialog-modules/config-dialog-modules.component';
import { ConfigDialogStringComponent } from './config-dialog-string/config-dialog-string/config-dialog-string.component';

export interface Row {
  key: string;
  description?: string;
  count: number;
  source: string;
  list: string[] | string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})

export class ConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filterString$: Observable<string> = this.menuStore.filterString$;
  option$: Observable<string> = this.menuStore.option$;
  config$: Observable<Config> = this.configStore.config$;

  subscriptions = new Subscription();

  table: Row[] = [];
  fileEntries;
  dbEntries;
  tableName = 'config';
  optionSaveInDB = 'config.options.saveindb';
  optionSelectActiveModules = 'config.options.selectmodules';

  fileSource = 'config.table.file';
  dbSource = 'config.table.database';

  displayedColumns = ['key', 'description', 'count', 'source'];
  dataSource = new MatTableDataSource();
  configDialogArrayRef: MatDialogRef<ConfigDialogArrayComponent>;
  configDialogStringRef: MatDialogRef<ConfigDialogStringComponent>;
  modulesDialogRef: MatDialogRef<ConfigDialogModulesComponent>;


  constructor(
    private configService: ConfigService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private fileUtil: FileUtil,
    private menuStore: MenuStoreService,
    private configStore: ConfigStoreService,
    private auth: AuthorizationService
  ) { }

  ngOnInit() {

    this.subscriptions.add(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setEntryDescriptions();
    }));

    this.subscriptions.add(this.option$.subscribe((option: string) => {
      if (option === this.optionSaveInDB) { this.saveFileSourcesInDb(); }
      if (option === this.optionSelectActiveModules) { this.selectActivatedModules(); }
    }));

    this.fileEntries = [];
    this.subscriptions.add(this.fileUtil.getJSON('assets/config.json').subscribe(fileConfig => {
      if (fileConfig) {
        this.fileEntries = fileConfig;
        this.updateTable();
      }
    }));
    // console.log(fileConfig);
    this.subscriptions.add(this.filterString$.subscribe(queryString => this.filter(queryString)));

    this.dbEntries = [];
    this.subscriptions.add(this.config$.subscribe(entries => {
      if (entries) {
        this.dbEntries = entries;
        this.updateTable();
      }
    }));

  }

  ngAfterViewInit(): void {
    const options: { label: string, icon: string }[] = [];

    if (this.auth.hasRights(this.tableName, ACTIONS.canUpdate)) {
      options.push({ label: this.optionSaveInDB, icon: 'backup' });
      options.push({ label: this.optionSelectActiveModules, icon: 'done' });
    }

    this.menuStore.componentInfo = {
      name: this.tableName,
      form: false,
      options: options,
      showFilter: true
    };

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private selectActivatedModules() {
    let activatedModules = this.configStore.config.modulesActivated;
    if (!activatedModules) { activatedModules = []; }

    this.modulesDialogRef = this.dialog.open(ConfigDialogModulesComponent, {
      width: '750px',
      data: {
        header: 'config.modules.activate',
        allModules: MODULES,
        activatedModules,
      }
    });

    this.modulesDialogRef.afterClosed().subscribe(modules => {
      if (!modules) { return; }

      this.configService.deleteAllType('modulesActivated').then(() => {
        this.configService.update('modulesActivated', modules)
      }
      );
    });
  }


  private updateTable() {
    this.table = [];

    if (this.dbEntries) {
      Object.keys(this.dbEntries).forEach(key => {
        if (this.dbEntries[key]) {
          const row: Row = {
            key,
            count: this.dbEntries[key].length,
            source: this.dbSource,
            list: this.dbEntries[key]
          };
          this.table.push(row);
        }
      });
    }
    if (this.fileEntries) {
      Object.keys(this.fileEntries).forEach(key => {
        if (!this.dbEntries[key] && this.fileEntries[key]) {
          const row: Row = {
            key,
            count: this.fileEntries[key].length,
            source: this.fileSource,
            list: this.fileEntries[key]
          };
          this.table.push(row);
        }
      });
    }

    this.setEntryDescriptions();
    // console.log(this.table);
    this.dataSource.data = this.table;
    this.dataSource.paginator = this.paginator;
  }

  private filter(query: string) {
    this.dataSource.filter = query.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectRow(row: Row) {
    const index = this.table.findIndex((entry: Row) => entry.key === row.key);
    // console.log(this.table[index].list);
    // console.log(Array.isArray(this.table[index].list));

    if (this.table[index].list instanceof Array) {
      if (this.table[index].list.length === 1) {
        const value = this.table[index].list[0];
        this.editString(row, value);
      } else {
        const values: string[] = this.table[index].list as string[];
        this.editArray(row, values);
      }
    } else {
      const value = this.table[index].list as string;
      this.editString(row, value);
    }
  }

  private editArray(row, values: string[]) {

    this.configDialogArrayRef = this.dialog.open(ConfigDialogArrayComponent, {
      width: '750px',
      data: { header: 'config.dialog.array.header', key: 'config.key.' + row.key, items: values }
    });

    this.configDialogArrayRef.afterClosed().subscribe(items => {
      if (!items) { return; }

      this.updateInDb(row, items);
    });
  }

  private editString(row, value: string) {

    this.configDialogStringRef = this.dialog.open(ConfigDialogStringComponent, {
      width: '500px',
      data: { header: 'config.dialog.string.header', key: 'config.key.' + row.key, item: value }
    });

    this.configDialogStringRef.afterClosed().subscribe(item => {
      if (!item) { return; }

      this.updateInDb(row, item);
    });
  }

  private updateInDb(row: any, items: any) {
    this.configService.deleteAllType(row.key).then(() => {
      this.configService.update(row.key, items);
      row.source = this.dbSource;
    });
  }

  private saveFileSourcesInDb() {
    this.table.forEach(row => {
      if (row.source === this.fileSource) { this.updateInDb(row, row.list); }
    });
  }

  private setEntryDescriptions() {
    for (const row of this.table) {
      const key = 'config.key.' + row.key;
      row.description = this.translate.instant(key);
    }

    // this.table.sort((a, b) => {
    //   if (a.description < b.description) { return -1; }
    //   if (a.description > b.description) { return +1; }
    //   return 0;
    // });
  }
}
