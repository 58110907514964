import { PdfList } from './../../../print/pdf-list';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Breakpoints } from '@angular/cdk/layout';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DataService } from 'src/app/shared/services/data.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, takeUntil, Subject } from 'rxjs';
import { AdminStoreService } from 'src/app/admin/services/admin-store.service';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { ConfigService } from 'src/app/admin/services/config.service';
import { AppUser } from 'src/app/domain/appuser';
import { FamilyMember } from 'src/app/domain/familymember';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { ColumnDialogComponent } from 'src/app/shared/components/column-dialog/column-dialog.component';
import { FileUtil } from 'src/app/shared/file.util';
import { Config, ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { FamilyStoreService } from '../../services/family-store.service';
import { DatePipe } from "@angular/common";

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'family-list',
  templateUrl: './family-list.component.html',
  styleUrls: ['./family-list.component.scss']
})
export class FamilyListComponent implements OnInit, OnDestroy, AfterViewInit {

  appUser$: Observable<AppUser> = this.adminStore.appUser$;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('clickMail') clickMail;

  filteredFamily = new MatTableDataSource<FamilyMember>();
  family: FamilyMember[] = [];
  filterString$: Observable<string> = this.menuStore.filterString$;
  option$: Observable<string> = this.menuStore.option$;
  config$: Observable<Config> = this.configStore.config$;
  filterString: string;
  tableName = 'family';
  familyAllColumns: string[];
  subscriptions = new Subscription();
  ownersOnly = false;

  familyList = 'family.list.options.print';
  familyEmail = 'family.list.options.email';
  exportFamily = 'family.list.options.export';
  importFamily = 'family.list.options.import';
  configColumns = 'family.list.options.configcolumns';
  filterowners = 'family.list.options.filterowners';

  locale: string;

  displayedColumns: string[];

  displayNameMap = new Map([[Breakpoints.XSmall, 'xs'], [Breakpoints.Small, 'sm'], [Breakpoints.Medium, 'md'], [Breakpoints.Large, 'lg'], [Breakpoints.XLarge, 'xl']]);

  currentScreenSize: string = 'xs';
  destroyed = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private router: Router,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private fileUtil: FileUtil,
    private translate: TranslateService,
    private familyStore: FamilyStoreService,
    private menuStore: MenuStoreService,
    private configStore: ConfigStoreService,
    private adminStore: AdminStoreService,
    private dataService: DataService,
    private pdfList: PdfList,
  ) { }

  ngOnInit() {
    this.familyAllColumns = this.configStore.config.familyAllColumns;
    this.locale = this.translate.currentLang;

    this.subscriptions.add(this.config$.subscribe(config => {
      if (config.familyDisplayedColumns) {
        this.displayedColumns = config.familyDisplayedColumns;
        if (!this.displayedColumns.includes('viewtree')) { this.displayedColumns.unshift('viewtree'); }
      }
    }));

    this.subscriptions.add(
      this.familyStore.familyExpanded$.subscribe((family: FamilyMember[]) => {
        this.family = family;
        this.filteredFamily.data = family;
        // console.log('Family: ', this.filteredFamily.data);
      })
    );

    this.subscriptions.add(this.filterString$.subscribe(searchString => this.filter(searchString)));

    this.subscriptions.add(
      this.option$.subscribe(option => {
        if (option === this.familyList) { this.printFamilyList(); }
        if (option === this.filterowners) { this.toggleFilterOwners(); }
        if (option === this.familyEmail) { this.emailToFamilyMembers(); }
        if (option === this.configColumns) { this.configureColumns(); }
        if (option === this.exportFamily) { this.fileUtil.exportFamily(this.tableName, this.filteredFamily.data, this.family); }
        if (option === this.importFamily) { this.familyStore.importFamilyFile(this.familyAllColumns); }
      }));


  }

  toggleFilterOwners() {
    this.ownersOnly = !this.ownersOnly;

    if (this.ownersOnly) {
      const owners: FamilyMember[] = [];
      for (const member of this.family) {
        if (member.part && member.part > 0) owners.push(member);
      }
      this.filteredFamily.data = owners;
    } else {
      this.filteredFamily.data = this.family;
    }

    if (this.filteredFamily.paginator) this.filteredFamily.paginator.firstPage();
  }

  ngAfterViewInit(): void {
    const options: { label: string, icon: string }[] = [];
    if (this.authorizationService.hasRights(this.tableName, 'canRead')) {
      options.push({ label: this.filterowners, icon: 'filter_alt' });
      options.push({ label: this.familyList, icon: 'list_alt' });
      options.push({ label: this.exportFamily, icon: 'cloud_download' });
    }
    if (this.authorizationService.hasRights(this.tableName, 'canCreate')) {
      options.push({ label: this.importFamily, icon: 'cloud_upload' });
    }
    if (this.authorizationService.hasRights('config', 'canUpdate')) {
      // options.push({ label: this.calculateGenerations, icon: 'calculate' });
      options.push({ label: this.familyEmail, icon: 'email' });
      options.push({ label: this.configColumns, icon: 'settings_applications' });
    }

    this.menuStore.componentInfo = {
      name: this.tableName,
      form: false,
      options: options,
      showFilter: true
    };

    this.filteredFamily.paginator = this.paginator;
    this.filteredFamily.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyed.next();
    this.destroyed.complete();
  }

  private filter(query: string) {
    // console.info('Family List filter: ',query);
    this.filterString = query;
    if (this.filterString) {
      this.filteredFamily.filter = query.trim().toLowerCase();
    } else {
      this.filteredFamily.filter = '';
    }
    if (this.filteredFamily.paginator) { this.filteredFamily.paginator.firstPage(); }
  }

  private emailToFamilyMembers() {
    const list: string[] = this.filteredFamily.filteredData.map((element: FamilyMember) => {
      if (element.email) { return element.email; }
    });
    // console.log('List of emails: ', list.join());
    const node = document.createElement('a');
    node.setAttribute('href', 'mailto:hans.daniel.suter@gmail.com?subject=Leuenegg&bcc=' + list.join());
    node.click();
  }

  getTotalParts() {
    let total = 0;
    if (this.filteredFamily) {
      for (const member of this.filteredFamily.filteredData) {
        if (member.part) { if (!isNaN(member.part)) { total += member.part; } }
      }
    }
    // console.log('Summ of parts: ',total);
    return total;
  }

  viewDetails(row) {
    this.familyStore.setCurrentRecord(row);
    this.router.navigate(['/family/']);
  }

  viewTree(row) {
    this.familyStore.setCurrentRecord(row);
    this.router.navigate(['/family-tree/' + row.key]);
  }

  private configureColumns() {
    const familyDisplayedColumns = this.configStore.config.familyDisplayedColumns;

    const columnsDialogRef = this.dialog.open(ColumnDialogComponent, {
      width: '700px',
      data: {
        domain: 'family',
        header: this.configColumns,
        allColumns: this.familyAllColumns,
        currentColumns: familyDisplayedColumns,
      }
    });

    columnsDialogRef.afterClosed().subscribe(columns => {
      if (!columns) { return; }

      this.configService.deleteAllType('familyDisplayedColumns').then(() =>
        this.configService.update('familyDisplayedColumns', columns)
      );
    });
  }


  private printFamilyList() {
    const excludeList: string[] = ['fatherId', 'motherId', 'partnerId', 'childrenIds', 'key','viewtree'];
    const dateList: string[] = ['createdOn', 'modifiedOn', 'dateOfBirth', 'dateOfDeath'];
    const datePipe = new DatePipe(this.translate.currentLang);

    // TODO: imageUrl and boolean
    const baseName = 'family';
    let header = 'Liste der Erben ';
    if (this.filterString) {
      header = header.concat(' avec filtre: ' + this.filterString);
    }
    const headerRow = ['N°'];
    const columns: string[] = [];
    this.configStore.config.familyDisplayedColumns.forEach(column=>{
      if(!excludeList.includes(column)) columns.push(column)
    });
    columns.forEach(column => headerRow.push(this.translate.instant('family.list.'+column.toLocaleLowerCase())));
    const rows = [];

    let nr = 0;
    const sortedList = this.filteredFamily.sortData(this.filteredFamily.filteredData,this.sort);
    sortedList.forEach(record => {
      nr++;
      const line = [];
      line.push(nr);
      columns.forEach(column => {
        let data = ' ';
        if (column === 'photo' && record['imageUrl']) data='X';
        if (record[column]) {
          data = dateList.includes(column) ? datePipe.transform(record[column], 'shortDate') : record[column];
          // console.log('Column: ',column,' Data:', data);
        }
        line.push(data);
      });
      // let status = '';
      // if (element['hasBadge'] === 'hasBadge') { status = 'oui'; }
      // line.push(status);

      rows.push(line);
    });
    this.pdfList.generate('download', baseName, header, headerRow, rows);
  }
}
