import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent  {

  constructor(
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      name: string,
      version: string,
      author: string,
      database: string,
      storage: string,
      date: string
    }) {}

  close(): void {
    this.dialogRef.close();
  }
}