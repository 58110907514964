import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'home-temp-gauge',
  templateUrl: './home-temp-gauge.component.html',
  styleUrls: ['./home-temp-gauge.component.scss']
})
export class HomeTempGaugeComponent implements OnInit, OnDestroy {
  @Input() deviceName: string;
  @Input() temperature: number;
  @Input() humidity: number;
  @Input() gaugeNumber: number;

  chart: Highcharts.Chart;
  subscriptions = new Subscription();

  constructor(private translate: TranslateService) { }


  containers = ['tempGauge0', 'tempGauge1', 'tempGauge2', 'tempGauge3'];

  public options: any = {
    lang: { thousandsSep: '\u0020' },
    chart: {
      type: 'gauge',
      plotBackgroundColor: '#000',
      backgroundColor: '#000',
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    size: '100%',
    title: {
      text: 'Speedometer',
      style: { color: '#AAA', fontSize: '18px' }
    },
    subtitle: {
      style: { color: '#AAA', fontSize: '12px' }
    },

    pane: {
      size: '85%',
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '109%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '107%'
      }, {
        // default background
      }, {
        backgroundColor: '#AAA',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },
    credits: { enabled: false },

    // the value axis
    yAxis: {
      min: 10,
      max: 30,

      minorTickInterval: 0.25, // 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: { step: 1, rotation: 'auto' },
      title: { text: '°C' },
      plotBands: [{
        from: 10, to: 17, color: '#55BF3B' // green
      }, {
        from: 17, to: 23, color: '#DDDF0D' // yellow
      }, {
        from: 23, to: 30, color: '#DF5353' // red
      }],

    },
    series: [{
      name: 'Temp',
      data: [],
      tooltip: { enabled: false },
      dataLabels: { format: '{point.y:.1f} °C' },
    }]

  };


  ngOnInit(): void {
    this.subscriptions.add(this.translate.onLangChange.subscribe((event) => {
      // console.log('New language is: ', event);
      this.highchartLocalize();
      setTimeout(() => this.chart.redraw());
    }
    ));

    this.options.series[0].data[0] = this.temperature;
    // console.log('Container: ', this.containers[this.gaugeNumber]);
    this.chart = Highcharts.chart(this.containers[this.gaugeNumber], this.options);
    this.highchartLocalize();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private highchartLocalize() {
    this.options.series[0].name = this.translate.instant('home.overview.temperature');
    // console.log(this.options.series[0].name);
    this.chart.setTitle({ text: this.deviceName, });
    this.chart.setSubtitle({
      text: this.translate.instant('home.overview.humidity') + ': ' + this.humidity + '%'
    });
    this.chart.reflow();
  }
}
