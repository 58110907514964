import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { Option } from 'src/app/domain/option';

export interface ComponentInfo {
  name: string;
  form: boolean;
  showFilter?: boolean;
  showEditButtons?: boolean;
  canUpdate?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
  options?: Option[];
}

export enum EditAction {
  DELETE = 'DELETE',
  UNDO = 'UNDO',
  SAVE = 'SAVE',
  MODIFY = 'MODIFY',
  DELIMG = 'DELIMG',
  COPY = 'COPY',
  ADD = 'ADD',
}

export enum EditState {
  READONLY = 'READONLY',
  UNTOUCHED = 'UNTOUCHED',
  INVALID = 'INVALID',
  VALID = 'VALID',
}
export interface EditButtonsState {
  disCopy: boolean;
  disMod: boolean;
  disSave: boolean;
  disDel: boolean;
  disCan: boolean;
}
@Injectable({ providedIn: 'root' })

export class MenuStoreService {
  private componentInfoSubject = new BehaviorSubject<ComponentInfo>({
    name: '',
    canUpdate: false,
    canCreate: false,
    canDelete: false,
    form: false,
    options: [],
    showFilter: false
  });
  componentInfo$: Observable<ComponentInfo> = this.componentInfoSubject.asObservable();

  private sendMail = new BehaviorSubject<boolean>(false);
  sendMail$: Observable<boolean> = this.sendMail.asObservable();

  private editActionSubject = new Subject<EditAction>();
  editAction$: Observable<EditAction> = this.editActionSubject.asObservable();

  private editButtonsStateSubject = new BehaviorSubject<EditButtonsState>({
    disCopy: true,
    disMod: true,
    disSave: true,
    disDel: true,
    disCan: true
  });
  editButtonsState$: Observable<EditButtonsState> = this.editButtonsStateSubject.asObservable();

  private editStateSubject = new BehaviorSubject<EditState>(EditState.READONLY);
  editState$: Observable<EditState> = this.editStateSubject.asObservable();

  private optionSubject = new Subject<string>();
  option$: Observable<string> = this.optionSubject.asObservable();

  private filterStringSubject = new BehaviorSubject<string>('');
  filterString$: Observable<string> = this.filterStringSubject.asObservable();

  constructor(private authorizationService: AuthorizationService) { }

  public get componentInfo(): ComponentInfo {
    return this.componentInfoSubject.getValue();
  }

  public set componentInfo(info: ComponentInfo) {
    info.canUpdate = info.canUpdate? info.canUpdate : this.authorizationService.hasRights(info.name, 'canUpdate'),
    info.canCreate = info.canCreate? info.canCreate :this.authorizationService.hasRights(info.name, 'canCreate'),
    info.canDelete = info.canDelete? info.canDelete :this.authorizationService.hasRights(info.name, 'canDelete'),
    this.componentInfoSubject.next(info);
    // console.info('Compoent info: ', info);
  }

  public set editAction(action: EditAction) {
    this.editActionSubject.next(action);
    // console.info('Edit Action: ', action);
  }

  public get editState(): EditState {
    return this.editStateSubject.getValue();
  }

  public set option(option: string) {
    this.optionSubject.next(option);
  }

  public set filterString(searchString: string) {
    this.filterStringSubject.next(searchString);
  }

  public get filterString() {
    return this.filterStringSubject.getValue();
  }

  public set editState(state: EditState) {
    this.editStateSubject.next(state);


    let editButtonsState: EditButtonsState;
    switch (state) {
      case EditState.READONLY: editButtonsState = { disCopy: false, disMod: false, disCan: false, disDel: false, disSave: true }
        break;
      case EditState.UNTOUCHED: editButtonsState = { disCopy: true, disMod: true, disCan: false, disDel: true, disSave: true }
        break;
      case EditState.INVALID: editButtonsState = { disCopy: true, disMod: true, disCan: false, disDel: true, disSave: true }
        break;
      case EditState.VALID: editButtonsState = { disCopy: true, disMod: true, disCan: false, disDel: true, disSave: false }
    }
    this.editButtonsStateSubject.next(editButtonsState);
    // console.info('Buttons state: ', editButtonsState);
  }

  public get editButtonsState(): EditButtonsState {
    return this.editButtonsStateSubject.getValue();
  }
}

