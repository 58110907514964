import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DividendDialogComponent } from './components/dividend-dialog/dividend-dialog.component';
import { PerformanceChartComponent } from './components/performance-chart/performance-chart.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PositionListComponent } from './components/position-list/position-list.component';
import { SecurityDialogComponent } from './components/security-dialog/security-dialog.component';
import { SecurityListComponent } from './components/security-list/security-list.component';
import { TransactionDialogComponent } from './components/transaction-dialog/transaction-dialog.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { ValidateIsinDirective } from './directives/validate-isin.directive';
import { PortfolioAuthGuardService } from './services/portfolio-auth-guard.service';



@NgModule({
  declarations: [
    PerformanceChartComponent,
    SecurityListComponent,
    PositionListComponent,
    PortfolioComponent,
    TransactionDialogComponent,
    ValidateIsinDirective,
    TransactionListComponent,
    DividendDialogComponent,
    SecurityDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'portfolio', component: PortfolioComponent, canActivate: [PortfolioAuthGuardService]},
      { path: 'position-list', component: PositionListComponent, canActivate: [PortfolioAuthGuardService]},
      { path: 'security-list', component: SecurityListComponent, canActivate: [PortfolioAuthGuardService]},
      { path: 'transaction-list', component: TransactionListComponent, canActivate: [PortfolioAuthGuardService]},
    ]),
  ]
})
export class PortfolioModule { }
