import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';
import { AppUser } from '../domain/appuser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private db: AngularFireDatabase) { }

  save(user: any) {
    const appUser: AppUser = {
      key: user.uid,
      email: user.email,
      name: user.displayName,
      role: null,
      creationDateTime: Date.now(),
      locations: []
    };

    this.db.object('/users/' + user.uid).update(appUser).catch(error => console.log(error));
    return appUser;

  }

  update(uid: string, user: {}) {
    this.db.object('/users/' + uid).update(user);
  }

  users() {
    return this.db.list('/users')
      .snapshotChanges()
      .pipe(map(users => {
        return users.map(a => {
          const data = a.payload.val();
          const key = a.payload.key;
          return { key, ...(data as {}) };  // or {key, data} in case data is not an Objectj
        });
      }));
  }

  permissions() {
    return this.db.list('/permissions/').valueChanges();
  }

  get(uid: string): AngularFireObject<AppUser> {
    return this.db.object('/users/' + uid);
  }
}
