<div class="mat-elevation-z8 container">
  <mat-table [dataSource]="dataSource" multiTemplateDataRows=false>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> {{'user-role.list.name' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> {{'user-role.list.mail' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="role" *ngFor="let role of roles">
      <mat-header-cell *matHeaderCellDef> {{role}} </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <mat-checkbox (change)="userChanged(user, role)" [(ngModel)]="user[role]" [checked]="user[role]"
          [disabled]="!hasWriteRights">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="15" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons></mat-paginator>
</div>