<h1 mat-dialog-title>{{title | translate}}</h1>
<div mat-dialog-content>
  <button mat-raised-button color="basic" (click)="isFileSelected = false; fileInput.click()">
    <mat-icon>create_new_folder</mat-icon>
    <span> {{'common.button.selectfile' | translate}}</span>
    <input #fileInput type="file" (change)="fileSelected($event.target.files)" style="display:none;" accept="*.xlsx" />
  </button>
  <mat-label class="filename">{{fileName}}</mat-label>
  <hr />
  <mat-table *ngIf="isFileSelected" matSort [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Select Column -->
    <ng-container matColumnDef="use">
      <mat-header-cell *matHeaderCellDef> {{'family.import.select' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox (change)="selectionChanged(element)" [(ngModel)]="element.selected" [checked]="element.selected">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Excel Column Name Column -->
    <ng-container matColumnDef="xlsxcol">
      <mat-header-cell *matHeaderCellDef> {{'family.import.xlsxcol' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.xlsxcol}} </mat-cell>
    </ng-container>

    <!-- App Column Name Column -->
    <ng-container matColumnDef="appcol">
      <mat-header-cell *matHeaderCellDef> {{'family.import.appcol' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.appcol}} </mat-cell>
    </ng-container>

    <!-- App Column Name Select Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef> {{'family.import.select' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <!-- <mat-form-field> -->
          <!-- <mat-label>{{'family.import.appcolsel' | translate}} </mat-label> -->
          <mat-select [(ngModel)]="element.appcol" [value]="element.appcol" (selectionChange)=headerSelected(element)>
            <mat-option></mat-option>
            <mat-option *ngFor="let header of appHeadersTranslated" [value]="header['currentLang']"> {{header['currentLang']}} </mat-option>
          </mat-select>
        <!-- </mat-form-field> -->
      </mat-cell>
    </ng-container>

    <!-- Levenshtein Column -->
    <ng-container matColumnDef="dist">
      <mat-header-cell *matHeaderCellDef> {{'family.import.dist' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.dist}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>
  </mat-table>
  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div mat-dialog-actions>
  <button [disabled]="!isFileSelected" mat-raised-button (click)="save(true)">
    <mat-icon>cloud_upload</mat-icon>
    <span>{{'common.button.append' | translate}}</span>
  </button>
  <button [disabled]="!isFileSelected" mat-raised-button (click)="save(false)">
    <mat-icon>restore_from_trash</mat-icon>
    <span>{{'common.button.replace' | translate}}</span>
  </button>
  <button mat-raised-button (click)="cancel()" cdkFocusInitial>
    <mat-icon>cancel</mat-icon>
    <span>{{'common.button.cancel' | translate}}</span>
  </button>
</div>