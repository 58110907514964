import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { ConfigStoreService } from "src/app/shared/services/config-store.service";
import { Rate } from './../../../domain/rate';
import { FxRatesService } from './../../services/fxrates.service';
declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'fxrate-chart',
  templateUrl: './fxrate-chart.component.html',
  styleUrls: ['./fxrate-chart.component.scss']
})

export class FxrateChartComponent implements OnInit, OnDestroy {

  private baseCcy: string;
  private currencies: string[];
  private selectedCurrency: string;
  private periodInDays = 2 * 365;
  private toDate = Date.now();
  private fromDate = (new Date(this.toDate - 24 * 3600000 * this.periodInDays)).getTime();
  private rates: Rate[][];

  private subscriptions = new Subscription();
  private tableName = 'fxrates';
  private chart: Highcharts.Chart;

  private container = 'fxRateLineChart';
  private colors = ['#fff700', '#5DADEC', '#FF7A00', '#A7F432', '#FFAE42', '#FF3855', '#E936A7'];
  private yMin = 0.75;
  private yMax = 2.75;
  private yTickInterval = 0.25;

  private options: any = {
    lang: { thousandsSep: '\u0020' },
    chart: {
      zoomType: 'x',
      type: 'line',
      backgroundColor: 'rgba(66,66,66,1.0)',
      style: { fontFamily: 'Roboto', color: '#fff' },
    },
    title: { style: { color: '#fff', fontSize: '20px' } },
    subtitle: { style: { color: '#fff', fontSize: '15px' } },
    credits: { enabled: false },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter() { return Highcharts.dateFormat('%e. %b. %Y', this.value); },
        step: 1,
        style: { color: '#fff', fontSize: '14px', }
      }
    },
    yAxis: {
      opposite: true,  // axis on the right side
      title: { style: { color: '#fff' } },
      labels: {
        format: '{value}',
        formatter() { return Highcharts.numberFormat(this.value, 2); },
        style: { color: '#fff', fontSize: '14px' },
        // plotBands: [{ color: 'rgba(252,255,197,0.25)', from: 1.0, to: 1.2 }]
      },
    },
    legend: {
      enabled: true,
      itemStyle: { color: '#fff', fontSize: '14px', fontWeight: 'bold' }
    },

    plotOptions: {
      series: {
        events: {
          legendItemClick: (event) => { this.switchBase(event); return false; }
        }
      },
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          ]
        },
        marker: { radius: 2 },
        lineWidth: 1,
        states: { hover: { lineWidth: 1 } },
        threshold: null
      }
    },
    series: []
  };

  constructor(
    private fxRatesService: FxRatesService,
    private translate: TranslateService,
    private menuStore: MenuStoreService,
    private configStore: ConfigStoreService
  ) { }

  ngOnInit() {
    this.selectedCurrency = this.configStore.config.baseCcy;
    this.currencies = this.configStore.config.currencies;
    this.fxRatesService.getAllFXHistoricalRates(this.selectedCurrency, this.currencies, this.fromDate, this.toDate);

    this.subscriptions.add(this.fxRatesService.historicalRates.subscribe(rates => {
      // console.log('New rates');
      this.rates = rates;
      this.createSeries();
      this.chart = Highcharts.chart(this.container, this.options);
      this.highchartLocalize();
    }));

    this.subscriptions.add(this.translate.onLangChange.subscribe((event) => {
      // console.log('New language is: ', event);
      if (this.chart) {
        this.highchartLocalize();
        setTimeout(() => this.chart.redraw());
      }
    }
    ));
  }

  ngAfterViewInit() {
    const options: { label: string, icon: string }[] = [];

    this.menuStore.componentInfo = {
      canUpdate: false,
      canCreate: false,
      canDelete: false,
      name: this.tableName,
      form: false,
      options,
      showFilter: false
    };

  }

  private createSeries() {
    let curveIndex = 0;
    this.options.series = [];
    this.options.yAxis.title.text = this.selectedCurrency;

    for (const fxRate of this.rates) {
      const series = [];

      for (const ccyRate of fxRate) {
        // 'circle', 'square','diamond', 'triangle' and 'triangle-down'.
        const dataPoint = { x: ccyRate.date.getTime(), y: ccyRate.rate };
        series.push(dataPoint);
      }

      this.options.series.push({
        data: series,
        name: this.currencies[curveIndex],
        color: this.colors[curveIndex],
        marker: { enabled: true, }
      });

      curveIndex++;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private highchartLocalize() {
    // console.log('Chart: ', this.chart);
    this.chart.setTitle({ text: this.translate.instant('fxrates.chart.title') + ' ' + this.selectedCurrency });
    this.chart.setSubtitle({ text: this.translate.instant('fxrates.chart.subtitle') });
    // console.log('Options: ', this.options);
  }

  switchBase(event: any) {
    this.selectedCurrency = event.target.name;
    this.options.yAxis.title.text = this.options.yAxis.title.text;
    // console.log('Options: ', this.options);
    this.fxRatesService.getAllFXHistoricalRates(this.selectedCurrency, this.currencies, this.fromDate, this.toDate);
  }
}
