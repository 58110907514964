import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/admin/services/authorization.service';
import { FamilyMember } from 'src/app/domain/familymember';
import { MenuStoreService } from 'src/app/menu/services/menu-store.service';
import { FileUtil } from 'src/app/shared/file.util';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  filteredFamily$ = new MatTableDataSource<FamilyMember>();
  filterString: string;
  tableName = 'family';
  filterString$: Observable<string> = this.menuStore.filterString$;
  option$: Observable<string> = this.menuStore.option$;
  subscription = new Subscription();

  familyList = 'family.list.options.print';
  exportFamily = 'family.list.options.export';

  displayedColumns = ['lastName', 'firstName', 'modifiedby', 'modifiedon', 'createdby', 'createdon'];

  constructor(
    private menuStore: MenuStoreService,
    private authorizationService: AuthorizationService,
    private fileUtil: FileUtil,
    private translate: TranslateService,
    private dataService: DataService,
  ) { }

  ngOnInit() {

    this.subscription.add(
      this.dataService.readAll<FamilyMember>(this.tableName).subscribe((family: FamilyMember[]) => {
        this.filteredFamily$.data = family;
      })
    );

    this.subscription.add(this.filterString$.subscribe(filterString => this.filter(filterString)));

    this.subscription.add(
      this.option$.subscribe(option => {
        if (option === this.familyList) { this.printAuditList(); }
        if (option === this.exportFamily) { this.fileUtil.export(this.tableName, this.filteredFamily$.data); }
      }));

  }

  ngAfterViewInit(): void {
    const options: { label: string, icon: string }[] = [];
    if (this.authorizationService.hasRights(this.tableName, 'canRead')) {
      options.push({ label: this.familyList, icon: 'list_alt' });
      options.push({ label: this.exportFamily, icon: 'cloud_download' });
    }

    this.menuStore.componentInfo = {
      name: this.tableName,
      form: false,
      options: options,
      showFilter: true
    };

    this.filteredFamily$.paginator = this.paginator;
    this.filteredFamily$.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private filter(query: string) {
    console.trace('Query string: ', query);

    this.filterString = query;
    if (this.filterString) {
      this.filteredFamily$.filter = query.trim().toLowerCase();
    } else {
      this.filteredFamily$.filter = '';
    }
    if (this.filteredFamily$.paginator) { this.filteredFamily$.paginator.firstPage(); }
  }

  viewDetails(row) {
    // this.familyService.setCurrentRecord(row);
    // this.router.navigate(['/family/']);
  }

  private printAuditList() {

    // TODO: imageUrl and boolean
    const baseName = 'family';
    let header = 'Audit Liste ';
    if (this.filterString) {
      header = header.concat(' avec filtre: ' + this.filterString);
    }
    const headerRow = ['N°'];
    const columns = this.displayedColumns;
    columns.forEach(column => headerRow.push(this.translate.instant(column)));
    const rows = [];

    let nr = 0;
    this.filteredFamily$.filteredData.forEach(record => {
      nr++;
      const line = [];
      line.push(nr);
      columns.forEach(column => line.push(record[column]));
      rows.push(line);
    });
    // this.pdfGenerator.generateList(baseName, header, headerRow, rows);
  }
}
