<footer *ngIf="entity">
    <div>
        <p>
            <ng-container *ngIf="createdBy">
                <span class="label">
                    {{'common.form.createdby' | translate}}:
                </span>
                <span class="content">
                    {{createdBy}}
                </span>
                <span class="label">
                    {{'common.form.createdon' | translate}}:
                </span>
                <span class="content">
                    {{createdOn | date :'medium'}}
                </span>
            </ng-container>
            <ng-container *ngIf="modifiedBy">
                <span class="label">
                    {{'common.form.modifiedby' | translate}}:
                </span>
                <span class="content">
                    {{modifiedBy}}
                </span>
                <span class="label">
                    {{'common.form.modifiedon' | translate}}:
                </span>
                <span class="content">
                    {{modifiedOn | date :'medium'}}
                </span>
            </ng-container>
            <ng-container *ngIf="entity.key">
                <span class="label">
                     {{'common.form.key' | translate}}:
                </span>
                <span class="content">
                    {{entity.key}}
                </span>
            </ng-container>
        </p>
    </div>
</footer>