import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PositionSecurity } from 'src/app/domain/position-security';
import { Security } from 'src/app/domain/security';


export interface Chart {
  positions: PositionSecurity[],
  chartType: string,
  option: string
};

@Injectable({ providedIn: 'root' })
export class PortfolioStoreService {
  private selectedPositionsSubject = new BehaviorSubject<PositionSecurity[]>([]);
  private allPositionsSubject = new BehaviorSubject<PositionSecurity[]>([]);
  private selectedSecuritiesSubject = new BehaviorSubject<Security[]>([]);
  private chartSubject = new BehaviorSubject<Chart>({
    positions: [],
    chartType: '',
    option: ''
  });

  public selectedPositions$: Observable<PositionSecurity[]> = this.selectedPositionsSubject.asObservable();
  public allPositions$: Observable<PositionSecurity[]> = this.allPositionsSubject.asObservable();;
  public selectedSecurities$: Observable<Security[]> = this.selectedSecuritiesSubject.asObservable();;
  public chart$: Observable<Chart> = this.chartSubject.asObservable();;

  constructor() { }

  public get selectedPositions(): PositionSecurity[] {
    return this.selectedPositionsSubject.getValue();
  }
  public set selectedPositions(value: PositionSecurity[]) {
    this.selectedPositionsSubject.next(value);
  }

  public get allPositions(): PositionSecurity[] {
    return this.allPositionsSubject.getValue();
  }
  public set allPositions(value: PositionSecurity[]) {
    this.allPositionsSubject.next(value);
  }

  public get selectedSecurities(): Security[] {
    return this.selectedSecuritiesSubject.getValue();
  }
  public set selectedSecurities(value: Security[]) {
    this.selectedSecuritiesSubject.next(value);
  }

  public get chart(): Chart {
    return this.chartSubject.getValue();
  }
  public set chart(value: Chart) {
    this.chartSubject.next(value);
  }
}
