<mat-action-list>
    <button (click)="routeTo('/')" mat-button matTooltip="{{'config.modules.weather.tooltip' | translate}}"
        [matTooltipShowDelay]="1000" matTooltipPosition="right">
        <mat-icon>wb_sunny</mat-icon>
    </button>

    <button (click)="routeTo('fxrate-chart')" mat-button
        matTooltip="{{'config.modules.fxrates.tooltip' | translate}}" [matTooltipShowDelay]="1000"
        matTooltipPosition="right">
        <mat-icon>attach_money</mat-icon>
    </button>

    <ng-container *ngIf="(appUser$ | async)">
        <button *ifHasRight="'canRead' in 'family'" (click)="routeTo('family-list')" mat-button
            matTooltip="{{'config.modules.family.tooltip' | translate}}" [matTooltipShowDelay]="1000"
            matTooltipPosition="right">
            <mat-icon>wc</mat-icon>
        </button>

        <!-- <button *ifHasRight="'canRead' in 'portfolio'" (click)="routeTo('portfolio')" mat-button
            matTooltip="{{'config.modules.portfolio.tooltip' | translate}}" [matTooltipShowDelay]="1000"
            matTooltipPosition="right">
            <mat-icon>local_atm</mat-icon>
        </button> -->
        <!-- <button *ifHasRight="'canRead' in 'home'" (click)="routeTo('home')" mat-button
            matTooltip="{{'config.modules.home.tooltip' | translate}}" [matTooltipShowDelay]="1000"
            matTooltipPosition="right">
            <mat-icon>home</mat-icon>
        </button> -->
        <button *ifHasRight="'canRead' in 'admin'" (click)="routeTo('audit-list')" mat-button
            matTooltip="{{'config.modules.audit.tooltip' | translate}}" [matTooltipShowDelay]="1000"
            matTooltipPosition="right">
            <mat-icon>list</mat-icon>
        </button>
    </ng-container>

</mat-action-list>