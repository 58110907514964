import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { PositionSecurity } from 'src/app/domain/position-security';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { TransactionType } from 'src/app/domain/transactiontypes';
import { FxRatesService } from 'src/app/fxrates/services/fxrates.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfigStoreService, Config } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { TransactionDialogComponent } from '../transaction-dialog/transaction-dialog.component';
import { SecurityType } from './../../../domain/securitytypes';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dividend-dialog',
  templateUrl: './dividend-dialog.component.html',
  styleUrls: ['./dividend-dialog.component.scss']
})
export class DividendDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('form', { static: false }) form: UntypedFormGroup;

  config$: Observable<Config> = this.configStore.config$;
  subscriptions = new Subscription();
  header: string;
  enableDeleteButton: boolean;
  positions: PositionSecurity[];
  tableName = 'portfolio';
  transaction: TransactionSecurity;
  trxDate: Date;
  yesNoDialogRef: any;
  baseCcy: string;
  SWISSTAXES = 0.35;

  constructor(
    public dialogRef: MatDialogRef<TransactionDialogComponent>,
    private dataService: DataService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private fxRatesService: FxRatesService,
    private configStore: ConfigStoreService,
    @Inject(MAT_DIALOG_DATA) public data: TransactionSecurity
  ) {
    this.transaction = data;
    this.transaction.transactionType = TransactionType.DIVIDEND;
    this.transaction.comment = this.translate.instant('transaction.form.dividend.dividendpayment');
    if (this.transaction.transactionDate) {
      this.enableDeleteButton = true;
      this.trxDate = new Date(this.transaction.transactionDate)
    } else {
      this.enableDeleteButton = false;
      this.trxDate = new Date(Date.now());
    }
    this.baseCcy = this.configStore.config.baseCcy; // default: base currency
    if (!this.transaction.currency) this.transaction.currency = this.baseCcy;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.readAllSortBy(this.tableName + '/positions', 'key')
        .subscribe((positions: PositionSecurity[]) => {
          this.positions = positions;
        })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.form.valueChanges.subscribe((result) => {
      if (!this.form.pristine) {
        console.log('Form: ', this.form);
        let unitPrice: number;
        let units: number;
        let dividends: number;
        let taxes = 0;

        if (this.form.controls.units.value) units = Number(this.form.controls.units.value);
        if (this.form.controls.unitPrice.value) unitPrice = Number(this.form.controls.unitPrice.value);
        if (this.form.controls.taxes.value) taxes = Number(this.form.controls.taxes.value);
        if (units && unitPrice) dividends = units * unitPrice;

        if (dividends) {
          if (this.form.controls.currency.value === this.baseCcy) {
            this.form.controls.dividendsInBaseCcy.setValue(dividends, { emitEvent: false });
            taxes = this.SWISSTAXES * dividends;
            console.log('Taxes: ',taxes);
            const totalCost = dividends - taxes;
            this.form.controls.taxes.setValue(taxes, { emitEvent: false });
            this.form.controls.totalCost.setValue(totalCost, { emitEvent: false });
          } else {
            this.subscriptions.add(
              this.fxRatesService
                .getFXRate(this.form.controls.currency.value, this.baseCcy, this.trxDate.getTime())
                .subscribe((rate) => {
                  // console.log('Exchange rate: ', +rate);
                  const dividendsInBaseCcy = +rate * dividends;
                  taxes = this.SWISSTAXES * dividendsInBaseCcy;
                  this.form.controls.dividendsInBaseCcy.setValue(dividendsInBaseCcy, { emitEvent: false });
                  const totalCost = +rate * dividends - taxes;
                  this.form.controls.taxes.setValue(taxes, { emitEvent: false });
                  this.form.controls.totalCost.setValue(totalCost, { emitEvent: false });
                })
                );
              }
            } else {
              this.form.controls.dividendsInBaseCcy.setValue(null, { emitEvent: false });
              this.form.controls.totalCost.setValue(null, { emitEvent: false });
              this.form.controls.taxes.setValue(null, { emitEvent: false });
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateLinkedFields(symbol) {
    const position = this.positions.find(pos => pos.symbol === symbol);
    this.transaction.units = position.units;
    this.transaction.company = position.company;
  }

  deleteTransaction() {
    this.yesNoDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '20vw',
      data: { title: 'common.dialog.confirm.delete.title', message: 'common.dialog.confirm.delete.message' }
    });

    this.yesNoDialogRef.afterClosed().subscribe(remove => {
      if (remove) this.dialogRef.close('delete');
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  proceed(): void {
    this.subscriptions.unsubscribe();
    this.transaction.dividends = Number(this.form.controls.dividends.value); // convert to number
    this.transaction.dividendsInBaseCcy = Number(this.form.controls.dividendsInBaseCcy.value); // convert to number
    this.transaction.taxes = Number(this.form.controls.taxes.value); // convert to number
    this.transaction.unitPrice = Number(this.form.controls.unitPrice.value); // convert to number
    this.transaction.units = Number(this.form.controls.units.value); // convert to number
    this.transaction.transactionDate = this.trxDate.getTime(); // convert to millisecs
    this.transaction.securityType = SecurityType.STOCK;

    console.log('Transaction: ', this.transaction);
    this.dialogRef.close(this.transaction);
  }
}
