import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FxrateChartComponent } from './components/fxrate-chart/fxrate-chart.component';



@NgModule({
  declarations: [FxrateChartComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'fxrate-chart',  component: FxrateChartComponent},
    ]),
  ]
})
export class FxRatesModule { }
