<div class="mat-elevation-z8 container">
    <mat-table matSort matSortActive="watchList" matSortDirection="desc" [dataSource]="dataSource"
        class="mat-elevation-z8">

        <ng-container matColumnDef="watchList">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.watch' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.watchList" [checked]="element.watchList"
                (change)="selectionChanged(element)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="company">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.company' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.company}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="symbol">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.symbol' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.symbol}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="securityType">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.securitytype' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.securityType}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isin">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.isin' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.isin}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="valor">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.valor' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.valor}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="currency">
            <mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'portfolio.list.currency' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.currency}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="exchange">
            <mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef>
                {{ 'portfolio.list.exchange' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.exchange}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': row.watchList}"
            (click)="$event.stopPropagation(); selectedRow()"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>