import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from "@angular/material/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AboutDialogComponent } from 'src/app/admin/components/about-dialog/about-dialog.component';
import { AdminStoreService } from 'src/app/admin/services/admin-store.service';
import { AppUser } from 'src/app/domain/appuser';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from 'src/environments/environment';
import { ComponentInfo, EditAction, EditButtonsState, MenuStoreService } from '../../services/menu-store.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['../menu.scss']
})

export class TopMenuComponent implements OnInit, OnDestroy {
  appUser$: Observable<AppUser> = this.adminStore.appUser$;
  aboutDialogRef: MatDialogRef<AboutDialogComponent>;

  DELETE = EditAction.DELETE;
  DELIMG = EditAction.DELIMG;
  UNDO = EditAction.UNDO;
  COPY = EditAction.COPY;
  SAVE = EditAction.SAVE;
  MODIFY = EditAction.MODIFY;

  subscriptions = new Subscription();

  component: any;
  hasOptions = false;
  currentlang: string;
  
  btnStat$: Observable<EditButtonsState> = this.menuStore.editButtonsState$;
  componentInfo$: Observable<ComponentInfo> = this.menuStore.componentInfo$;
  editAction$: Observable<EditAction> = this.menuStore.editAction$;
  option$: Observable<string> = this.menuStore.option$;
  filterString$: Observable<string> = this.menuStore.filterString$;

  searchInput: string;
  searchInputUpdate = new Subject<string>();

  date: string = environment.date;
  name: string = environment.name;
  author: string = environment.author;
  version: string = environment.version;
  database:  string = environment.firebase.databaseURL;
  storage:  string = environment.firebase.storageBucket

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<any>,
    private menuStore: MenuStoreService,
    private adminStore: AdminStoreService
  ) {
    this.searchInputUpdate
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => this.menuStore.filterString = value);
  }

  ngOnInit(): void {
    this.currentlang = this.translate.currentLang;
    this.filterString$.subscribe(searchString => this.searchInput = searchString);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.auth.logout();
  }

  clearSearchInput() {
    this.searchInput = '';
    this.menuStore.filterString = this.searchInput;
  }

  routeTo(path) {
    // console.log(path);
    this.clearSearchInput();
    this.router.navigate(['/' + path]);
  }

  execute(option: string) {
    this.menuStore.option = option;
  }

  editEntry(action) {
    this.menuStore.editAction = action;
  }

  language(lang: string) {
    this.currentlang = lang;
    this.translate.use(lang);
    this.dateAdapter.setLocale(lang);
  }

  about() {
    this.aboutDialogRef = this.dialog.open(AboutDialogComponent, {
      width: '500px',
      data: {
        title: 'top.menu.about.title',
        name: this.name,
        version: 'Version: ' + this.version,
        author: 'Author: ' + this.author,
        database: 'Database: ' + this.database,
        storage: 'Cloud store: ' + this.storage,
        date: this.date
      }
    });

    this.aboutDialogRef.afterClosed().subscribe(() => {
      return;
    });
  }
}
