import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditListComponent } from './components/audit-list/audit-list.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuditGuardService } from './services/audit-guard.service';



@NgModule({
  declarations: [AuditListComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'audit-list', component: AuditListComponent, canActivate: [AuditGuardService] },
    ]),
  ]
})
export class AuditModule { }
