import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { PositionSecurity } from 'src/app/domain/position-security';
import { TimeSeries } from 'src/app/domain/timeseries';
import { Chart, PortfolioStoreService } from '../../services/portfolio-store.service';
import { DataService } from './../../../shared/services/data.service';

// import * as Highcharts from 'highcharts';
declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');
const Highcharts = require('highcharts/highstock');
require('highcharts/themes/dark-unica')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss']
})
export class PerformanceChartComponent implements OnInit, OnDestroy {

  chart$: Observable<Chart> = this.portfolioStore.chart$;
  containers = ['performancechart'];

  public options: any = {
    // 1m, 3m, 6m, YTD, 1y, All
    rangeSelector: { selected: 2 },
    credits: { enabled: false },
    chart: { style: { fontFamily: 'Roboto, "Helvetica Neue", sans-serif', }, type: 'line', },
    title: { text: 'Stock Price Comparison' },
    legend: { enabled: true },
    // yAxis: {    reversedStacks: false  }, // order like input
    // plotOptions: { series: { compare: 'percent', showInNavigator: true } },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
      valueDecimals: 2,
      split: true
    },
    series: []
  };

  subscriptions = new Subscription();
  timeSeries: TimeSeries[];

  positions: PositionSecurity[];
  chartType: string;
  option: string;

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private portfolioStore: PortfolioStoreService
  ) { }

  // https://www.highcharts.com/docs/getting-started/your-first-chart
  ngOnInit(): void {
    this.subscriptions.add(this.dataService.readAll<TimeSeries>('portfolio/timeseries/')
      .subscribe((ts: TimeSeries[]) => {
        // console.log('TimeSeries updated', ts.length);
        if (!ts) return;
        this.timeSeries = ts;
        this.drawChart();
      }));

    this.subscriptions.add(this.chart$
      .subscribe(chart => {
        // console.log('Chart requested: ', chart);
        if (!chart) return;
        this.positions = chart.positions;
        this.chartType = chart.chartType;
        this.option = chart.option;
        if (this.timeSeries) this.drawChart();
      }));

  }

  private drawChart() {
    if (this.chartType === 'line') this.lineChart();
    if (this.chartType === 'area') this.sumChart();
  }

  private lineChart() {
    this.options.series = [];
    for (const position of this.positions) {
      const index = this.timeSeries.findIndex(ts => ts.key === position.symbol);
      if (index > -1) {
        const data = this.convertSeries(this.timeSeries[index]);
        const name = this.timeSeries[index].key;
        const type = 'line';
        this.options.series.push({ data, name, type });
      }
    }
    this.options.plotOptions = { series: { compare: this.option, showInNavigator: false } };
    this.options.title.text = this.translate.instant('portfolio.chart.line.title');
    Highcharts.stockChart(this.containers[0], this.options);
  }

  private sumChart() {

    this.options.series = [];
    this.options.chart.type = 'area';
    let colIdx = 0;
    for (const position of this.positions) {
      const index = this.timeSeries.findIndex(ts => ts.key === position.symbol);
      if (index > -1) {
        const data = this.convertSeries(this.timeSeries[index]);
        for (const dataPoint of data) { dataPoint[1] = position.units * dataPoint[1] }
        const name = this.timeSeries[index].key;
        const fillColor = {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [[0, Highcharts.getOptions().colors[colIdx]], [0.5, this.hexToRGBA(Highcharts.getOptions().colors[colIdx++], 0)]]
        }
        this.options.series.push({ data, name, fillColor });
      }
    }
    this.options.plotOptions = {
      series: {
        fillOpacity: 0.1,
        compare: undefined,
        showInNavigator: false,
        stacking: 'normal'
      }
    };
    this.options.title.text = this.translate.instant('portfolio.chart.sum.title');
    Highcharts.stockChart(this.containers[0], this.options);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private convertSeries(ts: TimeSeries): number[][] {
    const timeSeries: number[][] = [];
    Object.keys(ts.series).forEach(key => {
      const x: number = this.convertDate(key);
      const y: number = ts.series[key];
      timeSeries.push([x, y]);
    });
    return timeSeries;
  }

  private convertDate(key: string): number {
    // console.log(+key.substr(0,4),+key.substr(5,2)-1,+key.substr(8,2))
    const date = new Date(+key.substr(0, 4), +key.substr(5, 2) - 1, +key.substr(8, 2));
    return date.getTime();
  }

  private hexToRGBA(hex, opacity) {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  };
}
