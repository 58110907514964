import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FamilyMember } from 'src/app/domain/familymember';
import { ConfigStoreService, Config } from 'src/app/shared/services/config-store.service';
import * as moment from "moment";

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'person-dialog',
  templateUrl: './person-dialog.component.html',
  styleUrls: ['./person-dialog.component.scss']
})
export class PersonDialogComponent implements OnInit {
  config$: Observable<Config> = this.configStore.config$;

  dateOfBirth: Date;
  personForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PersonDialogComponent>,
    private configStore: ConfigStoreService,
    @Inject(MAT_DIALOG_DATA) public data: { header: string, member: FamilyMember }) {
  }

  ngOnInit(): void {
    // console.log('PersonDialog: ',this.data.member);
    this.personForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      sex: new FormControl(null, Validators.required),
      dateOfBirth: new FormControl(null),
    });

    if (this.data.member.firstName) this.personForm.get('firstName').setValue(this.data.member.firstName);
    if (this.data.member.lastName) this.personForm.get('lastName').setValue(this.data.member.lastName);
    if (this.data.member.sex) this.personForm.get('sex').setValue(this.data.member.sex);
    if (this.data.member.dateOfBirth) this.personForm.get('dateOfBirth').setValue(moment(this.data.member.dateOfBirth));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  proceed(): void {
    if (this.personForm.get('firstName').value) this.data.member.firstName = this.personForm.get('firstName').value;
    if (this.personForm.get('lastName').value) this.data.member.lastName = this.personForm.get('lastName').value;
    if (this.personForm.get('sex').value) this.data.member.sex = this.personForm.get('sex').value;
    if (this.personForm.get('dateOfBirth').value) this.data.member.dateOfBirth = this.personForm.get('dateOfBirth').value.valueOf();
    this.dialogRef.close(this.data.member);
  }
}
