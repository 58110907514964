<ng-container *ngIf="record">

  <div class="dropzone layout" dsDnd (fileDropped)="onDropImage($event)">
    <ng-container *ngIf="imageUrl">
      <mat-icon *ngIf="!downloadingImage" class="cornerIcon"
        matTooltip="{{'family.form.image.delete.tooltip' | translate}}" (click)="deleteAttached('imageUrl')">
        delete
      </mat-icon>
      <img src={{imageUrl}} [hidden]="downloadingImage" (load)="downloadingImage=false" [disableClick]="true">
    </ng-container>


    <ng-container *ngIf="!imageUrl && !uploadingImage">
      <mat-icon>portrait</mat-icon>
      <span> {{ 'common.dragndrop.image' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="uploadingImage">
      <mat-progress-spinner mode="indeterminate" color="accent">
      </mat-progress-spinner>
    </ng-container>
  </div>

  <!-- <div class="form">
    <ng-container *ngIf="formUrl">
      <div class="box" ngx-dropzone [multiple]="false" (change)="onDropFile($event)" [disableClick]="true">
        <mat-icon class="cornerIcon" matTooltip="{{'member.form.attachform.delete.tooltip' | translate}}"
          (click)="deleteAttached('formUrl')">
          delete
        </mat-icon>
        <mat-icon class="bigIcon" matTooltip="{{'member.form.showattachedform.tooltip' | translate}}"
          (click)="showAttachedForm()">
          picture_as_pdf
        </mat-icon>
        <p>{{filename}}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="!formUrl">
      <div class="box dropzone" ngx-dropzone [multiple]="false" (change)="onDropFile($event)">
        <ng-container *ngIf="uploadingForm" class="dropzone ">
          <mat-spinner></mat-spinner>
        </ng-container>
        <ng-container *ngIf="!uploadingForm" class="dropzone ">
          <ngx-dropzone-label>
            <span>{{'common.dragndrop.form' | translate}}</span>
          </ngx-dropzone-label>
        </ng-container>
      </div>
    </ng-container>
  </div> -->