import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminStoreService } from 'src/app/admin/services/admin-store.service';

@Injectable({ providedIn: 'root' })

export class DataService {

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private adminStore: AdminStoreService
  ) { }

  create(key: string, data: any) { // replace existing record, if it exists
    // console.log('Key: ', key, ' Data: ', data);
    return this.db.object('/' + key).set(data).catch(error => console.log(error));
  }

  modify(key: string, data: any) { // update existing record, if it exists, keep properties not in data
    return this.db.object('/' + key).update(data).catch(error => console.log(error));
  }

  set(type: string, data: any, key?: string) { // replace existing record, if it exists
    if (key) {
      return this.db.object('/' + type + '/' + key).set(data).catch(error => alert('Error: ' + error + '\n' + data));
    } else {
      this.updateUserStampCreated(data);
      return this.db.object('/' + type + '/' + data.key).set(data).catch(error => alert('Error: ' + error + '\n' + data));
    }
  }

  update(type: string, data: any, key?: string) { // update existing record, if it exists, keep properties not in data
    if(key) {
      return this.db.object('/' + type + '/' + key).update(data).catch(error => console.log(error));
    } else {
      this.updateUserStampModified(data);
      return this.db.object('/' + type + '/' + data.key).update(data).catch(error => console.log(error));
    }
  }

  multiUpdate(type: string,data: any,) {
    console.info('Data: ',data);
      return this.db.object('/' + type ).update(data).catch(error => console.log(error));
  }

   updateUserStampCreated(data: any) {
    const userName = this.adminStore.appUser.name;
    if (userName) data.createdBy = userName;
    data.createdOn = Date.now();
  }

   updateUserStampModified(data: any) {
    const userName = this.adminStore.appUser.name;
    if (userName) data.modifiedBy = userName;
    data.modifiedOn = Date.now();
  }

  read<T>(type: string, key: string): Observable<T> {
    return this.db.object<T>('/' + type + '/' + key).valueChanges();
  }

  readAllSortBy<T>(type: string, orderBy: string): Observable<T[]> {
    return this.db.list<T>(`/` + type, ref => ref.orderByChild(orderBy)).valueChanges();
  }

  readAll<T>(type: string): Observable<T[]> {
    return this.db.list<T>(`/` + type + '/').valueChanges();
  }


  readAllWithKey<T>(type: string) {
    return this.db.list(`/` + type)
      .snapshotChanges()
      .pipe(map(config => {
        return config.map(a => {
          const data = a.payload.val();
          const key = a.payload.key;
          return { key, data };  // or {key, data} in case data is not an Objectj
        });
      }));
  }

  delete(type: string, data: any) {
    if (data.imageUrl) {
      this.storage.storage.refFromURL(data.imageUrl).delete().then(() => {
      }).catch(error => {
        console.error('Error occured while deleting file ' + data.imageUrl + ': ' + error);
      });
    }
    return this.db.object('/' + type + '/' + data.key).remove().catch(error => console.error(error));
  }

  deleteAll(type: string) {
    return this.db.object('/' + type).remove().catch(error => console.error(error));
  }
}
