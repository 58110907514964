import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TransactionSecurity } from 'src/app/domain/transaction-security';
import { ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { DataService } from 'src/app/shared/services/data.service';
import { TransactionDialogComponent } from '../transaction-dialog/transaction-dialog.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['../../portfolio.scss']
})
export class TransactionListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableName = 'portfolio';
  subscriptions = new Subscription();
  displayedColumns = [
     'company', 'securitytype', 'transactiontype','date', 'units', 'price', 'totalprice', 'dividends', 'taxes', 'commission', 'totalcost'
  ];
  dataSource = new MatTableDataSource();
  transactions: TransactionSecurity[];
  total: number;
  baseCcy: string;
  totalCosts: number;
  totalTaxes: number;
  totalCommission: number;
  totalValue: number;
  totalPrice: number;
  totalDelta: number;
  totalDividends: number;
  locale: string;
  transactionDialogRef: any;

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private configStore: ConfigStoreService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.dataService.readAll<TransactionSecurity>(this.tableName + '/transactions').subscribe((transactions: TransactionSecurity[]) => {
        this.dataSource.data = transactions;
        this.transactions = transactions;
        this.setTotals(transactions);
      })
    );
    this.baseCcy = this.configStore.config.baseCcy;
    // console.log('Base currency: ', this.baseCcy);
    this.subscriptions.add(this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // console.log(params.lang);
      this.locale = params.lang;
    }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getValue(currentTransaction): number {
    const transaction = this.transactions.find(entry => entry.key === currentTransaction.key);
    return transaction.totalCost;
  }

  selectRow(transaction) {
    this.transactionDialogRef = this.matDialog.open(
      TransactionDialogComponent,
      {
        width: '750px',
        data: transaction,
      }
    );

    this.transactionDialogRef.afterClosed().subscribe((modifiedTransaction) => {
      // console.log('Transaction: ', modifiedTransaction);

      if (modifiedTransaction === 'delete') {
        this.dataService.delete(this.tableName + '/transactions', transaction);
        return;
      }
      if (!modifiedTransaction) { return; }

      this.dataService.update(this.tableName + '/transactions', modifiedTransaction);
    });
  }

  setTotals(transactions: TransactionSecurity[]): void {
    this.totalCosts = 0;
    this.totalTaxes = 0;
    this.totalCommission = 0;
    this.totalValue = 0;
    this.totalPrice = 0;
    this.totalDelta = 0;
    this.totalDividends = 0;
    for (const transaction of transactions) {
      this.totalCosts += transaction.totalCost;
      this.totalTaxes += transaction.taxes;
      this.totalCommission += transaction.commission;
      this.totalValue += transaction.totalCost;
      this.totalPrice += transaction.price;
      this.totalDividends += transaction.dividends;
      // console.log('Transaction date: ', transaction.transactionDate);
    }
  }

  getTotalPrice(transactions: TransactionSecurity[]): number {
    let total = 0;
    for (const transaction of transactions) { total += transaction.priceInBaseCcy; }
    return total;
  }

  getSecType(type) {
    return this.translate.instant('portfolio.list.sectypes.'+type) ;
  }
  getTransType(type) {
    return this.translate.instant('portfolio.list.transtypes.'+type) ;
  }
}
