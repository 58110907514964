<div class="container">

    <button mat-raised-button color="basic" (click)="migrate(false)">
        <mat-icon>upgrade</mat-icon>
        <span>{{'migrate.list.header' | translate}}</span>
    </button>
    <button mat-raised-button color="basic" (click)="migrate(true)">
        <mat-icon>upgrade</mat-icon>
        <span>{{'migrate.list.test' | translate}}</span>
    </button>
    <button mat-raised-button color="basic" (click)="setAll(false)">
        <mat-icon>restart_alt</mat-icon>
        <span>{{'migrate.list.reset' | translate}}</span>
    </button>


    <mat-table matSort matSortActive="table" [dataSource]="dataSource">
        <ng-container matColumnDef="table">
            <mat-header-cell *matHeaderCellDef> {{'migrate.list.table' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.table}} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> 
                <mat-checkbox (change)="setAll(checkAll)" [(ngModel)]="checkAll" [checked]="checkAll" color="primary">
                    {{'migrate.list.selected' | translate}} 
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.checked"
                    [checked]="element.checked" color="primary">
                </mat-checkbox>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="countRecords">
            <mat-header-cell *matHeaderCellDef> {{'migrate.list.countrecords' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.countRecords}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="countModified">
            <mat-header-cell *matHeaderCellDef> {{'migrate.list.countmodified' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.countModified}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="done">
            <mat-header-cell *matHeaderCellDef> {{'migrate.list.done' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.done" [checked]="element.done" color="primary">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': row.table === ALL }">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSize]="15" [pageSizeOptions]="[10, 15, 20, 50]"></mat-paginator>

</div>