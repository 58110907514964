const packageJson = require('../../package.json');
export const environment = {
    version: packageJson.version,
    date: packageJson.date,
    name: packageJson.name,
    author: packageJson.author,
    production: true,
    openweathermap: {
        apiKey: '707b5adde87eafd82e348117f67cd0b2',
        apiUrl: 'https://api.openweathermap.org/data/2.5/'
    },
    alphavantage: {
        apiKey: 'N343ZKV7EQQXYGL0',
        apiUrl: 'https://www.alphavantage.co/query?function=TIME_SERIES_DAILY'
    },
    firebase: {
        apiKey: "AIzaSyBU8rId2vB2E2CaswTmXAbO1XaRZ0tYz0w",
        authDomain: "home-2e3a4.firebaseapp.com",
        databaseURL: 'https://black-board.firebaseio.com',
        projectId: "home-2e3a4",
        storageBucket: 'black-board',
        messagingSenderId: "220200757427",
        appId: "1:220200757427:web:5eebe7117edc16bbf0a8c6"
    }
}