import { ConfigStoreService } from 'src/app/shared/services/config-store.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { isArray } from "highcharts";
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FamilyMember } from './../domain/familymember';

export const excelDateBase = 25569;

@Injectable()
export class FileUtil {
    dateFormat = 'dd/mm/yyyy';
    datePipe = new DatePipe('fr');
    dateValueColumns = ['modifiedon', 'modifiedon', 'createdon', 'createdon', 'dateofbirth', 'dateofdeath'];

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private configStore: ConfigStoreService,

    ) { }

    public getJSON(filename: string): Observable<any> {
        return this.http.get(filename).pipe(map((res: {}) => res));
    }

    public exportFamily(tableName: string, data: {}[], fullSet: {}[]) {
        data = this.reformatIds(data, fullSet);
        data = this.sortByAllColumns(data);
        data = this.replaceHeadings(data);
        this.export(tableName, data);
    }

    public export(tableName: string, data: {}[]) {
        data = this.removeFileRefs(data);
        if (tableName === 'members') { data = this.expandSubscriptions(data); }
        const now = Date.now();
        const fileName = 'Export' + '_' + tableName + '_' + this.datePipe.transform(now, 'yyyyMMddHHmmSS') + '.xlsx';
        const wb = XLSX.utils.book_new();

        const wsMembers = this.convertAndFormatDates(XLSX.utils.json_to_sheet(data));
        XLSX.utils.book_append_sheet(wb, wsMembers, tableName);
        XLSX.writeFile(wb, fileName);
    }

    private replaceHeadings(data: FamilyMember[]): FamilyMember[] {
        const newData: FamilyMember[] = [];
        for (let index = 0; index < data.length; index++) {
            const member = data[index];
            const newMember = {};
            let translatedName: string;
            Object.keys(member).forEach(key => {
                if (key === 'childrenNames') {
                    translatedName = this.translate.instant('family.list.children');
                } else {
                    translatedName = this.translate.instant('family.list.' + key.toLowerCase());
                    if (translatedName.includes('familiy.list.')) translatedName = key;
                }
                if (key !== 'key') newMember[translatedName] = member[key];
                if (this.dateValueColumns.includes(key.toLowerCase())) this.dateValueColumns.push(translatedName);
                // console.log('Key: ', key, ' Header: ', translatedName);
            });
            console.log('New member: ', newMember);
            newData.push(newMember);
        }
        return newData;
    }

    private removeFileRefs(data: {}[]): {}[] {
        // console.log('Data before: ', data);
        for (let index = 0; index < data.length; index++) {
            Object.keys(data[index]).forEach((key: string) => {
                if (key.toLowerCase().includes('url')) { delete data[index][key]; }
            });
        }
        // console.log('Data after: ', data);
        return data;
    }
    private convertChildrenToLength(data: {}[]): {}[] {
        // console.log('Data before: ', data);
        for (let index = 0; index < data.length; index++) {
            Object.keys(data[index]).forEach((key: string) => {
                if (isArray(data[index][key])) data[index][key] = data[index][key].length;
            });
        }
        // console.log('Data after: ', data);
        return data;
    }

    expandSubscriptions(data: {}[]): {}[] {
        for (let index = 0; index < data.length; index++) {
            if (data[index]['subscriptions']) {
                const subscriptions = data[index]['subscriptions'];
                // console.log(subscriptions);
                delete data[index]['subscriptions'];
                Object.keys(subscriptions).forEach(year => data[index][year] = subscriptions[year]['amount']);
            }
        }
        return data;
    }

    private sortByAllColumns(familyMembers: FamilyMember[]): FamilyMember[] {
        const sortedColumns: FamilyMember[] = [];
        for (const familyMember of familyMembers) {
            const orderedMember: FamilyMember = {};
            for (const column of this.configStore.config.familyAllColumns) {
                orderedMember[column]=familyMember[column];
            }
            sortedColumns.push(orderedMember);
        }
        return sortedColumns;
    }
    private reformatIds(data: FamilyMember[], dataAll: FamilyMember[]): {}[] {
        for (let index = 0; index < data.length; index++) {
            const member = data[index];
            if (member.fatherId) delete member.fatherId;
            if (member.motherId) delete member.motherId;
            if (member.partnerId) delete member.partnerId;
            if (member.childrenIds) {
                // console.log('Children before: ', member);
                let childrenNames: string = '';
                for (const childId of member.childrenIds) {
                    const index2 = dataAll.findIndex(entry => entry.key === childId);
                    if (index2 > -1) {
                        childrenNames = childrenNames.concat(dataAll[index2].firstName + ', ');
                    }
                }
                childrenNames = childrenNames.substring(0, childrenNames.length - 2);
                member['childrenNames'] = childrenNames;
                delete member.childrenIds;
                data[index] = member;
                // console.log('Children after: ', data[index]['childrenNames']);
            }
        }
        return data;
    }

    public import(file: File): Observable<any> {

        let arrayBuffer: any;

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onerror = (e) => alert('Unable to read ' + file + ' Error: ' + e);

        return new Observable((observer: Observer<{}>) => {
            fileReader.onloadend = (e) => {
                arrayBuffer = fileReader.result;
                const data = new Uint8Array(arrayBuffer);
                const arr = new Array();
                for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
                const bstr = arr.join('');
                const workbook = XLSX.read(bstr, { type: 'binary' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                if (workbook.Workbook.WBProps.date1904) {
                    alert('Votre Excel utilise le calendrier depuis 1904. \n' +
                        'Changer le en 1900 (Option - calcul - DECROCHER "Utiliser calendrier depuis 1904"');
                }
                let xlsxRecords = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
                const headersRow = xlsxRecords[0];
                xlsxRecords = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                const table = { header: headersRow, rows: xlsxRecords };
                observer.next(table);
                observer.complete();
            };
        });
    }

    downloadImages(data: {}[]) {
        data.forEach(item => {
            if (item['imageUrl']) {
                const imageUrl = item['imageUrl'];
                const filename = imageUrl.substring(imageUrl.lastIndexOf('%2F') + 3, imageUrl.indexOf('?'));
                this.getBase64ImageFromUrl(item['imageUrl']).then(result => {
                    const blob = this.dataURItoBlob(result);
                    saveAs(blob, filename);
                }).catch(e => {
                    console.log('Error: ' + filename + ' ' + e);
                    alert(e);
                });
            }
        });
    }

    private convertAndFormatDates(ws: XLSX.WorkSheet): XLSX.WorkSheet {
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const heading = ws[XLSX.utils.encode_cell({ r: range.s.r, c: colNum })];

            let itIsADateColumn = false;
            for (const column of this.dateValueColumns) if (heading.v.includes(column)) itIsADateColumn = true;

            if (itIsADateColumn) {
                for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
                    const cell = ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
                    if (cell) {
                        cell.v = this.convertUnixDateToExcelDate(cell.v);
                        delete cell.w; // remove old formatted text
                        cell.z = this.dateFormat; // set cell format
                    }
                }
            }
        }
        return ws;
    }


    private convertUnixDateToExcelDate(unixDate: number): number {
        return unixDate / 86400000 + excelDateBase;
    }

    async getBase64ImageFromUrl(imageUrl: string) {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException('Problem parsing input file.'));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsDataURL(blob);
        });

    }

    private dataURItoBlob(dataURI): Blob {
        const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }

}
