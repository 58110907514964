<div class="mat-elevation-z8 container">
    <mat-table matSort [dataSource]="filteredFamily" matSortActive="part" matSortDirection="desc">

        <!-- Tree button column -->
        <ng-container matColumnDef="viewtree">
            <mat-header-cell *matHeaderCellDef> {{'contact.list.tree' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-mini-fab (mouseout)="treeViewButtoncolor = 'accent'"
                    (click)="$event.stopPropagation(); viewTree(element)">
                    <!-- matTooltip="{{'contact.list.button.tree.tooltip' | translate}}"> -->
                    <mat-icon>reduce_capacity</mat-icon>
                </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="photo">
            <mat-header-cell *matHeaderCellDef>{{'common.photo' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.imageUrl">photo_camera</mat-icon>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- FirstName Column -->
        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="center">
                {{'contact.list.firstname' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.lastname' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> {{'family.list.totalparts' | translate}} </mat-footer-cell>
        </ng-container>

        <!-- DateOfBirth Column -->
        <ng-container matColumnDef="dateOfBirth">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'family.list.dateofbirth' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateOfBirth | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.email' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Telephone Column -->
        <ng-container matColumnDef="telephoneFix">
            <mat-header-cell *matHeaderCellDef>
                {{'contact.list.telephone' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.telephoneFix}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Telephone2 Column -->
        <ng-container matColumnDef="telephoneMobile">
            <mat-header-cell *matHeaderCellDef>
                {{'contact.list.telephone2' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.telephoneMobile}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Father Column -->
        <ng-container matColumnDef="fathersName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.father' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.fathersName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Mother Column -->
        <ng-container matColumnDef="mothersName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.mother' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mothersName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Children Column -->
        <ng-container matColumnDef="childrenNames">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.children' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.childrenNames}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Partner Column -->
        <ng-container matColumnDef="partnersName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.partner' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.partnersName}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- partNominal Column -->
        <ng-container matColumnDef="part">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.part' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.part}} </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{getTotalParts() | number:'1.0-0':locale}} </mat-footer-cell>
        </ng-container>

        <!-- Generation Column -->
        <ng-container matColumnDef="generation">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.generation' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.generation}} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- FamilyTree Column -->
        <ng-container matColumnDef="familyTree">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'family.list.familytree' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.familyTree}} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- DateOfDeath Column -->
        <ng-container matColumnDef="dateOfDeath">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'family.list.dateofdeath' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateOfDeath | date:'dd.MM.yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Sex Column -->
        <ng-container matColumnDef="sex">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.sex' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sex}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>


        <!-- Email2 Column -->
        <ng-container matColumnDef="email2">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.email2' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email2}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- AdrLine1 Column -->
        <ng-container matColumnDef="addrLine1">
            <mat-header-cell *matHeaderCellDef>
                {{'contact.list.adrline1' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.addrLine1}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- AdrLine2 Column -->
        <ng-container matColumnDef="addrLine2">
            <mat-header-cell *matHeaderCellDef>
                {{'contact.list.adrline2' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.addrLine2}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- CIP Column -->
        <ng-container matColumnDef="cp">
            <mat-header-cell *matHeaderCellDef>
                {{'contact.list.zip' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.cp}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="city">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.city' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.country' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.country}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'contact.list.key' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
        <!-- [ngClass]="{'highlight': row.LastSubscription === lastSubscription }"  -->
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="$event.stopPropagation(); viewDetails(row)">
        </mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns" class="bold"></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons></mat-paginator>
</div>