import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FamilyMemberContext } from 'src/app/domain/familymembercontext';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ImagePopupDialogComponent } from 'src/app/shared/components/image-popup-dialog/image-popup-dialog.component';
import { UploadFileService } from 'src/app/shared/services/uploadfile.service';
import { FamilyStoreService } from '../../services/family-store.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'image-and-form',
  templateUrl: './image-and-form.component.html',
  styleUrls: ['./image-and-form.component.scss']
})
export class ImageAndFormComponent implements OnInit, OnDestroy {



  yesNoDialogRef: any;
  imageDialogRef: any;

  downloadingImage = true;
  uploadingImage = false;
  uploadingForm = false;

  imageUrl: string;
  formUrl: string;
  record: {};
  tableName = 'family';
  filename: string;
  currentMember$: Observable<FamilyMemberContext> = this.familyStore.currentMemberContext$;


  subscribeSelectedRecord: Subscription;

  constructor(
    private dialog: MatDialog,
    private uploadFileService: UploadFileService,
    private familyStore: FamilyStoreService
  ) { }

  ngOnInit(): void {
    this.subscribeSelectedRecord = this.currentMember$.subscribe(currentmember => {
      // console.log('Image.and.form: ',currentmember);
      if (currentmember.self) {
        this.imageUrl = currentmember.self.imageUrl;
        this.formUrl = currentmember.self.formUrl;
        if (this.formUrl) {
          const decoded = decodeURI(this.formUrl);
          // console.log(decoded);
          this.filename = decoded.match('%2..*%2F(.*?)\\?alt')[1];
          // console.log(this.filename);
        }
        this.record = currentmember.self;
        this.uploadingImage = false;
        this.uploadingForm = false;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscribeSelectedRecord.unsubscribe();
  }

  showAttachedForm() {
    this.imageDialogRef = this.dialog.open(ImagePopupDialogComponent,
      { width: '80vw', data: this.formUrl }
    );
  }

  deleteAttached(field: string) {
    this.yesNoDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: { title: 'common.dialog.confirm.delete.title', message: 'common.dialog.confirm.delete.message' }
    });

    this.yesNoDialogRef.afterClosed().subscribe(remove => {
      if (remove) {
        this.uploadFileService.deleteImageAndDBReference(this.tableName, this.record, field);
      }
    });
  }

  onDropImage(event: any) {
    let fileList: FileList | null = event as FileList;
    if (fileList) {
      this.uploadingImage = true;
      this.imageUrl = null;
      this.uploadFileService.uploadAttachment(this.tableName, fileList.item(0), this.record, 'imageUrl');
    }
  }

  onDropFile(event: any) {
    // console.log(event.addedFiles);
    let fileList: FileList | null = event as FileList;
    if (fileList) {
      this.uploadingForm = true;
      this.formUrl = null;
      this.uploadFileService.uploadAttachment(this.tableName, fileList.item(0), this.record, 'formUrl');
    }
  }

}
