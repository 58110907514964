<div *ngIf="(currentMember$ | async) as currentMemberContext" class="container layout">

  <div class="form mat-elevation-z8">
    <mform></mform>
  </div>

  <div class="list mat-elevation-z8">
    <ng-container *ngIf="currentMemberContext.children">
      <children-list></children-list>
    </ng-container>
  </div>

  <div class="attachments mat-elevation-z8">
    <image-and-form></image-and-form>
  </div>

  <div class="footer mat-elevation-z8">
    <userstamps-footer [entity]="currentMemberContext.self"></userstamps-footer>
  </div>

</div>